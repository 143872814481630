import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { changeJapaneseErrorMessage } from '../common/utils';
import { AxiosContext } from './AxiosContext';
import { useNavigate } from 'react-router-dom';
import PasswordInput from './PasswordInput';
import ModalFrame from './ModalFrame';

import '../css/signIn.css';

/**
 * ログインモーダル
 * @param {close} 閉じる処理
 * @param {setter} モーダル内容設定
 * @returns {React.Element}
 */
const ModalSignIn = ({ close, setter }) => {
    const instance = useContext(AxiosContext);

    const [userInfo, setUserInfo] = useState({ email: '', password: '' });
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    // 入力時処理
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setUserInfo((params) => ({
            ...params,
            [name]: value,
        }));
    };

    // 登録処理
    const register = async (e) => {
        e.preventDefault(); // フォーム送信を防止
        setIsLoading(true);
        try {
            const result = await Auth.signIn({
                username: userInfo.email,
                password: userInfo.password,
                autoSignIn: {
                    enabled: true,
                },
            });

            const params = {
                authType: 'LOGIN',
            };
            await instance.request({
                data: params,
                method: 'POST',
                url: 'settingAuthenticationHistory',
            });

            if (result.attributes['custom:regInfo'] === '1') {
                close();
                navigate('/home');
            } else {
                setter({ target: 'userInfoReg' });
            }
        } catch (err) {
            const message = changeJapaneseErrorMessage(err.message);
            setRemoteError({ status: true, message: message });
        } finally {
            setIsLoading(false);
        }
    };

    //パスワードリセット
    const toResetPassword = () => {
        setter((prev) => ({ ...prev, target: 'resetPassword' }));
    };

    return (
        <div>
            {isLoading && <ModalFrame modalId="loading" />}
            <div className="modal__title">ログイン</div>
            <div>
                <label htmlFor="" className="modal__content_input">
                    <p>メールアドレス</p>
                    <div>
                        <input
                            type="text"
                            name="email"
                            autoComplete="email"
                            placeholder="example@siwakee.com"
                            onChange={changeHandler}
                        />
                    </div>
                </label>
                <label htmlFor="" className="modal__content_input">
                    <p>パスワード</p>
                    <PasswordInput name="password" onChange={changeHandler} />
                </label>
                <div className="modal__btn_area">
                    <button
                        className="modal__btn back"
                        onClick={() => {
                            close();
                        }}
                    >
                        閉じる
                    </button>
                    <button className="modal__btn" onClick={register}>
                        ログイン
                    </button>
                </div>
            </div>
            <div>
                <button
                    className="modal__btn forget"
                    onClick={() => toResetPassword()}
                >
                    パスワードを忘れましたか？
                </button>
            </div>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </div>
    );
};

export default ModalSignIn;
