import React, { createContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import { AxiosInstanceProvider } from './components/AxiosContext';
import { Auth } from 'aws-amplify';
import { loadStripe } from '@stripe/stripe-js';
import Login from './View/Login';
import Home from './View/Home';
import RequireAuth from './components/RequireAuth';

import './App.css';

export const stripePromiseContext = createContext(
    loadStripe(process.env.REACT_APP_STRIPE_KEY)
);
export const ModalContext = createContext();

export default function App() {
    const [modal, setModal] = useState({
        dataChanged: false,
        display: false,
        target: '',
        data: undefined,
    });

    const interceptor = async (config) => {
        return new Promise((resolve, reject) => {
            Auth.currentSession()
                .then((session) => {
                    var idTokenExpire = session.getIdToken().getExpiration();
                    var refreshToken = session.getRefreshToken();
                    var currentTimeSeconds = Math.round(+new Date() / 1000);
                    if (idTokenExpire < currentTimeSeconds) {
                        Auth.currentAuthenticatedUser()
                            .then((res) => {
                                res.refreshSession(
                                    refreshToken,
                                    (err, data) => {
                                        if (err) {
                                            try {
                                                Auth.signOut({ global: true });
                                            } catch (e) {
                                                Auth.signOut();
                                            }
                                        } else {
                                            config.headers.Authorization =
                                                'Bearer ' +
                                                data.getIdToken().getJwtToken();
                                            resolve(config);
                                        }
                                    }
                                );
                            })
                            .catch((err) => {
                                // No logged-in user: don't set auth header
                                resolve(config);
                            });
                    } else {
                        config.headers.Authorization =
                            'Bearer ' + session.getIdToken().getJwtToken();
                        resolve(config);
                    }
                })
                .catch(() => {
                    // No logged-in user: don't set auth header
                    resolve(config);
                });
        });
    };

    return (
        <div id="root">
            <AxiosInstanceProvider
                config={{
                    baseURL: process.env.REACT_APP_BASE_URL,
                }}
                requestInterceptors={[interceptor]}
            >
                <ModalContext.Provider
                    value={{ data: modal, setter: setModal }}
                >
                    <Router>
                        <Routes>
                            <Route exact path="/" element={<Login />} />
                            <Route
                                exact
                                path="/home/*"
                                element={
                                    <RequireAuth>
                                        <Home />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                exact
                                path="/portal"
                                element={<Navigate to="/portal/" replace />}
                            />
                            <Route exact path="/portal/*" />
                            <Route
                                path="*"
                                element={<Navigate to="/" replace />}
                            />
                            {/* page-not-found route*/}
                        </Routes>
                    </Router>
                </ModalContext.Provider>
            </AxiosInstanceProvider>
        </div>
    );
}
