import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../View/Home';
import { AxiosContext } from './AxiosContext';
import { FrontErrorMessage } from './FrontErrorMessage';
import { countryOptions } from '../common/countryOptions';
import { Auth } from 'aws-amplify';
import ModalFrame from './ModalFrame';

import '../css/ModalUserInfoReg.css';
import { changeJapaneseErrorMessage } from '../common/utils';
/**
 * ユーザー情報登録・確認画面・編集画面
 * @param {update} 登録画面かどうか true: 登録画面 false: 確認画面
 * @param {type}  ユーザーのタイプ
 * @param {setter} set関数
 * @param {modalRef} ref
 * @returns
 */
function ModalUserInfoReg({ close, type, setter, modalRef }) {
    const instance = useContext(AxiosContext);
    const userInfo = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [frontError, setFrontError] = useState({
        name: { error: false, message: [] },
        companyName: { error: false, message: [] },
    });
    const [page, setPage] = useState('reg'); // reg: 登録・編集画面 conf: 確認画面
    const [isShowWarning, setIsShowWarning] = useState(false);
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });

    // 日本のオブジェクトを取得
    const jp = countryOptions.find((v) => v.value === 'JP');
    const [params, setParams] = useState({
        countryCode: jp.value, // 今は日本固定
        name: '',
        nameKana: '',
        companyName: '',
        departmentName: '',
        positionName: '',
    });

    useEffect(() => {
        // 確認画面を表示する際にモーダルの一番上に自動的にスクロールする
        modalRef.current?.scrollTo(0, 0);

        // 画面ごとにRefでクラスを変更する処理
        // 登録画面
        if (page === 'reg') {
            modalRef.current.className = 'modal modal--add';
            // 確認画面
        } else if (page === 'conf') {
            modalRef.current.className = 'modal modal--confirmation';
        }
    }, [modalRef, page]);

    useEffect(() => {
        // マイページからの遷移の場合
        if (userInfo !== undefined && userInfo.data !== undefined) {
            setParams((prev) => ({
                ...prev,
                countryCode: userInfo.data.nameCompanyInfo.countryCode
                    ? userInfo.data.nameCompanyInfo.countryCode
                    : prev.countryCode,
                name: userInfo.data.nameCompanyInfo.name,
                nameKana: userInfo.data.nameCompanyInfo.nameKana,
                companyName: userInfo.data.nameCompanyInfo.companyName,
                departmentName: userInfo.data.nameCompanyInfo.departmentName,
                positionName: userInfo.data.nameCompanyInfo.positionName,
            }));
        }
    }, [userInfo]);

    // 名前バリデーションチェック
    const nameValidation = (value) => {
        let nameErrorMessage = [];
        // 必須入力チェック
        if (value === '') {
            nameErrorMessage.push('氏名を入力してください。');
        }
        setFrontError((prev) => ({
            ...prev,
            name: {
                error: nameErrorMessage && nameErrorMessage.length > 0,
                message: nameErrorMessage,
            },
        }));
    };

    // 会社名バリデーションチェック
    const companyNameValidation = (value) => {
        let companyNameErrorMessage = [];
        // 必須入力チェック
        if (value === '') {
            companyNameErrorMessage.push('会社名を入力してください。');
        }
        setFrontError((prev) => ({
            ...prev,
            companyName: {
                error:
                    companyNameErrorMessage &&
                    companyNameErrorMessage.length > 0,
                message: companyNameErrorMessage,
            },
        }));
    };

    // 入力情報の更新
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setParams((params) => ({
            ...params,
            [name]: value,
        }));
        if (name === 'name') {
            nameValidation(value);
        } else if (name === 'companyName') {
            companyNameValidation(value);
        }
    };

    // 確認画面遷移
    const goToNext = async (e) => {
        e.preventDefault();
        if (!isValid()) {
            return;
        }
        setPage('conf');
    };

    const goToNextStripe = async () => {
        //　確認画面で登録処理実行
        setIsLoading(true);
        try {
            if (!type) {
                const sendParam = {
                    nameCompanyInfo: params,
                };
                // マイページからのパターン
                const response = await instance.request({
                    data: sendParam,
                    method: 'POST',
                    url: 'updAccount',
                });
                userInfo.setter({ ...userInfo.data, nameCompanyInfo: params });
                setter({
                    target: 'updateComplete',
                    data: {
                        title: '氏名・会社情報変更完了',
                        message: '氏名・会社情報の変更が完了しました。',
                    },
                });
            } else if (type === 'free' || type === 'login') {
                await instance.request({
                    data: params,
                    method: 'POST',
                    url: 'regNewAccount',
                });
                const user = await Auth.currentAuthenticatedUser();
                // attributesのカスタム属性を追加(ユーザー情報が登録されているかどうかのフラグ 1: 登録されている)
                await Auth.updateUserAttributes(user, {
                    'custom:regInfo': '1',
                });
                setter({ target: 'freeUserComplete', data: undefined });
            } else if (type === 'paid') {
                const response = await instance.request({
                    data: params,
                    method: 'POST',
                    url: 'regNewAccount',
                });
                const user = await Auth.currentAuthenticatedUser({
                    bypassCache: true,
                });
                // attributesのカスタム属性を追加(ユーザー情報が登録されているかどうかのフラグ 1: 登録されている)
                await Auth.updateUserAttributes(user, {
                    'custom:regInfo': '1',
                });
                setter({ target: 'stripeReg', data: params });
            }
        } catch (err) {
            const message = changeJapaneseErrorMessage(
                err.response?.data.errInfo || err.message
            );
            setRemoteError({ status: true, message: message });
        } finally {
            setIsLoading(false);
        }
    };

    const isValid = () => {
        return !Object.values(frontError).some((e) => e.error) &&
            params.name &&
            params.companyName
            ? true
            : false;
    };

    const backToRegisterPage = (e) => {
        setPage('reg');
        // 確認画面から登録画面へ戻る際戻るボタンのフォーカスを外す
        e.target.blur();
    };

    return (
        <div>
            {isLoading && <ModalFrame modalId="loading" />}
            <div className="modal__title">
                氏名・会社情報
                {!type && page === 'reg'
                    ? '変更'
                    : type && page === 'reg'
                    ? '登録'
                    : page === 'conf' && '確認'}
            </div>
            <div>
                <label htmlFor="country" className="modal__content_input">
                    <p>お住まいの国または地域</p>
                    <div className="confirm_area">
                        {params.countryCode === 'JP' && '日本 (JP)'}
                        <input
                            type="text"
                            id="country"
                            name="country"
                            value="日本"
                            readOnly
                            style={{ position: 'absolute', left: '-9999px' }}
                        />
                    </div>
                </label>
                <label htmlFor="name" className="modal__content_input">
                    <p>氏名　※必須</p>
                    <div>
                        {page === 'reg' ? (
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={params.name}
                                onChange={changeHandler} // <-- attach change handler
                            />
                        ) : (
                            params.name
                        )}
                    </div>
                    <FrontErrorMessage message={frontError.name.message} />
                </label>

                <label htmlFor="name_kana" className="modal__content_input">
                    <p>氏名（ヨミ）</p>
                    <div>
                        {page === 'reg' ? (
                            <input
                                type="text"
                                id="name_kana"
                                name="nameKana"
                                value={params.nameKana}
                                onChange={changeHandler}
                            />
                        ) : (
                            params.nameKana
                        )}
                    </div>
                </label>
                <label htmlFor="company" className="modal__content_input">
                    <p>会社名　※必須</p>
                    <div>
                        {page === 'reg' ? (
                            <input
                                type="text"
                                id="company"
                                name="companyName"
                                maxLength={256}
                                onChange={changeHandler}
                                value={params.companyName}
                            />
                        ) : (
                            params.companyName
                        )}
                    </div>
                    <FrontErrorMessage
                        message={frontError.companyName.message}
                    />
                </label>

                <label htmlFor="department" className="modal__content_input">
                    <p>部署</p>
                    <div>
                        {page === 'reg' ? (
                            <input
                                type="text"
                                id="department"
                                name="departmentName"
                                onChange={changeHandler}
                                value={params.departmentName}
                            />
                        ) : (
                            params.departmentName
                        )}
                    </div>
                </label>
                <label htmlFor="position" className="modal__content_input">
                    <p>役職</p>
                    <div>
                        {page === 'reg' ? (
                            <input
                                type="text"
                                id="position"
                                name="positionName"
                                onChange={changeHandler}
                                value={params.positionName}
                            />
                        ) : (
                            params.positionName
                        )}
                    </div>
                </label>
                <div className="modal__btn_area">
                    {/* 登録画面の場合 */}
                    {page === 'reg' ? (
                        <>
                            {
                                // 認証系画面の場合
                                type ? (
                                    <button
                                        className="modal__btn back"
                                        onClick={() => setIsShowWarning(true)}
                                    >
                                        登録を中断する
                                    </button>
                                ) : (
                                    // マイページ系画面の場合
                                    <button
                                        className="modal__btn back"
                                        onClick={() => close()}
                                    >
                                        戻る
                                    </button>
                                )
                            }
                            <button
                                className="modal__btn"
                                onClick={goToNext}
                                disabled={!isValid()}
                            >
                                確認
                            </button>
                        </>
                    ) : (
                        // 確認画面の場合
                        <>
                            <button
                                className="modal__btn back"
                                onClick={backToRegisterPage}
                            >
                                戻る
                            </button>
                            <button
                                className="modal__btn"
                                onClick={goToNextStripe}
                            >
                                登録
                            </button>
                        </>
                    )}
                </div>
            </div>
            {isShowWarning && (
                <ModalFrame
                    modalId="warning"
                    data={{
                        message:
                            '本当に登録を中断しますか？\nアカウントはすでに登録されているため、次回ログイン時に氏名・会社情報の登録から再開されます。',
                        onClickOK: close,
                        onClickCancel: setIsShowWarning,
                    }}
                />
            )}
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </div>
    );
}

export default ModalUserInfoReg;
