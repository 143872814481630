import { useLocation } from 'react-router-dom';

/**
 * ログイン後のフッターコンポーネント
 * @returns {React.Element}
 */
function Footer() {
    const location = useLocation();
    const className =
        location.pathname === '/home/mypage' ||
        location.pathname === '/home/mypage/definition' ||
        location.pathname === '/home/inquiry' 
            ? 'mypage '
            : '';
    return (
        <footer className={className + 'footer'}>
            <ul className="footer__nav">
                <li>
                    <a href="/portal/entry/2023/07/20/162046" target="_blank" rel="noopener noreferrer">
                        利用規約
                    </a>
                </li>
                <li>
                    <a
                        href="/portal/entry/2023/07/20/162113"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        プライバシーポリシー
                    </a>
                </li>
                <li>
                    <a href="/portal/entry/2023/07/20/162441" target="_blank" rel="noopener noreferrer">
                        特定商取引法に基づく表記
                    </a>
                </li>
            </ul>
            <p className="copyright">&copy; Siwakee Inc.</p>
        </footer>
    );
}

export default Footer;
