import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { FrontErrorMessage } from './FrontErrorMessage';
import {
    changeJapaneseErrorMessage,
    passwordValidation,
} from '../common/utils';
import ModalFrame from './ModalFrame';
import PasswordInput from './PasswordInput';

import '../css/resetPassword.css';

/**
 * パスワードリセットモーダル
 * @param {setter} モーダル内容設定
 * @param {data} ユーザーデータ
 * @returns {React.Element}
 */
const ModalConfirmResetPassword = ({ setter, data }) => {
    const { email } = data;
    const [userInfo, setUserInfo] = useState({
        code: '',
        password: '',
        confirmPassword: '',
    });
    const [frontError, setFrontError] = useState({
        code: { error: false, message: [] },
        password: { error: false, message: [] },
        confirmPassword: { error: false, message: [] },
    });
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    // 検証コードのバリデーション
    const codeValidation = (value) => {
        let codeErrorMessage = [];
        if (value === '') {
            codeErrorMessage.push('検証コードを入力してください。');
        }
        setFrontError({
            ...frontError,
            code: {
                error: codeErrorMessage && codeErrorMessage.length > 0,
                message: codeErrorMessage,
            },
        });
    };

    // パスワードの確認のバリデーション
    const confirmPasswordValidation = (value, password) => {
        let confirmPasswordErrorMessage = [];
        if (value === '') {
            confirmPasswordErrorMessage.push(
                'パスワードを再度入力してください。'
            );
        } else if (value !== password) {
            confirmPasswordErrorMessage.push('パスワードが一致しません。');
        }
        setFrontError({
            ...frontError,
            confirmPassword: {
                error:
                    confirmPasswordErrorMessage &&
                    confirmPasswordErrorMessage.length > 0,
                message: confirmPasswordErrorMessage,
            },
        });
    };

    // バリデーション
    const validation = (name, value) => {
        if (name === 'code') {
            codeValidation(value);
        } else if (name === 'password') {
            const validationResults = passwordValidation(
                value,
                userInfo.confirmPassword
            );
            setFrontError((prev) => ({
                ...prev,
                password: {
                    error: validationResults.password.error,
                    message: validationResults.password.message,
                },
                confirmPassword: {
                    error: validationResults.confirmPassword.error,
                    message: validationResults.confirmPassword.message,
                },
            }));
        } else if (name === 'confirmPassword') {
            confirmPasswordValidation(value, userInfo.password);
        }
    };

    // 入力時処理
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setUserInfo((params) => ({
            ...params,
            [name]: value,
        }));
        // バリデーションチェック
        validation(name, value);
    };

    // 登録処理
    const register = async (e) => {
        e.preventDefault(); // フォーム送信を防止
        setIsLoading(true);
        if (!isValid()) {
            setIsLoading(false);
            return;
        } else {
            try {
                const result = await Auth.forgotPasswordSubmit(
                    email,
                    userInfo.code,
                    userInfo.password
                );
                setter((prev) => ({ ...prev, target: 'signIn' }));
            } catch (err) {
                const message = changeJapaneseErrorMessage(err.message);
                setRemoteError({ status: true, message: message });
            } finally {
                setIsLoading(false);
            }
        }
    };

    // コード再送信処理
    const forgotPassword = async () => {
        setIsLoading(true);
        try {
            const result = await Auth.forgotPassword(email);
        } catch (err) {
            const message = changeJapaneseErrorMessage(err.message);
            setRemoteError({ status: true, message: message });
        } finally {
            setIsLoading(false);
        }
    };

    // ボタン有効化切り替え
    const isValid = () => {
        return !Object.values(frontError).some((e) => e.error) &&
            userInfo.code &&
            userInfo.password &&
            userInfo.confirmPassword
            ? true
            : false;
    };

    return (
        <div>
            {isLoading && <ModalFrame modalId="loading" />}
            <div className="modal__title">パスワードの変更</div>
            <div>
                <label htmlFor="" className="modal__content_input">
                    <p>検証コード</p>
                    <div>
                        <input
                            type="text"
                            name="code"
                            autoComplete="code"
                            onChange={changeHandler}
                        />
                    </div>
                </label>
                {frontError.code.error && (
                    <FrontErrorMessage message={frontError.code.message} />
                )}
                <label htmlFor="" className="modal__content_input">
                    <p>パスワード</p>
                    <PasswordInput name="password" onChange={changeHandler} />
                </label>
                {frontError.password.error && (
                    <FrontErrorMessage message={frontError.password.message} />
                )}
                <label htmlFor="" className="modal__content_input">
                    <p>パスワードの確認</p>
                    <PasswordInput
                        name="confirmPassword"
                        onChange={changeHandler}
                    />
                </label>
                {frontError.confirmPassword.error && (
                    <FrontErrorMessage
                        message={frontError.confirmPassword.message}
                    />
                )}
                <div className="modal__btn_area">
                    <button
                        className="modal__btn back"
                        onClick={() => {
                            setter((prev) => ({
                                ...prev,
                                target: 'resetPassword',
                            }));
                        }}
                    >
                        戻る
                    </button>
                    <button
                        className="modal__btn"
                        onClick={register}
                        disabled={!isValid()}
                    >
                        送信
                    </button>
                </div>
            </div>
            <div>
                <button
                    className="modal__btn resend"
                    onClick={() => forgotPassword()}
                >
                    コードを再送信
                </button>
            </div>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </div>
    );
};

export default ModalConfirmResetPassword;
