import { useContext, useState } from 'react';
import { AppContext } from '../View/Home';
import { Auth } from 'aws-amplify';
import { FrontErrorMessage } from './FrontErrorMessage';
import ModalFrame from './ModalFrame';
import { changeJapaneseErrorMessage } from '../common/utils';

/**
 * メールアドレス変更モーダル
 * @param {close} 閉じる処理
 * @param {setter}  モーダル内容設定
 * @returns {React.Element}
 */
const ModalUpdMail = ({ close, setter }) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const { data } = useContext(AppContext);
    const [frontError, setFrontError] = useState({
        email: { error: false, message: [] },
    });
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });

    // メールアドレスアドレスバリデーション
    const emailValidation = (value) => {
        const emailErrorMessage = [];
        if (value === '') {
            emailErrorMessage.push('メールアドレスを入力してください。');
        } else if (value === data.userInfo.mail) {
            emailErrorMessage.push('現在登録済みのメールアドレスです。');
        }
        setFrontError((prev) => ({
            ...prev,
            email: {
                error: emailErrorMessage && emailErrorMessage.length > 0,
                message: emailErrorMessage,
            },
        }));
    };

    // 入力時処理
    const handleChange = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
        emailValidation(e.target.value);
    };

    // 閉じる処理
    const handleClose = (e) => {
        e.preventDefault();
        close();
    };

    // 検証コード送信処理
    const handleMailVerif = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const user = await Auth.currentAuthenticatedUser();
            const response = await Auth.updateUserAttributes(user, {
                email: email,
            });

            setter({
                target: 'confirmSignUp',
                data: { state: 'update', email: email },
            });
        } catch (err) {
            const message = changeJapaneseErrorMessage(err.message);
            setRemoteError({ status: true, message: message });
        } finally {
            setLoading(false);
        }
    };

    const isValid = () => {
        return !Object.values(frontError).some((e) => e.error) && email
            ? true
            : false;
    };

    return (
        <>
            {loading && <ModalFrame modalId="loading" />}
            <div className="modal__title">メールアドレスの変更</div>
            <dl className="modal__save_box">
                <dt className="modal__save_title">メールアドレス</dt>
                <dd>
                    <input
                        className="modal__save_textbox"
                        type="text"
                        name="email"
                        value={email}
                        maxLength={256}
                        placeholder="example@siwakee.com"
                        onChange={handleChange}
                    />
                </dd>
                {frontError.email.error && (
                    <div style={{ marginTop: '10px' }}>
                        <FrontErrorMessage message={frontError.email.message} />
                    </div>
                )}
            </dl>
            <div className="modal__btn_area">
                <button
                    className="modal__btn modal__btn03 js-modal_close"
                    onClick={handleClose}
                >
                    戻る
                </button>
                <button
                    className="modal__btn modal__btn02"
                    onClick={handleMailVerif}
                    disabled={!isValid()}
                >
                    検証コードを送信
                </button>
            </div>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </>
    );
};

export default ModalUpdMail;
