import React from 'react';

/**
 * フロントエラーメッセージ表示
 * @param {message} エラーメッセージ
 * @returns {React.Element}
 */
export const FrontErrorMessage = ({ message }) => {
    // 改行コードを取得してメッセージを改行する処理
    const renderTextWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };
    return (
        <>
            {message.length > 0 && (
                <div style={{ display: 'flex', height: 'auto' }}>
                    <div className="modal__validation_textbox" />
                    <div className="modal__validation_error_message">
                        {message.map((v, idx) => {
                            return (
                                <span key={idx}>
                                    {renderTextWithLineBreaks(v)}
                                </span>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};
