import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { useContext, useState, useEffect, useMemo, React } from 'react';
import { AxiosContext } from './AxiosContext';
import { API, Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { changeJapaneseErrorMessage, isEmpty } from '../common/utils';
import { FrontErrorMessage } from './FrontErrorMessage';
import { AppContext } from '../View/Home';
import * as subscriptions from '../graphql/subscriptions';
import useFetch from '../hook/useFetch';
import ModalFrame from './ModalFrame';

import '../css/ModalStripeReg.css';

/**
 * 有料プラン登録完了モーダル
 * @param {close} 閉じる処理
 * @param {setter} モーダル内容設定
 * @param {type} セッター設定フラグ
 * @returns {React.Element}
 */
function ModalStripeReg({ close, setter, type }) {
    const { loaded, response } = useFetch('getProductInfo', 'GET', null);
    const instance = useContext(AxiosContext);
    const userInfoContext = useContext(AppContext);

    const elements = useElements();
    const stripe = useStripe();

    const [cardHolder, setCardHolder] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState([]);
    const [frontError, setFrontError] = useState({
        cardNumber: { error: false, message: [] },
        cardExpiry: { error: false, message: [] },
        cardCvc: { error: false, message: [] },
        cardHolder: { error: false, message: [] },
    });
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });

    const [selectedProd, setSelectedProd] = useState('');

    // 初期レンダリング時は入力値が空
    const [emptyCardNum, setEmptyCardNum] = useState(true);
    const [emptyCardExpiry, setEmptyCardExpiry] = useState(true);
    const [emptyCardCvc, setEmptyCardCvc] = useState(true);

    let subscription;
    let timeoutID;

    const stableResponse = useMemo(() => {
        return response || {};
    }, [response]);

    useEffect(() => {
        // 商品名と価格を取得する
        if (loaded && !isEmpty(stableResponse)) {
            setProduct(stableResponse.productList);
            if (
                userInfoContext &&
                userInfoContext.data &&
                userInfoContext.data.userInfo.lstProduct
            ) {
                setSelectedProd(userInfoContext.data.userInfo.lstProduct);
            } else {
                setSelectedProd(stableResponse.productList[0].priceId);
            }
            // setProduct({
            //     name: stableResponse.productName,
            //     priceId: stableResponse.priceId,
            // });
        }
    }, [loaded, stableResponse, userInfoContext]);

    //カード番号バリデーション処理
    const cardNumberValidation = (e) => {
        let cardNumberError = [];
        // 必須入力チェック
        if (e.empty) {
            cardNumberError.push('カード番号を入力してください。');
        }
        // エラーがあったら
        if (e.error) {
            cardNumberError.push(e.error.message);
        }
        setFrontError({
            ...frontError,
            cardNumber: {
                error: cardNumberError && cardNumberError.length > 0,
                message: cardNumberError,
            },
        });
    };

    // 有効期限バリデーション処理
    const cardExpiryValidation = (e) => {
        let cardExpiryError = [];
        // 必須入力チェック
        if (e.empty) {
            cardExpiryError.push('カード有効期限を入力してください。');
        }
        // エラーがあったら
        if (e.error) {
            cardExpiryError.push(e.error.message);
        }
        setFrontError({
            ...frontError,
            cardExpiry: {
                error: cardExpiryError && cardExpiryError.length > 0,
                message: cardExpiryError,
            },
        });
    };

    // セキュリティコードバリデーション処理
    const cardCvcValidation = (e) => {
        let cardCvcError = [];
        // 必須入力チェック
        if (e.empty) {
            cardCvcError.push('セキュリティコードを入力してください。');
        }
        // エラーがあったら
        if (e.error) {
            cardCvcError.push(e.error.message);
        }
        setFrontError({
            ...frontError,
            cardCvc: {
                error: cardCvcError && cardCvcError.length > 0,
                message: cardCvcError,
            },
        });
    };

    // カード名義人バリデーション処理
    const cardHolderValidation = (e) => {
        let cardHolderError = [];
        if (!e.target.value) {
            cardHolderError.push('カード名義人を入力してください。');
        }
        setFrontError({
            ...frontError,
            cardHolder: {
                error: cardHolderError && cardHolderError.length > 0,
                message: cardHolderError,
            },
        });
    };

    const changeHandler = (e) => {
        if (e.elementType === 'cardNumber') {
            cardNumberValidation(e);
            setEmptyCardNum(e.empty);
        } else if (e.elementType === 'cardExpiry') {
            cardExpiryValidation(e);
            setEmptyCardExpiry(e.empty);
        } else if (e.elementType === 'cardCvc') {
            cardCvcValidation(e);
            setEmptyCardCvc(e.empty);
        } else {
            setCardHolder(e.target.value);
            cardHolderValidation(e);
        }
    };

    const changeProduct = (e) => {
        setSelectedProd(product[e.target.selectedIndex].priceId);
    };

    // カード情報登録時の処理
    const registerStripeInfo = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!isValid()) {
            setIsLoading(false);
            return;
        }
        try {
            const params = {
                priceId: selectedProd,
            };
            const response = await instance.request({
                data: params,
                method: 'POST',
                url: 'convertToPaid',
            });

            const customerSecretId =
                response.data.contentsInfo.customerSecretId; // 顧客シークレットID
            // このAPIの処理後にwebhookが起動する
            const result = await stripe.confirmCardPayment(customerSecretId, {
                payment_method: {
                    card: elements.getElement('cardNumber'),
                    billing_details: {
                        name: cardHolder, // カード名義人
                    },
                },
                save_payment_method: true,
                setup_future_usage: 'off_session',
            });
            // paidにならなかったらErrorを表示したい。
            // paidになったらtimeoutを終わり、ツール画面に遷移
            // 10秒以内にpaidにならなかったらErrorを表示したい
            if (!result.error) {
                timeoutID = setTimeout(errorHandling, 10000);
                checkMutation(timeoutID);
            } else {
                // エラーコードがcard_declinedの時は拒否コードをthrowする
                if (
                    result.error.code === 'card_declined' &&
                    result.error.decline_code
                ) {
                    throw Error(result.error.decline_code);
                    // それ以外の場合はエラーコードをthrowする
                } else {
                    throw Error(result.error.code);
                }
            }
        } catch (error) {
            setIsLoading(false);
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
            if (type === 'convert') {
                setter({
                    target: 'updateComplete',
                    data: {
                        title: '決済結果',
                        message:
                            // エラーコードor拒否コードを見てエラーメッセージをカスタマイズする
                            changeJapaneseErrorMessage(
                                error.response?.data.errInfo || error.message
                            ),
                    },
                });
            } else {
                setter({
                    target: 'stripeComplete',
                    data: {
                        isSuccess: false,
                        message:
                            // エラーコードor拒否コードを見てエラーメッセージをカスタマイズする
                            changeJapaneseErrorMessage(
                                error.response?.data.errInfo || error.message
                            ),
                    },
                });
            }
        }
    };

    const errorHandling = () => {
        // Loadingを非表示
        setIsLoading(false);
        // Appsyncをunsubscribe
        subscription.unsubscribe();
        // エラーメッセージ表示
        if (type === 'convert' || type === 'planupd') {
            setter({
                target: 'updateComplete',
                data: {
                    title: '決済結果',
                    message: 'タイムアウトが発生しました。',
                },
            });
        } else {
            setter({
                target: 'stripeComplete',
                data: {
                    isSuccess: false,
                    message: 'タイムアウトが発生しました。',
                },
            });
        }
    };

    const checkMutation = async (timeoutID) => {
        const user = await Auth.currentAuthenticatedUser();
        subscription = await API.graphql({
            query: subscriptions.onUpdateSiwakeeInfo,
            variables: {
                USER: user.username,
                SORT_KEY: 'user',
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        }).subscribe({
            next: async ({ provider, value }) => {
                try {
                    // Timeoutをクリアー
                    clearTimeout(timeoutID);

                    const USER_TYPE = value.data.onUpdateSiwakeeInfo.USER_TYPE;

                    // Appsyncをunsubscribe
                    subscription.unsubscribe();

                    // Paid になったらツール画面に移動
                    if (USER_TYPE === 'paid') {
                        if (type === 'convert') {
                            const info = await instance.request({
                                data: null,
                                method: 'POST',
                                url: 'initAccount',
                            });
                            userInfoContext.setter(
                                JSON.parse(info.data.contentsInfo)
                            );
                            setter({
                                target: 'updateComplete',
                                data: {
                                    title: '有料プラン登録完了',
                                    message:
                                        '決済が成功し、有料プランの登録が完了しました。',
                                    shouldReload: true,
                                },
                            });
                        } else {
                            setter({
                                target: 'stripeComplete',
                                data: { isSuccess: true },
                            });
                        }
                        // Paid　にならなかったら、エラーメッセージ
                    } else {
                        throw new Error();
                    }
                } catch (error) {
                    setter({
                        target: 'stripeComplete',
                        data: { isSuccess: false, message: error.message },
                    });
                } finally {
                    setIsLoading(false);
                }
            },
            error: (error) => {
                console.error(error);
                try {
                    subscription.unsubscribe();
                } catch (error) {
                    console.error(error);
                } finally {
                    setter({
                        target: 'stripeComplete',
                        data: { isSuccess: false },
                    });
                    //　Loadingを非表示
                    setIsLoading(false);
                }
            },
        });
    };

    const checkMutationUpdateUserStatus = async (timeoutID) => {
        const user = await Auth.currentAuthenticatedUser();
        subscription = await API.graphql({
            query: subscriptions.onUpdateSiwakeeInfo,
            variables: {
                USER: user.username,
                SORT_KEY: 'user',
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        }).subscribe({
            next: async ({ provider, value }) => {
                let wait = true;
                try {
                    const USER_STATUS =
                        value.data.onUpdateSiwakeeInfo.USER_STATUS;

                    // Active になったらステータス状態変更
                    if (USER_STATUS === 'active') {
                        wait = false;
                        // Timeoutをクリアー
                        clearTimeout(timeoutID);
                        // Appsyncをunsubscribe
                        subscription.unsubscribe();
                        if (type === 'planupd') {
                            const info = await instance.request({
                                data: null,
                                method: 'POST',
                                url: 'initAccount',
                            });
                            userInfoContext.setter(
                                JSON.parse(info.data.contentsInfo)
                            );
                            setter({
                                target: 'updateComplete',
                                data: {
                                    title: 'プラン変更完了',
                                    message:
                                        '決済が成功し、プラン変更が完了しました。',
                                },
                            });
                        } else {
                            userInfoContext.setter({
                                ...userInfoContext.data,
                                userInfo: {
                                    ...userInfoContext.data.userInfo,
                                    userStatus: USER_STATUS,
                                },
                            });
                            setter({
                                target: 'updateComplete',
                                data: {
                                    title: '有料プラン登録完了',
                                    message:
                                        '決済が成功し、有料プランの登録が完了しました。',
                                },
                            });
                        }
                        // activeにならなかったら、エラーメッセージ
                    } else if (USER_STATUS === 'pending') {
                        wait = false;
                        clearTimeout(timeoutID);
                        // Appsyncをunsubscribe
                        subscription.unsubscribe();
                        throw new Error(
                            'ユーザーステータスが変更できませんでした。'
                        );
                    }
                } catch (error) {
                    setter({
                        target: 'updateComplete',
                        data: {
                            title:
                                type === 'planupd'
                                    ? 'プラン変更失敗'
                                    : 'カード情報変更失敗',
                            message: changeJapaneseErrorMessage(error.message),
                        },
                    });
                } finally {
                    if (!wait) {
                        setIsLoading(false);
                    }
                }
            },
            error: (error) => {
                console.error(error);
                try {
                    subscription.unsubscribe();
                } catch (error) {
                    console.error(error);
                } finally {
                    setter({
                        target: 'updateComplete',
                        data: {
                            title:
                                type === 'planupd'
                                    ? 'プラン変更失敗'
                                    : 'カード情報変更失敗',
                            message: changeJapaneseErrorMessage(error.message),
                        },
                    });
                    // Loadingを非表示
                    setIsLoading(false);
                }
            },
        });
    };

    // カード情報変更時の処理
    const updateStripeInfo = async () => {
        setIsLoading(true);
        try {
            // paymentMethodの新規作成
            const params = { stripeInfo: {} };
            let result;
            if (type === 'planupd') {
                timeoutID = setTimeout(errorHandling, 10000);
                checkMutationUpdateUserStatus(timeoutID);
                params.stripeInfo.productID = selectedProd;
                result = await instance.request({
                    data: params,
                    method: 'POST',
                    url: 'updAccount',
                });
            } else {
                const paymentMethodResult = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement('cardNumber'),
                    billing_details: {
                        name: cardHolder, // カード名義人
                    },
                });
                if (paymentMethodResult.error) {
                    throw Error(paymentMethodResult.error.code);
                }
                params.stripeInfo.paymentMethodID =
                    paymentMethodResult.paymentMethod.id;
                params.stripeInfo.confirmed = false;
                result = await instance.request({
                    data: params,
                    method: 'POST',
                    url: 'updAccount',
                });

                if (result.data.contentsInfo.secret) {
                    const resultConfCardSetUp = await stripe.confirmCardSetup(
                        result.data.contentsInfo.secret
                    );
                    const params = {
                        stripeInfo: {
                            paymentMethodID:
                                paymentMethodResult.paymentMethod.id,
                            confirmed: true,
                        },
                    };

                    if (resultConfCardSetUp.error) {
                        throw Error('setup_error');
                    }

                    result = await instance.request({
                        data: params,
                        method: 'POST',
                        url: 'updAccount',
                    });
                }

                userInfoContext.setter((prev) => ({
                    ...prev,
                    stripeInfo: {
                        ...prev.stripeInfo,
                        card4: result.data.contentsInfo.card4,
                        expirationDtYear:
                            result.data.contentsInfo.expirationDtYear,
                        expirationDtMonth:
                            result.data.contentsInfo.expirationDtMonth,
                        cardHolder: cardHolder,
                        currentPeriodStart: result.data.contentsInfo
                            .currentPeriodStart
                            ? result.data.contentsInfo.currentPeriodStart
                            : prev.stripeInfo.currentPeriodStart,
                        currentPeriodEnd: result.data.contentsInfo
                            .currentPeriodEnd
                            ? result.data.contentsInfo.currentPeriodEnd
                            : prev.stripeInfo.currentPeriodEnd,
                    },
                }));

                if (result.data.contentsInfo.mustWait) {
                    timeoutID = setTimeout(errorHandling, 10000);
                    checkMutationUpdateUserStatus(timeoutID);
                } else {
                    setter({
                        target: 'updateComplete',
                        data: {
                            title: 'クレジットカード情報変更完了',
                            message:
                                'クレジットカード情報の変更が完了しました。',
                        },
                    });
                    setIsLoading(false);
                }
            }
        } catch (err) {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
            if (subscription) {
                subscription.unsubscribe();
            }

            if (type === 'planupd') {
                setter({
                    target: 'updateComplete',
                    data: {
                        title: 'プラン変更中断',
                        message:
                            'ご登録済みのクレジットカードで決済に失敗したため、プラン変更が中断されました。' +
                            'マイページ「決済情報」欄の変更ボタンから他のくれじっどカードを登録した上で、改めてプラン変更をお試しください。',
                    },
                });
            } else {
                const message = changeJapaneseErrorMessage(
                    (err.response?.data.errInfo &&
                        `mp_${err.response?.data.errInfo}`) ||
                        err.message
                );
                setRemoteError({ status: true, message: message });
            }
            setIsLoading(false);
        }
    };

    // 有料登録をやめるボタンの処理
    const stopStripeRegister = () => {
        // カード情報登録画面を閉じて、無償会員でツール画面を表示
        // ツール画面に移動する前に警告モーダルを表示するかも？
        setter({ target: 'freeUserComplete', data: 'cancelSubscription' });
    };

    // 戻るボタンの処理
    const goToBack = () => {
        close();
    };

    const isValid = () => {
        if (userInfoContext && type === 'planupd') {
            const selectedIndex = product.findIndex(
                (prod) => prod.priceId === selectedProd
            );
            if (selectedIndex >= 0) {
                return (
                    userInfoContext.data.userInfo.product !==
                    product[selectedIndex].productName
                );
            } else {
                return false;
            }
        } else {
            return !Object.values(frontError).some((e) => e.error) &&
                cardHolder !== '' &&
                !emptyCardNum &&
                !emptyCardExpiry &&
                !emptyCardCvc
                ? true
                : false;
        }
    };

    return (
        <>
            {(!loaded || isLoading) && <ModalFrame modalId="loading" />}
            <div className="modal__title">
                {!type
                    ? 'クレジットカード情報変更'
                    : type === 'planupd'
                    ? 'プランの変更'
                    : 'クレジットカード情報登録'}
            </div>
            <div>
                {type !== 'planupd' && (
                    <>
                        <label
                            htmlFor="cardNumber"
                            className="modal__content_input"
                        >
                            <p>カード番号　※必須</p>
                            <CardNumberElement
                                id="cardNumber"
                                name="cardNumber"
                                options={{
                                    placeholder: '',
                                    classes: { base: 'cardNum' },
                                    style: {
                                        base: {
                                            '::placeholder': {
                                                color: 'rgba(88, 123, 170, .5)',
                                            },
                                        },
                                        invalid: { color: 'black' },
                                    },
                                }}
                                onChange={changeHandler}
                            ></CardNumberElement>
                        </label>
                        <FrontErrorMessage
                            message={frontError.cardNumber.message}
                        />
                        <label
                            htmlFor="cardExpiration"
                            className="modal__content_input"
                        >
                            <p>カード有効期限（月/年）　※必須</p>
                            <CardExpiryElement
                                id="cardExpiration"
                                name="cardExpiration"
                                options={{
                                    placeholder: '',
                                    classes: { base: 'cardNum' },
                                    style: {
                                        base: {
                                            '::placeholder': {
                                                color: 'rgba(88, 123, 170, .5)',
                                            },
                                        },
                                        invalid: { color: 'black' },
                                    },
                                }}
                                onChange={changeHandler}
                            ></CardExpiryElement>
                        </label>
                        <FrontErrorMessage
                            message={frontError.cardExpiry.message}
                        />
                        <label
                            htmlFor="securityCode"
                            className="modal__content_input"
                        >
                            <p>セキュリティコード　※必須</p>
                            <CardCvcElement
                                id="securityCode"
                                name="securityCode"
                                options={{
                                    placeholder: '',
                                    classes: { base: 'cardNum' },
                                    style: {
                                        base: {
                                            '::placeholder': {
                                                color: 'rgba(88, 123, 170, .5)',
                                            },
                                        },
                                        invalid: { color: 'black' },
                                    },
                                }}
                                onChange={changeHandler}
                            ></CardCvcElement>
                        </label>
                        <FrontErrorMessage
                            message={frontError.cardCvc.message}
                        />
                        <label
                            htmlFor="cardHolder"
                            className="modal__content_input"
                        >
                            <p>カード名義人　※必須</p>
                            <div>
                                <input
                                    type="text"
                                    onChange={changeHandler}
                                    value={cardHolder}
                                    id="cardHolder"
                                    name="cardHolder"
                                    // style={{ borderColor: 'red', borderWidth: 2, width: '100%', textAlign: 'left' }}
                                />
                            </div>
                        </label>
                        <FrontErrorMessage
                            message={frontError.cardHolder.message}
                        />
                    </>
                )}
                {!type ? null : (
                    // カード情報登録時のみ表示する
                    <>
                        <label
                            htmlFor="purchaseProduct"
                            className="modal__content_input"
                        >
                            <p>購入プラン</p>
                            <div className="modal__content_select">
                                <select
                                    id="purchaseProduct"
                                    name="purchaseProduct"
                                    onChange={changeProduct}
                                    value={selectedProd}
                                >
                                    {product.map((v, idx) => {
                                        return (
                                            <option key={idx} value={v.priceId}>
                                                {v.productName}
                                            </option>
                                        );
                                    })}

                                    {/* <option value="">{product.name}</option> */}
                                </select>
                            </div>
                        </label>
                        <div className="modal__terms_area">
                            <a
                                href="/portal/entry/2023/07/20/162046"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                利用規約
                            </a>
                            を参照する
                        </div>
                    </>
                )}
                <div className="modal__btn_area">
                    {!type || type === 'convert' || type === 'planupd' ? (
                        // カード情報変更時
                        <button className="modal__btn back" onClick={goToBack}>
                            戻る
                        </button>
                    ) : (
                        // カード情報登録時
                        <button
                            className="modal__btn back"
                            onClick={stopStripeRegister}
                        >
                            有料登録をやめる
                        </button>
                    )}
                    <button
                        className="modal__btn"
                        onClick={
                            !type || type === 'planupd'
                                ? updateStripeInfo
                                : registerStripeInfo
                        }
                        disabled={!isValid()}
                    >
                        {!type ? '登録' : '決済'}
                    </button>
                </div>
            </div>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </>
    );
}

export default ModalStripeReg;
