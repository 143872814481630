/**
 * データ更新ある場合、未保存確認モーダル画面
 * @param {close} 閉じる処理
 * @param {target} 保存しない場合、次の画面・モダールに移動。
 * @param {setter} モーダル内容設定
 * @returns
 */
const ModalUnsaved = ({ close, target, setter }) => {
    // 画面遷移処理
    const continueToTarget = () => {
        setter({ target: target, data: undefined });
    };

    return (
        <>
            <p className="modal__text">
                変更が保存されていません。
                <br />
                このまま進めると変更内容が失われる場合があります。
            </p>
            <div className="modal__btn_area">
                <button
                    className="modal__btn modal__btn03 js-modal_close"
                    onClick={() => {
                        close(true);
                    }}
                >
                    戻る
                </button>
                <button
                    className="modal__btn modal__btn02"
                    onClick={continueToTarget}
                >
                    OK
                </button>
            </div>
        </>
    );
};

export default ModalUnsaved;
