import Tooltip from './Tooltip';

/**
 * 変換データヘッダー
 * @param {memo} メモーかヘッダ行判断
 * @param {col} 列数
 * @param {val} 値
 * @param {valChange} データ更新処理
 * @param {last} 最後か最後の前の列の場合：True
 * @returns
 */
const ToolDataHeader = ({ memo, col, val, valChange, last }) => {
    return (
        <>
            {memo ? (
                <div className="sheet__memo_heder--line1">
                    <div
                        className={
                            val.length > 12
                                ? 'sheet__memo_text js-help'
                                : 'sheet__memo_text'
                        }
                    >
                        <input
                            className="sheet__textarea"
                            cols="23"
                            rows="1"
                            value={val}
                            onChange={(e) =>
                                valChange(e, col, 0, e.target.value)
                            }
                        />
                        {val.length > 12 ? (
                            <Tooltip last={last} type="memo">
                                {val}
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </div>
                    <button
                        className="sheet__delete"
                        onClick={(e) => valChange(e, col, 0, '')}
                    />
                </div>
            ) : (
                <div className="sheet__memo_heder--line2">
                    <div
                        className={
                            val.length > 12
                                ? 'sheet__memo_text js-help'
                                : 'sheet__memo_text'
                        }
                    >
                        <input
                            className="sheet__textarea"
                            cols="20"
                            rows="1"
                            value={val}
                            onChange={(e) =>
                                valChange(e, col, 1, e.target.value)
                            }
                        />
                        {val.length > 12 ? (
                            <Tooltip last={last} type="memo">
                                {val}
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </div>
                    <button
                        className="sheet__delete"
                        onClick={(e) => valChange(e, col, 1, '')}
                    />
                </div>
            )}
        </>
    );
};

export default ToolDataHeader;
