import { useContext, useEffect, useRef, useState } from 'react';
import { AxiosContext } from './AxiosContext';
import { AppContext } from '../View/Home';
import ModalFrame from './ModalFrame';

import '../css/ModalInquiryConfirm.css';
/**
 * サポート内容確認
 * @param {close} モダール閉じる処理
 * @param {data}  サポートデーター
 * @param {setter} set関数
 * @returns
 */
function ModalConfirmInquiry({ close, data, setter }) {
    const instance = useContext(AxiosContext);
    const userInfo = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });

    const txtAreaRef = useRef();

    const [txtAreaH, setTxtAreaH] = useState({ height: '25px' });

    useEffect(() => {
        if (txtAreaRef.current) {
            setTxtAreaH({
                height: txtAreaRef.current.scrollHeight + 'px',
            });
        }
    }, []);

    const sendInquiry = async (e) => {
        //　確認画面で登録処理実行
        setIsLoading(true);
        try {
            e.preventDefault();
            const params = {
                name: userInfo.data.nameCompanyInfo.name,
                suppType: data.inquiryType,
                accSoft:
                    data.accountingSoft === 'その他'
                        ? data.accountingSoft + '：' + data.accountingSoftInput
                        : data.accountingSoft,
                spreadstSoft:
                    data.spreadstSoft === 'Excel以外'
                        ? data.spreadstSoft + '：' + data.spreadstSoftInput
                        : data.spreadstSoft,
                content: data.content,
            };
            const response = await instance.request({
                data: params,
                method: 'POST',
                url: 'sendInquiry',
            });
            setter({
                target: 'updateComplete',
                data: {
                    title: '',
                    message: 'サポート依頼が送信されました。',
                    shouldReload: false,
                },
            });
        } catch (err) {
            setRemoteError({
                status: true,
                message:
                    'システムエラーによりサポート依頼が送信されませんでした。もう一度送信を試しても送信できない場合は、時間を空けて改めて送信してください。',
            });
        } finally {
            setIsLoading(false);
        }
    };

    // 閉じる処理
    const handleClose = (e) => {
        e.preventDefault();
        close();
    };

    return (
        <div>
            {isLoading && <ModalFrame modalId="loading" />}
            <div className="modal__title">サポート依頼確認</div>
            <div>
                <label htmlFor="country" className="modal__content_input">
                    <p>サポート種別</p>
                    <div className="confirm_area">{data.inquiryType}</div>
                </label>
                <label htmlFor="name" className="modal__content_input">
                    <p>お使いの会計ソフト</p>
                    <div className="confirm_area">
                        {data.accountingSoft === 'その他'
                            ? data.accountingSoft +
                              '：' +
                              data.accountingSoftInput
                            : data.accountingSoft}
                    </div>
                </label>

                <label htmlFor="name_kana" className="modal__content_input">
                    <p>お使いの表計算ソフト</p>
                    <div className="confirm_area">
                        {data.spreadstSoft === 'Excel以外'
                            ? data.spreadstSoft + '：' + data.spreadstSoftInput
                            : data.spreadstSoft}
                    </div>
                </label>
                <label htmlFor="company" className="modal__content_input">
                    <p>ご依頼内容</p>
                    <textarea
                        className="inquiry__textArea_confirm"
                        defaultValue={data.content}
                        style={txtAreaH}
                        ref={txtAreaRef}
                        disabled
                    />
                </label>
                <div className="modal__btn_area">
                    <button className="modal__btn back" onClick={handleClose}>
                        戻る
                    </button>
                    <button className="modal__btn" onClick={sendInquiry}>
                        送信
                    </button>
                </div>
            </div>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </div>
    );
}

export default ModalConfirmInquiry;
