import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../View/Home';
import { ModalContext } from '../App';
import ModalFrame from './ModalFrame';

import '../css/inquiry.css';

const Inquiry = () => {
    const { data } = useContext(AppContext);
    const dataStatus = useContext(ModalContext);
    const [inquiry, setInquiry] = useState({
        inquiryType: '導入支援の見積依頼',
        accountingSoft: '勘定奉行（インストール型）',
        accountingSoftInput: '',
        spreadstSoft: 'Excel',
        spreadstSoftInput: '',
        content: '',
    });

    const [isSuppSub, setIsSuppSub] = useState(false);

    const textAreaRef = useRef();

    useEffect(() => {
        dataStatus.setter({
            display: false,
            dataChanged: false,
            target: '',
            data: undefined,
        });
    }, []);

    useEffect(() => {
        if (data) {
            const nowDate = Date.now();
            if (data.userInfo.suppEdDtJst) {
                const suppEdDate = new Date(data.userInfo.suppEdDtJst);
                if (nowDate < suppEdDate) {
                    setIsSuppSub(true);
                } else {
                    setIsSuppSub(false);
                }
            } else if (
                data.userInfo.userType !== 'free' &&
                data.userInfo.planType === '1'
            ) {
                setIsSuppSub(true);
            }
        }
    }, [data]);

    const handleChange = (e, source) => {
        e.preventDefault();
        setInquiry({ ...inquiry, [source]: e.target.value });
    };

    const sendInquiry = async (e) => {
        e.preventDefault();
        dataStatus.setter({
            dataChanged: false,
            display: true,
            target: 'inquiry',
            data: inquiry,
        });
    };

    return (
        <>
            <main className="inquiryMain main">
                <div className="inquiry_title">
                    <h2 className="inquiry_title__headline">サポート依頼</h2>
                </div>
                <div className="inquiry">
                    <div className="content_inquiry">
                        <div className="inquiry_block">
                            <div className="about_block__detail _inquiry">
                                <div>
                                    <div className="inquiry_item">
                                        <p className="inquiry_item_title">
                                            メールアドレス
                                        </p>
                                        <div
                                            className="inquiry_confirm_area inquiry__no_events "
                                            id="email"
                                        >
                                            {data ? data.userInfo.mail : ''}
                                        </div>
                                    </div>

                                    <div className="inquiry_item">
                                        <p className="inquiry_item_title">
                                            サポート種別（右記「サポートについて」を必ずお読みください。）
                                        </p>

                                        <div className="inquiry_link__area">
                                            <a
                                                className="inquiry__link"
                                                href="/portal/entry/2023/12/28/163204"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                サポートについて
                                            </a>
                                        </div>

                                        <div className="inquiry_type__radio_wrap">
                                            <label className="radio">
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_type"
                                                    checked={
                                                        inquiry.inquiryType ===
                                                        '導入支援の見積依頼'
                                                    }
                                                    onChange={(e) => {
                                                        setInquiry({
                                                            ...inquiry,
                                                            inquiryType: e
                                                                .target.checked
                                                                ? '導入支援の見積依頼'
                                                                : 'メールサポート',
                                                        });
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    導入支援の見積依頼
                                                </span>
                                            </label>

                                            <label
                                                className={
                                                    !isSuppSub
                                                        ? 'radio inquiry_rightradio inquiry__no_events'
                                                        : 'radio inquiry_rightradio'
                                                }
                                            >
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_type"
                                                    disabled={!isSuppSub}
                                                    checked={
                                                        inquiry.inquiryType ===
                                                        'メールサポート'
                                                    }
                                                    onChange={(e) => {
                                                        setInquiry({
                                                            ...inquiry,
                                                            inquiryType: e
                                                                .target.checked
                                                                ? 'メールサポート'
                                                                : '導入支援の見積依頼',
                                                        });
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    メールサポート（月額税込3,806円のプランのみ）
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="inquiry_item">
                                        <p className="inquiry_item_title">
                                            お使いの会計ソフト　
                                        </p>

                                        <div className="inquiry_link__area">
                                            <a
                                                className="inquiry__link"
                                                href="/portal/entry/2023/12/28/163218"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                サポート対象ソフトについて
                                            </a>
                                        </div>
                                        <div className="inquiry_accSoft_Bloc">
                                            <label className="radio inquiry_radioMargin">
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_acc_soft"
                                                    checked={
                                                        inquiry.accountingSoft ===
                                                        '勘定奉行（インストール型）'
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setInquiry({
                                                                ...inquiry,
                                                                accountingSoft:
                                                                    '勘定奉行（インストール型）',
                                                                accountingSoftInput:
                                                                    '',
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    勘定奉行（インストール型）
                                                </span>
                                            </label>

                                            <label className="radio inquiry_radioMargin">
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_acc_soft"
                                                    checked={
                                                        inquiry.accountingSoft ===
                                                        '勘定奉行クラウド'
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setInquiry({
                                                                ...inquiry,
                                                                accountingSoft:
                                                                    '勘定奉行クラウド',
                                                                accountingSoftInput:
                                                                    '',
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    勘定奉行クラウド
                                                </span>
                                            </label>

                                            <label className="radio inquiry_radioMargin">
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_acc_soft"
                                                    checked={
                                                        inquiry.accountingSoft ===
                                                        '弥生会計（インストール型）'
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setInquiry({
                                                                ...inquiry,
                                                                accountingSoft:
                                                                    '弥生会計（インストール型）',
                                                                accountingSoftInput:
                                                                    '',
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    弥生会計（インストール型）
                                                </span>
                                            </label>

                                            <label className="radio inquiry_radioMargin">
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_acc_soft"
                                                    checked={
                                                        inquiry.accountingSoft ===
                                                        '弥生会計オンライン'
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setInquiry({
                                                                ...inquiry,
                                                                accountingSoft:
                                                                    '弥生会計オンライン',
                                                                accountingSoftInput:
                                                                    '',
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    弥生会計オンライン
                                                </span>
                                            </label>

                                            <label className="radio inquiry_radioMargin">
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_acc_soft"
                                                    checked={
                                                        inquiry.accountingSoft ===
                                                        'マネーフォワードクラウド会計'
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setInquiry({
                                                                ...inquiry,
                                                                accountingSoft:
                                                                    'マネーフォワードクラウド会計',
                                                                accountingSoftInput:
                                                                    '',
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    マネーフォワード
                                                    クラウド会計
                                                </span>
                                            </label>

                                            <label className="radio inquiry_radioMargin">
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_acc_soft"
                                                    checked={
                                                        inquiry.accountingSoft ===
                                                        'freee会計'
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setInquiry({
                                                                ...inquiry,
                                                                accountingSoft:
                                                                    'freee会計',
                                                                accountingSoftInput:
                                                                    '',
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    freee会計
                                                </span>
                                            </label>

                                            <div className="inquiry_radio_input">
                                                <label className="radio inquiry__RadioAcc">
                                                    <input
                                                        className="radio__input_wrap"
                                                        type="radio"
                                                        name="radio_acc_soft"
                                                        checked={
                                                            inquiry.accountingSoft ===
                                                            'その他'
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                setInquiry({
                                                                    ...inquiry,
                                                                    accountingSoft:
                                                                        'その他',
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <span className="radio__input"></span>
                                                    <span className="radio__text">
                                                        その他
                                                    </span>
                                                </label>
                                                <input
                                                    className="inquiry__textBox"
                                                    type="text"
                                                    disabled={
                                                        inquiry.accountingSoft !==
                                                        'その他'
                                                    }
                                                    onChange={(e) => {
                                                        handleChange(
                                                            e,
                                                            'accountingSoftInput'
                                                        );
                                                    }}
                                                    value={
                                                        inquiry.accountingSoftInput
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inquiry_item">
                                        <p className="inquiry_item_title">
                                            お使いの表計算ソフト
                                        </p>
                                        <div className="inquiry_type__radio_wrap">
                                            <label className="radio">
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_soft"
                                                    checked={
                                                        inquiry.spreadstSoft ===
                                                        'Excel'
                                                    }
                                                    onChange={(e) => {
                                                        setInquiry({
                                                            ...inquiry,
                                                            spreadstSoft: e
                                                                .target.checked
                                                                ? 'Excel'
                                                                : 'Excel以外',
                                                            spreadstSoftInput:
                                                                '',
                                                        });
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    Excel
                                                </span>
                                            </label>

                                            <label className="radio inquiry_rightradio inquiry__RadioSoft">
                                                <input
                                                    className="radio__input_wrap"
                                                    type="radio"
                                                    name="radio_soft"
                                                    checked={
                                                        inquiry.spreadstSoft ===
                                                        'Excel以外'
                                                    }
                                                    onChange={(e) => {
                                                        setInquiry({
                                                            ...inquiry,
                                                            spreadstSoft: e
                                                                .target.checked
                                                                ? 'Excel以外'
                                                                : 'Excel',
                                                        });
                                                    }}
                                                />
                                                <span className="radio__input"></span>
                                                <span className="radio__text">
                                                    Excel以外
                                                </span>
                                            </label>

                                            <input
                                                className="inquiry__textBox"
                                                type="text"
                                                disabled={
                                                    inquiry.spreadstSoft !==
                                                    'Excel以外'
                                                }
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        'spreadstSoftInput'
                                                    );
                                                }}
                                                value={
                                                    inquiry.spreadstSoftInput
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="inquiry_item">
                                        <p className="inquiry_item_title">
                                            ご依頼内容
                                        </p>
                                        <textarea
                                            id="inquiryContent"
                                            className="inquiry__textArea"
                                            defaultValue=""
                                            onChange={(e) => {
                                                handleChange(e, 'content');
                                            }}
                                            ref={textAreaRef}
                                        />
                                    </div>
                                </div>
                                <div className="inquiry__btn_area">
                                    <button
                                        className="inquiry__btn"
                                        onClick={sendInquiry}
                                    >
                                        確認
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {dataStatus.data.display && (
                    <ModalFrame
                        modalId={dataStatus.data.target}
                        data={dataStatus.data.data}
                    />
                )}
            </main>
        </>
    );
};

export default Inquiry;
