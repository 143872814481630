import { useState } from 'react';

/**
 * パスワード入力input
 * @param {name} パスワードかパスワード確認かの判定フラグ
 * @param {onChange}  入力内容セット処理
 * @returns {React.Element}
 */
const PasswordInput = ({ name, onChange }) => {
    const [passwordStyle, setPasswordStyle] = useState({
        type: 'password',
        className: 'eye',
    });

    // 表示切り替え処理
    const eyeClickHandler = () => {
        if (passwordStyle.type === 'password') {
            setPasswordStyle({ type: 'text', className: 'eye-slash' });
        } else {
            setPasswordStyle({ type: 'password', className: 'eye' });
        }
    };

    return (
        <div className="password__area">
            <input
                className="modal__save_textbox"
                type={passwordStyle.type}
                name={name}
                autoComplete="new-password"
                maxLength={256}
                onChange={onChange}
            />
            <span
                className={passwordStyle.className}
                onClick={eyeClickHandler}
            ></span>
        </div>
    );
};

export default PasswordInput;
