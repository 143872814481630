import axios from 'axios';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AxiosContext } from '../components/AxiosContext';
import { isEmptyString } from '../common/utils';

const useFetch = (url, requestType, body) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const contextInstance = useContext(AxiosContext);
    const instance = useMemo(() => {
        return contextInstance || axios;
    }, [contextInstance]);

    async function fetchData() {
        setLoaded(false);
        setError(null);
        try {
            const response = await instance.request({
                data: body,
                method: requestType,
                url,
            });
            const responseBody = response.data;
            if (isEmptyString(responseBody.errInfo)) {
                setResponse(responseBody.contentsInfo);
            } else {
                throw new Error(responseBody.errInfo);
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoaded(true);
        }
    }

    //eslint-disable-next-line
    useEffect(() => {
        fetchData();
    }, []);
    return { loaded, error, response };
};

export default useFetch;
