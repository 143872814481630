import { useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { createContext } from 'react';
import { getUserType, isEmpty } from '../common/utils';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Tool from '../components/Tool';
import useFetch from '../hook/useFetch';
import ModalFrame from '../components/ModalFrame';
import MyPage from '../components/MyPage';
import Inquiry from '../components/Inquiry';

import '../css/common.css';
import '../css/reset.css';

export const AppContext = createContext();

const Home = () => {
    const { loaded, response } = useFetch('initAccount', 'POST', null);
    const [userInfo, setUserInfo] = useState();

    const stableResponse = useMemo(() => {
        return response || {};
    }, [response]);

    useEffect(() => {
        if (loaded && !isEmpty(stableResponse)) {
            const parsedUserInfo = JSON.parse(stableResponse);
            setUserInfo({
                ...parsedUserInfo,
                userInfo: {
                    ...parsedUserInfo.userInfo,
                    userType: getUserType(parsedUserInfo.userInfo),
                },
            });
        }
    }, [loaded, stableResponse]);

    return (
        <div className="app" id="root">
            <AppContext.Provider
                value={{ data: userInfo, setter: setUserInfo }}
            >
                <Header />
                {!loaded ? (
                    <ModalFrame modalId="loading" />
                ) : (
                    <Routes>
                        <Route exact path="/" element={<Tool />} />
                        <Route exact path="/mypage/*" element={<MyPage />} />
                        <Route exact path="/inquiry" element={<Inquiry />} />
                        <Route
                            path="*"
                            element={<Navigate to="/home" replace />}
                        />
                        {/* page-not-found route*/}
                    </Routes>
                )}
                <Footer />
            </AppContext.Provider>
        </div>
    );
};

export default Home;
