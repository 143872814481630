import React from 'react';
import '../css/ModalWarning.css';
/**
 * データ更新ある場合、未保存確認モーダル画面
 * @param {close} 閉じる処理
 * @param {target} 保存しない場合、次の画面・モダールに移動。
 * @param {setter} モーダル内容設定
 * @returns
 */
const ModalWarning = ({ close, data }) => {
    const { message, onClickOK, onClickCancel } = data;

    // 改行コードを取得してメッセージを改行する処理
    const renderTextWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <>
            <p className="modal__text">{renderTextWithLineBreaks(message)}</p>
            <div className="modal__btn_area">
                <button
                    className="modal__btn js-modal_close"
                    onClick={() => onClickCancel(false)}
                >
                    キャンセル
                </button>
                <button className="modal__btn" onClick={() => onClickOK()}>
                    OK
                </button>
            </div>
        </>
    );
};

export default ModalWarning;
