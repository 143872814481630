/**
 * マイページ保存済定義一覧タブで三点メニュー押下時に表示するメニュー
 * @param {id} 一意な値
 * @param {menu} メニュー
 * @param {updDefinition} 更新処理
 * @param {delDefinition} 削除処理
 * @param {copyDefinition} 複製処理
 * @returns {React.Element}
 */
const DefinitionInfoMenuLeft = ({
    id,
    menu,
    updDefinition,
    delDefinition,
    copyDefinition,
}) => {
    //　メニュー表示・非表示
    const onMenuClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        menu.setter(menu.idSet === id ? '' : id);
    };

    const displayNewDef = (e) => {
        e.preventDefault();
        updDefinition(id);
    };

    const deleteDef = (e) => {
        e.preventDefault();
        delDefinition(id);
    };

    const copyDef = (e) => {
        e.preventDefault();
        copyDefinition(id);
    };

    return (
        <>
            <span
                className="list__menu_btn js-list_menu_btn _menu"
                onClick={onMenuClick}
                style={
                    menu.idSet === id
                        ? {
                              boxShadow: '0 0 7px rgba(0, 0, 0, 0.17)',
                              backgroundColor: 'rgb(255, 255, 255)',
                          }
                        : {
                              boxShadow: 'none',
                              backgroundColor: 'rgb(255, 255, 255, 0)',
                          }
                }
            ></span>
            {menu.idSet === id ? (
                <div className="list__menu js-list_menu">
                    <ul>
                        <li>
                            <button
                                className="list__menu_icon list__menu_icon--edit js-modal_toggle"
                                onClick={displayNewDef}
                            >
                                定義名とメモを編集
                            </button>
                        </li>
                        <li>
                            <button
                                className="list__menu_icon list__menu_icon--copy"
                                onClick={copyDef}
                            >
                                定義を複製
                            </button>
                        </li>
                        <li>
                            <button
                                className="list__menu_icon list__menu_icon--delete js-modal_toggle"
                                onClick={deleteDef}
                            >
                                定義を削除
                            </button>
                        </li>
                    </ul>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default DefinitionInfoMenuLeft;
