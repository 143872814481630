import React from 'react';
import '../css/ModalError.css';

/**
 * エラーメッセージ表示モーダル画面
 * @param {close} 閉じる処理
 * @param {message} エラーメッセージ
 * @param {setter} モーダル内容設定
 * @returns
 */
const ModalError = ({ message, errorSetter }) => {
    const handleClose = (e) => {
        e.preventDefault();
        errorSetter(false);
    };

    // 改行コードを取得してメッセージを改行する処理
    const renderTextWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <>
            <p className="modal__text">{renderTextWithLineBreaks(message)}</p>
            <div className="modal__btn_area">
                <button
                    className="modal__btn modal__btn02 js-modal_close"
                    onClick={handleClose}
                >
                    OK
                </button>
            </div>
        </>
    );
};

export default ModalError;
