import { AppContext } from '../View/Home';
import { ModalContext } from '../App';
import { useContext } from 'react';
import { getFormattedDate } from '../common/utils';
import ModalFrame from './ModalFrame';

/**
 * マイページアカウント・決済情報タブ
 * @returns {React.Element}
 */
const AccountInfo = () => {
    const { data } = useContext(AppContext);
    const dataStatus = useContext(ModalContext);

    // 有効期限の月が1桁の場合、0をつける処理
    const addLeadingZero = (number) => {
        if (number < 10) {
            return '0' + number;
        } else {
            return number;
        }
    };

    // 有効期限の年下二桁表示
    const showLastTwoDigits = (number) => {
        if (number >= 1000 && number <= 9999) {
            return number % 100;
        } else {
            return '--';
        }
    };

    // モーダル開く処理
    const openModal = (e, target) => {
        e.preventDefault();
        dataStatus.setter({
            dataChanged: false,
            display: true,
            target: target,
        });
    };

    const openStripeReg = (e) => {
        e.preventDefault();
        dataStatus.setter({
            ...dataStatus.data,
            display: true,
            target: 'stripeConfirm',
            data: 'planupd',
        });
    };

    const getEndPaidDate = () => {
        try {
            const paidDate = new Date(data.userInfo.paidEdDtJst);
            const suppDate = new Date(data.userInfo.suppEdDtJst);

            if (paidDate > suppDate) {
                return `〜${getFormattedDate(
                    new Date(data.userInfo.paidEdDtJst),
                    'yyyy年MM月dd日'
                )}`;
            } else {
                return `〜${getFormattedDate(
                    new Date(data.userInfo.suppEdDtJst),
                    'yyyy年MM月dd日'
                )}`;
            }
        } catch (Err) {
            return '';
        }
    };

    return (
        <>
            <div className="mypage_tab__content" id="account_content">
                <div className="content_account">
                    <div className="account_block">
                        <div className="about_block__detail _accout">
                            <h3 className="detail_title">アカウント情報</h3>
                            <div className="detail_list">
                                <div className="detail_list__item">
                                    <dl>
                                        <dt>メールアドレス</dt>
                                        <dd>
                                            {data ? data.userInfo.mail : ''}
                                        </dd>
                                    </dl>
                                    <div className="account_edit__area">
                                        <button
                                            className="account_edit__icon js-modal_toggle"
                                            onClick={(e) =>
                                                openModal(e, 'updEmail')
                                            }
                                        >
                                            変更
                                        </button>
                                    </div>
                                </div>
                                <div className="detail_list__item">
                                    <dl>
                                        <dt>パスワード</dt>
                                        <dd>********</dd>
                                    </dl>
                                    <div className="account_edit__area">
                                        <button
                                            className="account_edit__icon js-modal_toggle"
                                            onClick={(e) =>
                                                openModal(e, 'updPassword')
                                            }
                                        >
                                            変更
                                        </button>
                                    </div>
                                </div>
                                <div className="detail_list__item">
                                    <dl>
                                        <dt>国または地域（会社の所在地）</dt>
                                        <dd>日本 (JP)</dd>
                                    </dl>
                                    <dl>
                                        <dt>氏名</dt>
                                        <dd>
                                            {data
                                                ? data.nameCompanyInfo.name
                                                : ''}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>氏名（ヨミ）</dt>
                                        <dd>
                                            {data
                                                ? data.nameCompanyInfo.nameKana
                                                : ''}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>会社名</dt>
                                        <dd>
                                            {data
                                                ? data.nameCompanyInfo
                                                      .companyName
                                                : ''}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>部署名</dt>
                                        <dd>
                                            {data
                                                ? data.nameCompanyInfo
                                                      .departmentName
                                                : ''}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>役職名</dt>
                                        <dd>
                                            {data
                                                ? data.nameCompanyInfo
                                                      .positionName
                                                : ''}
                                        </dd>
                                    </dl>
                                    <div className="account_edit__area">
                                        <button
                                            className="account_edit__icon js-modal_toggle"
                                            onClick={(e) =>
                                                openModal(e, 'userInfoReg')
                                            }
                                        >
                                            変更
                                        </button>
                                    </div>
                                </div>
                                <div className="detail_list__item">
                                    <dl>
                                        <dt>アカウントの削除</dt>
                                        <dd></dd>
                                    </dl>
                                    <div className="account_edit__area">
                                        <button
                                            className="no_icon js-modal_toggle"
                                            onClick={(e) =>
                                                openModal(e, 'deleteAcc')
                                            }
                                        >
                                            アカウントを削除する
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="acount_block__detail _settlement">
                            <h3 className="detail_title">決済情報</h3>
                            <div className="detail_list">
                                <div className="detail_list__item">
                                    <dl>
                                        <dt>カード番号</dt>
                                        <dd>
                                            {data && data.stripeInfo
                                                ? '**** **** **** ' +
                                                  data.stripeInfo.card4
                                                : ''}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>カード有効期限（月/年）</dt>
                                        <dd>
                                            {data && data.stripeInfo
                                                ? addLeadingZero(
                                                      data.stripeInfo
                                                          .expirationDtMonth
                                                  ) +
                                                  ' / ' +
                                                  showLastTwoDigits(
                                                      data.stripeInfo
                                                          .expirationDtYear
                                                  )
                                                : ''}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>セキュリティコード</dt>
                                        <dd>
                                            {data && data.stripeInfo
                                                ? '***'
                                                : ''}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>カード名義人</dt>
                                        <dd>
                                            {data && data.stripeInfo
                                                ? data.stripeInfo.cardHolder
                                                : ''}
                                        </dd>
                                    </dl>
                                    {data && data.stripeInfo && (
                                        <div className="account_edit__area">
                                            <button
                                                className="account_edit__icon js-modal_toggle"
                                                onClick={(e) =>
                                                    openModal(e, 'stripeReg')
                                                }
                                            >
                                                変更
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="detail_list__item _account">
                                    <dl className="billing_plan">
                                        <dt>購入プラン</dt>
                                        <dd className="_plan">
                                            {data &&
                                            data.userInfo &&
                                            (data.userInfo.userType ===
                                                'paid' ||
                                                data.userInfo.userType.startsWith(
                                                    'fpaid'
                                                ))
                                                ? data.userInfo.product
                                                : '無料トライアル'}
                                        </dd>
                                    </dl>
                                    {data ? (
                                        data.stripeInfo ? (
                                            <>
                                                <div className="account_edit__area _plan__edit">
                                                    <button
                                                        className="no_icon js-modal_toggle"
                                                        onClick={openStripeReg}
                                                    >
                                                        プランを変更する
                                                    </button>
                                                </div>
                                                <div className="account_edit__area _plan__delete">
                                                    <button
                                                        className="no_icon js-modal_toggle"
                                                        onClick={(e) =>
                                                            openModal(
                                                                e,
                                                                'cancelSub'
                                                            )
                                                        }
                                                    >
                                                        プランを解約する
                                                    </button>
                                                </div>
                                            </>
                                        ) : data.userInfo.userType ===
                                          'paid' ? (
                                            <div className="account_edit__area _plan__cancel">
                                                解約済み
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="detail_list__item _account">
                                    <dl>
                                        <dt>対象期間</dt>
                                        <dd>
                                            {/* 有償会員&プランを解約していない場合 */}
                                            {data
                                                ? data.stripeInfo
                                                    ? `${data.stripeInfo.currentPeriodStart}〜${data.stripeInfo.currentPeriodEnd}`
                                                    : // 有償会員&プランを解約した場合
                                                    data.userInfo.userType ===
                                                      'paid'
                                                    ? getEndPaidDate()
                                                    : data.userInfo.userType.startsWith(
                                                          'fpaid'
                                                      )
                                                    ? `〜${getFormattedDate(
                                                          new Date(
                                                              data.userInfo.fpaidEdDtJst
                                                          ),
                                                          'yyyy年MM月dd日'
                                                      )}`
                                                    : // 無料会員の場合
                                                      '-'
                                                : ''}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dataStatus.data.display && (
                <ModalFrame
                    modalId={dataStatus.data.target}
                    data={dataStatus.data.data}
                />
            )}
        </>
    );
};

export default AccountInfo;
