import React from 'react';
import '../css/ModalStripeConfirm.css';
/**
 * データ更新ある場合、未保存確認モーダル画面
 * @param {close} 閉じる処理
 * @param {target} 保存しない場合、次の画面・モダールに移動。
 * @param {setter} モーダル内容設定
 * @returns
 */
const ModalStripeConfirm = ({ close, setter, data }) => {
    // 決済更新処理
    const goToStripeReg = () => {
        setter({
            target: 'stripeReg',
            data: data? data : 'convert',
        });
    };

    return (
        <>
            <div className="modal__title">確認</div>
            <p className="modal__text">
                プランを変更すると、現プランの期間が残っていても本日付で新たに決済を行います。
            </p>
            <div className="modal__btn_area">
                <button className="modal__btn back" onClick={() => close()}>
                    戻る
                </button>
                <button className="modal__btn" onClick={goToStripeReg}>
                    {data === 'planupd' ? '変更に進む' : '決済に進む'}
                </button>
            </div>
        </>
    );
};

export default ModalStripeConfirm;
