import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import { changeJapaneseErrorMessage } from '../common/utils';
import { i18n } from '../lib/i18n';
import { Auth } from 'aws-amplify';
import { AxiosContext } from '../components/AxiosContext';
import { ModalContext } from '../App';
import ModalFrame from '../components/ModalFrame';
import logo from '../img/top/icon_header.svg';
import icon_feature_image01 from '../img/top/icon_feature_image01.svg';
import icon_feature_image02 from '../img/top/icon_feature_image02.svg';
import icon_feature_image03 from '../img/top/icon_feature_image03.svg';
import icon_feature_image04 from '../img/top/icon_feature_image04.svg';
import icon_feature_image05 from '../img/top/icon_feature_image05.svg';
import icon_feature_image06 from '../img/top/icon_feature_image06.svg';

import '../css/login.css';

function Login() {
    const navigate = useNavigate();
    const location = useLocation();

    const modalStatus = useContext(ModalContext);

    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const [isActive, setIsActive] = useState(false);

    const footerRef = useRef(null);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const instance = useContext(AxiosContext);
    const dict = { ...translations };
    // check here : https://github.com/aws-amplify/amplify-ui/blob/main/packages/ui/src/i18n/dictionaries/authenticator/defaultTexts.ts
    // I18n.putVocabularies(translations);

    // 日本語化
    I18n.putVocabulariesForLanguage('ja', { ...dict.ja, ...i18n.ja });
    I18n.setLanguage('ja');

    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                elem.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [location]);

    useEffect(() => {
        const script = document.createElement('script');
        script.text = "window.DocsBotAI=window.DocsBotAI||{},DocsBotAI.init=function(e){return new Promise((t,r)=>{var n=document.createElement(\"script\");n.type=\"text\/javascript\",n.async=!0,n.src=\"https:\/\/widget.docsbot.ai\/chat.js\";let o=document.getElementsByTagName(\"script\")[0];o.parentNode.insertBefore(n,o),n.addEventListener(\"load\",()=>{let n;Promise.all([new Promise((t,r)=>{window.DocsBotAI.mount(Object.assign({}, e)).then(t).catch(r)}),(n=function e(t){return new Promise(e=>{if(document.querySelector(t))return e(document.querySelector(t));let r=new MutationObserver(n=>{if(document.querySelector(t))return e(document.querySelector(t)),r.disconnect()});r.observe(document.body,{childList:!0,subtree:!0})})})(\"#docsbotai-root\"),]).then(()=>t()).catch(r)}),n.addEventListener(\"error\",e=>{r(e.message)})})};\r\nDocsBotAI.init({id: \"QctbbqOuGkbU7FXzH9nB\/Kv0witP2ocrsrwywL4lU\"});"
        document.head.appendChild(script);

        return() => {
            document.head.removeChild(script);
            if(document.querySelectorAll('[id=docsbotai-root]')){
                for(const ele of document.querySelectorAll('[id=docsbotai-root]')){
                    document.body.removeChild(ele);
                }
            }
        }
    }, []);

    const getUser = async (user) => {
        try {
            // ユーザー情報が登録されている場合
            if (user.attributes['custom:regInfo'] === '1') {
                const params = {
                    authType: 'LOGIN',
                };
                await instance.request({
                    data: params,
                    method: 'POST',
                    url: 'settingAuthenticationHistory',
                });
                navigate('/home');
                return;
                // ユーザー情報が登録されていない場合
            } else {
                // ユーザー情報登録モーダルを表示する
                modalStatus.setter({
                    dataChanged: false,
                    display: true,
                    target: 'userInfoReg',
                    data: 'login',
                });
                return;
            }
        } catch (err) {
            const message = changeJapaneseErrorMessage(
                err.response?.data.errInfo || err.message
            );
            setRemoteError({ status: true, message: message });
        } finally {
            setIsLoading(false);
        }
    };

    const customFreeSignUp = async () => {
        modalStatus.setter({
            dataChanged: false,
            display: true,
            target: 'freeSignUp',
            data: 'free',
        });
    };
    const customPaidSignUp = async () => {
        modalStatus.setter({
            dataChanged: false,
            display: true,
            target: 'paidSignUp',
            data: 'paid',
        });
    };
    const customSignIn = async () => {
        setIsLoading(true);
        await Auth.currentAuthenticatedUser({ bypassCache: true })
            .then((user) => {
                getUser(user);
            })
            // サインインしていなかったらサインインモーダルを表示する
            .catch((err) => {
                setIsLoading(false);
                modalStatus.setter({
                    dataChanged: false,
                    display: true,
                    target: 'signIn',
                    data: 'login',
                });
            });
    };

    const smoothClick = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const openConfirmModal = (e) => {
        e.preventDefault();
        modalStatus.setter({
            dataChanged: false,
            display: true,
            target: 'topSupport',
            data: {},
        });
    };

    return (
        <div className="top_page">
            {isLoading && <ModalFrame modalId="loading" />}
            {modalStatus.data.display && (
                <ModalFrame
                    modalId={modalStatus.data.target}
                    data={modalStatus.data.data}
                />
            )}
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
            <header className="top_header">
                <div className="header-wrap">
                    <h1 className="header-icon-wrap">
                        <img src={logo} alt="siwakee" />
                        <span>仕訳データ変換サービス</span>
                    </h1>
                    <div className="header-menu-wrap">
                        <ul className="header-menu">
                            <li className="menu-item _portal">
                                <a
                                    href="/portal/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    ポータル
                                </a>
                            </li>
                            <li className="menu-item _use">
                                <a
                                    href="/portal/howto"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    使い方
                                </a>
                            </li>
                            <li className="menu-item _inquiry">
                                <a href="" onClick={openConfirmModal}>
                                    サポート
                                </a>
                            </li>
                            <li className="menu-item _price">
                                <a
                                    href="/portal/entry/2023/07/20/145000"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    機能・料金
                                </a>
                            </li>
                            <li className="menu-item _login">
                                <a tabIndex="0" onClick={customSignIn}>
                                    ログイン
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="menu-wrap">
                    <a
                        className={`menu ${isActive ? 'active' : ''}`}
                        onClick={toggleMenu}
                    >
                        <span className="menu__line menu__line--top"></span>
                        <span className="menu__line menu__line--bottom"></span>
                    </a>
                </div>
                <div className={`sp-menu-wrap ${isActive ? 'open' : ''}`}>
                    <div className="header-icon-wrap">
                        <img src={logo} alt="siwakee" />
                    </div>
                    <div className="menu-content">
                        <ul className="sp-header-menu">
                            <li className="menu-item _portal">
                                <a
                                    href="/portal/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    ポータル
                                </a>
                            </li>
                            <li className="menu-item _use">
                                <a
                                    href="/portal/howto"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    使い方
                                </a>
                            </li>
                            <li className="menu-item _price">
                                <a
                                    href="/portal/entry/2023/07/20/145000"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    機能・料金
                                </a>
                            </li>
                        </ul>
                        <div className="caution-box">
                            <h2>
                                お使いのパソコンからユーザー登録して下さい。
                            </h2>
                            <p>
                                ※当サービスはパソコン向けのため、
                                <br />
                                スマートフォンからはご登録いただけません。
                            </p>
                        </div>
                        <ul className="sub-menu-list">
                            <li className="list-item">
                                <a
                                    href="/portal/entry/2023/07/20/162046"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={toggleMenu}
                                >
                                    利用規約
                                </a>
                            </li>
                            <li className="list-item">
                                <a
                                    href="/portal/entry/2023/07/20/162113"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={toggleMenu}
                                >
                                    プライバシーポリシー
                                </a>
                            </li>
                            <li className="list-item">
                                <a
                                    href="/portal/entry/2023/07/20/162441"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={toggleMenu}
                                >
                                    特定商取引法に基づく表記
                                </a>
                            </li>
                            <li className="list-item">
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleMenu();
                                        smoothClick();
                                    }}
                                >
                                    運営会社
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>

            <div>
                <section className="fv-section">
                    <div className="top_inner">
                        <div className="fv-headline">
                            <h2>
                                <span className="small">
                                    Excelと会計ソフトは使える。
                                </span>
                                <br />
                                次は
                                <span className="blue">
                                    siwakee
                                    <span className="small sp-small">
                                        （シワキー）
                                    </span>
                                </span>
                                だ。
                            </h2>
                            <p>
                                単一仕訳も複合仕訳も
                                <br />
                                会計ソフトに取り込むデータはsiwakeeで変換！
                            </p>
                            <a
                                className="trial-btn blue-btn"
                                onClick={customFreeSignUp}
                                tabIndex="0"
                            >
                                無料で試してみる
                            </a>
                        </div>
                    </div>
                </section>
                <section className="feature-section section">
                    <div className="top_inner">
                        <div className="feature-section-headline">
                            <h2>
                                会計ソフトに記帳する
                                <br className="sp-block" />
                                <span className="blue">
                                    日々の作業負担を
                                    <span className="border">大幅軽減</span>
                                </span>
                                ！
                            </h2>
                            <p>
                                siwakeeを使えば、会計ソフトに一括取込できる
                                <br className="sp-block" />
                                仕訳データを
                                <br className="pc-block" />
                                簡単に作ることができます。
                            </p>
                        </div>
                        <div className="feature-card-wrap">
                            <ul className="feature-cards">
                                <li className="card-item">
                                    <div className="card-image">
                                        <h3 className="blue">
                                            仕訳データに変換
                                        </h3>
                                        <img
                                            src={icon_feature_image01}
                                            alt="仕訳データに変換"
                                        />
                                    </div>
                                    <p>
                                        ExcelやCSVのデータを会計ソフトに一括取込できる仕訳データに変換します。
                                        <sup>※1</sup>
                                    </p>
                                </li>
                                <li className="card-item">
                                    <div className="card-image">
                                        <h3 className="blue">直感的に使える</h3>
                                        <img
                                            src={icon_feature_image03}
                                            alt="直感的に使える"
                                        />
                                    </div>
                                    <p>
                                        特許取得！仕訳を切るような直感的操作でデータ変換方法が定義できます。
                                        <sup>※2</sup>
                                    </p>
                                </li>
                                <li className="card-item">
                                    <div className="card-image">
                                        <h3 className="blue">複合仕訳もOK</h3>
                                        <img
                                            src={icon_feature_image02}
                                            alt="複合仕訳もOK"
                                        />
                                    </div>
                                    <p>
                                        単一仕訳データだけでなく複合仕訳データも作成できます。
                                        <sup>※1</sup>
                                    </p>
                                </li>
                                <li className="card-item">
                                    <div className="card-image">
                                        <h3 className="blue">保存できる</h3>
                                        <img
                                            src={icon_feature_image04}
                                            alt="保存できる"
                                        />
                                    </div>
                                    <p>
                                        一度作成したデータ変換方法は保存して繰り返し使えます。
                                        <sup>※3</sup>
                                    </p>
                                </li>
                                <li className="card-item">
                                    <div className="card-image">
                                        <h3 className="blue">セキュリティ</h3>
                                        <img
                                            src={icon_feature_image05}
                                            alt="セキュリティ"
                                        />
                                    </div>
                                    <p>
                                        特許取得！変換前のデータも変換後のデータもインターネットに送信されません。
                                        <sup>※2</sup>
                                    </p>
                                </li>
                                <li className="card-item">
                                    <div className="card-image">
                                        <h3 className="blue">らくらく導入</h3>
                                        <img
                                            src={icon_feature_image06}
                                            alt="らくらく導入"
                                        />
                                    </div>
                                    <p>
                                        システムの専門家がいなくても導入できます。
                                    </p>
                                </li>
                            </ul>
                            <a
                                href="/portal/entry/2023/07/20/161913"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="patent-link border"
                            >
                                <span className="en">siwakee</span>
                                の特許技術について
                            </a>
                        </div>
                        <div className="detail-wrap">
                            <ul className="detail-list">
                                <li className="detail-item">
                                    ※1
                                    勘定奉行や弥生会計の他、様々な会計ソフトに対応できます。無料トライアルでお試し下さい。
                                </li>
                                <li className="detail-item">
                                    ※2 特許第7217907号および特許第7226885号
                                </li>
                                <li className="detail-item">
                                    ※3 有料プランのみ
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="use-section section">
                    <div className="top_inner">
                        <h2>
                            使い方は<span className="blue border">簡単</span>！
                        </h2>
                        <div className="use-wrap">
                            <div className="main-use-wrap">
                                <iframe
                                    src="https://www.youtube-nocookie.com/embed/ChviX_DTXgk?rel=0&modestbranding=1&vq=highres&iv_load_policy=3"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            </div>

                            <div className="main-use-wrap">
                                <iframe
                                    src="https://www.youtube-nocookie.com/embed/PSHCBerOJu4?rel=0&modestbranding=1&vq=highres&iv_load_policy=3"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <a
                                href="/portal/howto"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="patent-link border"
                            >
                                <span className="en">siwakee</span>の使い方
                            </a>
                        </div>
                    </div>
                </section>
                <section className="plan-section section">
                    <div className="top_inner">
                        <h2>
                            お試しは<span className="blue border">無料</span>！
                        </h2>
                        <p className="description" id="price">
                            無料でお試し利用ができます（期間制限なし）
                        </p>
                        <div className="plan-wrap">
                            <div className="plan-card">
                                <div className="plan-price">
                                    <h3>無料トライアル</h3>
                                    <div className="free-price">
                                        <p className="bold">無料</p>
                                    </div>
                                </div>
                                <ul className="plan-detail">
                                    <li className="detail-item _ok">
                                        データ変換方法の
                                        <br className="sp-block" />
                                        定義
                                    </li>
                                    <li className="detail-item _middle">
                                        データ変換できる
                                        <br className="sp-block" />
                                        レコード数<br className="sp-block"></br>
                                        （制限あり）
                                    </li>
                                    <li className="detail-item _no">
                                        データ変換方法の
                                        <br className="sp-block" />
                                        保存・編集・複製
                                    </li>
                                    <li className="detail-item _no">
                                        メールサポートなし
                                    </li>
                                </ul>
                                <a
                                    className="blue-btn price-btn"
                                    onClick={customFreeSignUp}
                                    tabIndex="0"
                                >
                                    無料で試してみる
                                </a>
                            </div>
                            <div className="plan-card _paid">
                                <div className="plan-price">
                                    <h3>有料プラン</h3>
                                    <div className="price">
                                        <p className="detail-price-marginTop">
                                            <span className="bold">1,480</span>
                                            円／月
                                        </p>
                                        <p className="detail-price">
                                            （税込1,628円／月）
                                        </p>
                                        <p className="detail-price">
                                            メールサポートなし
                                        </p>
                                        <div className="detail-orLine">
                                            <div className="detail-orBorder"></div>
                                            <p className="detail-or">または</p>
                                            <div className="detail-orBorder"></div>
                                        </div>
                                        <p>
                                            <span className="bold">3,460</span>
                                            円／月
                                        </p>
                                        <p className="detail-price">
                                            （税込3,806円／月）
                                        </p>
                                        <p className="detail-price">
                                            メールサポートあり
                                        </p>
                                    </div>
                                </div>
                                <ul className="plan-detail">
                                    <li className="detail-item _ok">
                                        データ変換方法の
                                        <br className="sp-block" />
                                        定義
                                    </li>
                                    <li className="detail-item _ok">
                                        データ変換できる
                                        <br className="sp-block" />
                                        レコード数<br className="sp-block"></br>
                                        （制限なし）
                                    </li>
                                    <li className="detail-item _ok">
                                        データ変換方法の
                                        <br className="sp-block" />
                                        保存・編集・複製
                                    </li>
                                    <li className="detail-item _middle">
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            メールサポートあり<sup>※</sup>
                                        </span>
                                        <div className="detail-item _subText">
                                            ※月額3,460円
                                            <span
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                （税込3,806円）
                                            </span>
                                            <span
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                のプランのみ
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                                <a
                                    onClick={customPaidSignUp}
                                    className="yellow-btn price-btn"
                                    tabIndex="0"
                                >
                                    有料プランに登録する
                                </a>
                            </div>
                        </div>
                        <div className="caution-box">
                            <h2>
                                お使いのパソコンからユーザー登録して下さい。
                            </h2>
                            <p>
                                ※当サービスはパソコン向けのため、
                                <br />
                                スマートフォンからはご登録いただけません。
                            </p>
                        </div>
                    </div>
                </section>
            </div>
            <footer className="top_footer" ref={footerRef}>
                <div className="footer-inner" id="company">
                    <h2>運営会社</h2>
                    <div className="footer-detail">
                        <dl>
                            <dt>商号</dt>
                            <dd>Siwakee株式会社</dd>
                        </dl>
                        <dl>
                            <dt>本店所在地</dt>
                            <dd>
                                〒102-0083
                                <br />
                                東京都千代田区麹町一丁目４番地４ LIFULL HUB
                            </dd>
                        </dl>
                        <dl>
                            <dt>役員</dt>
                            <dd>代表取締役社長　青柳 伸宏</dd>
                        </dl>
                        <dl>
                            <dt>資本金</dt>
                            <dd>2,375万円</dd>
                        </dl>
                        <dl>
                            <dt>設立年月日</dt>
                            <dd>2021年6月14日</dd>
                        </dl>
                        <dl>
                            <dt>親会社</dt>
                            <dd>
                                <a
                                    href="https://www.greenwillow.co.jp/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    グリーンウィロー株式会社
                                </a>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="footer-menu">
                    <ul className="menu-list">
                        <li className="list-item">
                            <a
                                href="/portal/entry/2023/07/20/162046"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                利用規約
                            </a>
                        </li>
                        <li className="list-item">
                            <a
                                href="/portal/entry/2023/07/20/162113"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                プライバシーポリシー
                            </a>
                        </li>
                        <li className="list-item">
                            <a
                                href="/portal/entry/2023/07/20/162441"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                特定商取引法に基づく表記
                            </a>
                        </li>
                    </ul>
                    <p className="copyright">&copy; Siwakee Inc.</p>
                </div>
            </footer>
        </div>
    );
}

export default Login;
