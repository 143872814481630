import Tooltip from './Tooltip';

/**
 * 定義データテーブルのセル
 * @param {info} セルの行数・列数・値
 * @param {dropped} ドロップされたセルの場合：ソースの列数、その他：-1
 * @param {valChange}  セル更新処理
 * @param {updateBG}  クリックされたら、droppedの値を設定
 * @param {yellowBG}  True:黄色で表示する, False:標準表示
 *
 * @returns
 */
const ToolDataCell = ({
    info,
    dropped,
    last,
    valChange,
    updateBG,
    yellowBG,
}) => {
    //Dropされた処理
    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.items.length === 1) {
            const source_key = e.dataTransfer.getData('text');
            valChange(true, info.row, info.col, source_key);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // データ更新処理
    const changeValue = (e) => {
        valChange(dropped > -1, info.row, info.col, e.target.value);
    };

    //　データ削除処理
    const deleteVal = (e) => {
        e.stopPropagation();
        valChange(dropped > -1, info.row, info.col, '');
        updateBG(-1);
    };

    // Background色修正
    const updateBGColor = () => {
        updateBG(dropped);
    };

    return (
        <div
            className="cell"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={yellowBG ? { background: '#FEEECA' } : {}}
            onClick={updateBGColor}
        >
            {dropped > -1 ? (
                <div
                    className={
                        info.val.length > 12
                            ? 'sheet__drop js-help'
                            : 'sheet__drop'
                    }
                >
                    <button className="sheet__delete" onClick={deleteVal} />
                    <span className="sheet__drop_mark"></span>
                    {info.val.length > 12 ? (
                        <>
                            {info.val.substring(0, 12) + '...'}
                            <Tooltip last={last} type="data">
                                {info.val}
                            </Tooltip>
                        </>
                    ) : (
                        info.val
                    )}
                </div>
            ) : (
                <div>
                    <div
                        className={
                            info.val.length > 12
                                ? 'sheet__cell_text--direct js-help'
                                : 'sheet__cell_text--direct'
                        }
                    >
                        <input
                            className="sheet__textarea"
                            value={info.val}
                            onChange={changeValue}
                        ></input>
                        {info.val.length > 12 ? (
                            <Tooltip last={last} type="data">
                                {info.val}
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </div>
                    <button className="sheet__delete" onClick={deleteVal} />
                </div>
            )}
        </div>
    );
};

export default ToolDataCell;
