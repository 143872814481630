import { i18n } from '../lib/i18n';
import Encoding from 'encoding-japanese';
import { read, utils } from 'xlsx';
import { conf } from '..';

export function isEmptyString(value) {
    return value === undefined || value === null || value === '';
}

export function getShiftMonth(shiftNum) {
    const date = new Date();
    date.setMonth(date.getMonth() + shiftNum);
    return date;
}

export function getFormattedDate(date, format) {
    if (isEmptyString(date)) {
        return null;
    }
    let result = format.replace(/yyyy/g, date.getFullYear().toString());
    result = result.replace(/MM/g, ('00' + (date.getMonth() + 1)).slice(-2));
    result = result.replace(/dd/g, ('00' + date.getDate()).slice(-2));
    result = result.replace(/HH/g, ('00' + date.getHours()).slice(-2));
    result = result.replace(/mm/g, ('00' + date.getMinutes()).slice(-2));
    result = result.replace(/ss/g, ('00' + date.getSeconds()).slice(-2));
    return result;
}

export function csvStringToArray(strData, type) {
    const objPattern = new RegExp(
        '(\\,|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^\\,\\r\\n]*))',
        'gi'
    );
    let arrMatches = null,
        arrData = [[]];
    while ((arrMatches = objPattern.exec(strData))) {
        if (arrMatches[1].length && arrMatches[1] !== ',') {
            if (
                (type === 'init' && arrData.length === 10) ||
                (type === 'free' && arrData.length === 21)
            ) {
                break;
            } else {
                arrData.push([]);
            }
        }
        arrData[arrData.length - 1].push(
            arrMatches[2]
                ? arrMatches[2].replace(new RegExp('""', 'g'), '"')
                : arrMatches[3]
        );
    }

    //remove empty lines
    for (let i = arrData.length - 1; i > 0; i--) {
        if (arrData[i].length === 1 && arrData[i][0] === '') {
            arrData.splice(i, 1);
        } else if (arrData[i].length > 1) {
            break;
        }
    }

    return arrData;
}

export function convertNumToLetter(idx) {
    let letter = '';
    let count = idx;
    while (count >= 0) {
        letter = String.fromCharCode(65 + (count % 26)) + letter;
        count = Math.floor(count / 26) - 1;
    }
    return letter;
}

export function convertLetterToNum(str) {
    let res = 0;
    switch (str.length) {
        case 1:
            res = str.charCodeAt(0) - 65;
            break;
        case 2:
            res = 26 * (str.charCodeAt(0) - 64) + str.charCodeAt(1) - 65;
            break;
        case 3:
            res =
                676 * (str.charCodeAt(0) - 64) +
                26 * (str.charCodeAt(1) - 64) +
                str.charCodeAt(2) -
                65;
            break;
        default:
            break;
    }
    return res;
}

export function prepareDefInfo(data, importData) {
    const rowMemo = [];
    const rowHeader = [];

    let inputEncoding = -1;

    if (importData.encoding === 'SJIS') {
        inputEncoding = 0;
    } else if (importData.encoding === 'UTF-8') {
        inputEncoding = 1;
    } else {
        inputEncoding = 2;
    }

    data.header.forEach((row) => {
        rowMemo.push(row[0]);
        rowHeader.push(row[1]);
    });

    return {
        header: data.headerOutput,
        outStrCode: data.encoding,
        hdColumnsL: rowMemo,
        hdColumnsP: rowHeader,
        mapDtArr: data.dropped,
        mapNameDtArr: data.rows,
        sourceMaxColumns: importData.cols.length,
        inputStrCode: inputEncoding,
    };
}

export const initialDataValue = {
    name: '',
    title: '',
    memo: '',
    encoding: 0,
    headerOutput: 1,
    dlURL: '',
    header: new Array(6).fill().map(() => new Array(2).fill('')),
    rows: new Array(1).fill().map(() => new Array(6).fill('')),
    dropped: new Array(1).fill().map(() => new Array(6).fill(-1)),
};

export const initialImportValue = {
    name: '',
    encoding: 'SJIS',
    fromDef: 0,
    cols: [],
    binary: [],
};

export function computeConversion(pattern, importData) {
    let patternMask = new Array(pattern.rows.length)
        .fill()
        .map(() => new Array(pattern.rows[0].length).fill(''));
    const funcIdx = [];

    pattern.rows.forEach((r, rIdx) => {
        r.forEach((c, cIdx) => {
            if (pattern.dropped[rIdx][cIdx] > -1) {
                patternMask[rIdx][cIdx] = (idx) => {
                    return importData[pattern.dropped[rIdx][cIdx]][idx + 1];
                };
                funcIdx.push(rIdx + '_' + cIdx);
            } else {
                patternMask[rIdx][cIdx] = c;
            }
        });
    });

    let fullConversion = [];
    if (pattern.headerOutput === 1) {
        fullConversion = [pattern.header.map((x) => x[1])];
    }

    for (let i = 0; i < importData[0].length - 1; i++) {
        const arch = patternMask.map((arr) => {
            return arr.slice();
        });
        funcIdx.forEach((el) => {
            const indexes = el.split('_');
            arch[indexes[0]][indexes[1]] = arch[indexes[0]][indexes[1]](i);
        });
        fullConversion.push(...arch);
    }

    return fullConversion;
}

// オブジェクトが空かどうかをチェックする処理
export function isEmpty(obj) {
    return !Object.keys(obj).length;
}

// prettier-ignore
export const ALLOWED_SPECIAL_CHARACTERS = [
    '^', '$', '*', '.', '[', ']',
    '{', '}', '(', ')', '?', '"',
    '!', '@', '#', '%', '&', '/',
    '\\', ',', '>', '<', "'", ':',
    ';', '|', '_', '~', '`', '=',
    '+', '-', ' '
];

// エラーメッセージの日本語化
export const changeJapaneseErrorMessage = (message) => {
    return i18n.ja[message] || 'システムエラーが発生しました。';
};

export const extractFullData = (importData, userType) => {
    const extension = importData.name.split('.').pop();
    let fullData = [];
    if (extension === 'csv') {
        const csvText = new TextDecoder(importData.encoding).decode(
            importData.binary
        );
        const csvLines = csvStringToArray(csvText, userType);
        let [row] = csvLines;
        fullData = row.map((value, column) =>
            csvLines.map((row) => row[column])
        );
    } else {
        const workbook = read(importData.binary, { sheets: 0 });
        const rows = utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]],
            { header: 'A', blankrows: false, raw: false, defval: '' }
        );

        let prevRowNum = -1;
        rows.every((e, idx) => {
            if (userType === 'free' && idx > 20) return false;
            let currentRowNum = e.__rowNum__;
            const nbCol = Object.keys(e).length;
            const values = Object.values(e);
            if (currentRowNum > prevRowNum + 1) {
                if (userType === 'free' && currentRowNum > 20) {
                    currentRowNum = 21;
                }
                for (let i = 0; i < nbCol; i++) {
                    if (idx === 0) {
                        fullData = [
                            ...fullData,
                            Array(currentRowNum - prevRowNum - 1).fill(''),
                        ];
                    } else {
                        fullData[i] = [
                            ...fullData[i],
                            ...Array(currentRowNum - prevRowNum - 1).fill(''),
                        ];
                    }
                }
            }

            if (userType === 'free' && currentRowNum > 20) {
                return false;
            }

            for (let i = 0; i < nbCol; i++) {
                let val = values[i];
                if (val.charAt(0) === '"') {
                    val = val.substring(1, val.length);
                }
                if (val.charAt(val.length - 1) === '"') {
                    val = val.substring(0, val.length - 1);
                }
                if (fullData[i]) {
                    fullData[i].push(val);
                } else {
                    fullData.push([val]);
                }
            }
            prevRowNum = currentRowNum;
            return true;
        });
    }

    return fullData;
};

export const toShiftJIS = (utf8String) => {
    const unicodeArray = Encoding.stringToCode(
        utf8String.replaceAll('¥', '\\')
    ); // Convert string to code array
    const sjisArray = Encoding.convert(unicodeArray, {
        to: 'SJIS',
        from: 'UNICODE',
    });
    return new Uint8Array(sjisArray);
};

// パスワードのバリデーション
// Cognitoのパスワードポリシーに準拠しているかチェック
export const passwordValidation = (
    value,
    confirmPassword,
    isUpdPassword = false
) => {
    let passwordErrorMessage = [];
    let passwordConfirmErrorMessage = [];
    // パスワード更新モーダルかどうかでエラーテキスト表示切り替え
    const textMessage = isUpdPassword
        ? '新しいパスワードを入力してください。'
        : 'パスワードを入力してください。';
    if (value === '') {
        passwordErrorMessage.push(textMessage);
    } else {
        const order =
            conf.aws_cognito_password_protection_settings
                .passwordPolicyCharacters;
        if (order.includes('REQUIRES_UPPERCASE') && !/[A-Z]/.test(value)) {
            passwordErrorMessage.push(
                '半角英字の大文字を1文字以上含めてください。'
            );
        }
        if (order.includes('REQUIRES_LOWERCASE') && !/[a-z]/.test(value)) {
            passwordErrorMessage.push(
                '半角英字の小文字を1文字以上含めてください。'
            );
        }
        if (order.includes('REQUIRES_NUMBERS') && !/\d/.test(value)) {
            passwordErrorMessage.push('半角数字を1文字以上含めてください。');
        }
        if (
            order.includes('REQUIRES_SYMBOLS') &&
            !ALLOWED_SPECIAL_CHARACTERS.some((char) => value.includes(char))
        ) {
            passwordErrorMessage.push(
                '以下の特殊文字を1文字以上含めてください。\n^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -'
            );
        }

        if (
            value.length <
            conf.aws_cognito_password_protection_settings
                .passwordPolicyMinLength
        ) {
            passwordErrorMessage.push(
                `${conf.aws_cognito_password_protection_settings.passwordPolicyMinLength}文字以上にしてください。`
            );
        }
        if (confirmPassword && value !== confirmPassword) {
            passwordConfirmErrorMessage.push('パスワードが一致しません。');
        }
    }
    return {
        password: {
            error: passwordErrorMessage && passwordErrorMessage.length > 0,
            message: passwordErrorMessage,
        },
        confirmPassword: {
            error:
                passwordConfirmErrorMessage &&
                passwordConfirmErrorMessage.length > 0,
            message: passwordConfirmErrorMessage,
        },
    };
};

export function getUserType(userInfo) {
    const nowDate = Date.now();
    if (userInfo.userType === 'free') {
        const paidDate = new Date(userInfo.paidEdDtJst);
        const suppDate = new Date(userInfo.suppEdDtJst);
        if (paidDate > nowDate || suppDate > nowDate) {
            return 'paid';
        } else {
            return 'free';
        }
    } else if (userInfo.userType === 'paid') {
        return 'paid';
    } else if (userInfo.userType === 'freepaid') {
        const fpaidDate = new Date(userInfo.fpaidEdDtJst);
        if (nowDate > fpaidDate) {
            return 'free';
        } else {
            const diffDays = Math.round(
                (fpaidDate - nowDate) / (1000 * 60 * 60 * 24)
            );
            if (diffDays <= 30) {
                return 'fpaidLastMonth';
            } else {
                return 'fpaid';
            }
        }
    }
}
