import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { FrontErrorMessage } from './FrontErrorMessage';
import {
    changeJapaneseErrorMessage,
    passwordValidation,
} from '../common/utils';
import PasswordInput from './PasswordInput';
import ModalFrame from './ModalFrame';

import '../css/signUp.css';

/**
 * 新規登録モーダル
 * @param {close} 閉じる処理
 * @param {setter} モーダル内容設定
 * @returns {React.Element}
 */
const ModalSignUp = ({ close, setter }) => {
    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        terms: false,
        policy: false,
    });
    // フロントエラー
    const [frontError, setFrontError] = useState({
        email: { error: false, message: [] },
        password: { error: false, message: [] },
        confirmPassword: { error: false, message: [] },
        terms: { error: false, message: [] },
        policy: { error: false, message: [] },
    });
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    }); // バックエンド側のエラー
    const [isLoading, setIsLoading] = useState(false);

    // メールアドレスアドレスバリデーション
    const emailValidation = (value) => {
        const emailErrorMessage = [];

        if (value === '') {
            emailErrorMessage.push('メールアドレスを入力してください。');
        }
        setFrontError((prev) => ({
            ...prev,
            email: {
                error: emailErrorMessage && emailErrorMessage.length > 0,
                message: emailErrorMessage,
            },
        }));
    };

    // パスワードの確認バリデーション
    const confirmPasswordValidation = (value, password) => {
        let confirmPasswordErrorMessage = [];
        if (value === '') {
            confirmPasswordErrorMessage.push(
                'パスワードを再度入力してください。'
            );
        } else if (value !== password) {
            confirmPasswordErrorMessage.push('パスワードが一致しません。');
        }
        setFrontError((prev) => ({
            ...prev,
            confirmPassword: {
                error:
                    confirmPasswordErrorMessage &&
                    confirmPasswordErrorMessage.length > 0,
                message: confirmPasswordErrorMessage,
            },
        }));
    };

    // 利用規約・プライバシーポリシーバリデーション
    const termsPolicyValidation = (name, checked) => {
        let termsPolicyErrorMessage = [];
        if (!checked) {
            if (name === 'terms') {
                termsPolicyErrorMessage.push('利用規約に同意してください。');
            } else if (name === 'policy') {
                termsPolicyErrorMessage.push(
                    'プライバシーポリシーに同意してください。'
                );
            }
        }
        setFrontError((prev) => ({
            ...prev,
            [name]: {
                error: termsPolicyErrorMessage.length > 0,
                message: termsPolicyErrorMessage,
            },
        }));
    };

    // バリデーション処理
    const validation = (name, value, checked) => {
        if (name === 'email') {
            emailValidation(value);
        } else if (name === 'password') {
            const validationResults = passwordValidation(
                value,
                userInfo.confirmPassword
            );
            setFrontError((prev) => ({
                ...prev,
                password: {
                    error: validationResults.password.error,
                    message: validationResults.password.message,
                },
                confirmPassword: {
                    error: validationResults.confirmPassword.error,
                    message: validationResults.confirmPassword.message,
                },
            }));
        } else if (name === 'confirmPassword') {
            confirmPasswordValidation(value, userInfo.password);
        } else if (name === 'terms' || 'policy') {
            termsPolicyValidation(name, checked);
        }
    };

    // 入力時処理
    const changeHandler = (e) => {
        const { name, value, checked } = e.target;
        if (
            name === 'email' ||
            name === 'password' ||
            name === 'confirmPassword'
        ) {
            setUserInfo((prev) => ({
                ...prev,
                [name]: value,
            }));
        } else if (name === 'terms' || name === 'policy') {
            setUserInfo((prev) => ({
                ...prev,
                [name]: checked,
            }));
        }
        // バリデーションチェック
        validation(name, value, checked);
    };

    // 登録処理
    const register = async (e) => {
        e.preventDefault(); // フォーム送信を防止

        try {
            setIsLoading(true);
            if (!isValid()) {
                setIsLoading(false);
                return;
            } else {
                const result = await Auth.signUp({
                    username: userInfo.email,
                    password: userInfo.password,
                    autoSignIn: {
                        enabled: true,
                    },
                });
                setter((prev) => ({
                    ...prev,
                    target: 'confirmSignUp',
                    data: { state: 'register', email: userInfo.email },
                }));
            }
        } catch (err) {
            const message = changeJapaneseErrorMessage(err.message);
            setRemoteError({ status: true, message: message });
        } finally {
            setIsLoading(false);
        }
    };

    // 各項目でエラーがなかったら&全ての項目が入力されていたら活性化する
    const isValid = () => {
        return !Object.values(frontError).some((e) => e.error) &&
            userInfo.email &&
            userInfo.password &&
            userInfo.confirmPassword &&
            userInfo.terms &&
            userInfo.policy
            ? true
            : false;
    };

    return (
        <div>
            {isLoading && <ModalFrame modalId="loading" />}
            <div className="modal__title">アカウント登録</div>
            <div>
                <label htmlFor="" className="modal__content_input">
                    <p>メールアドレス</p>
                    <div>
                        <input
                            type="text"
                            name="email"
                            autoComplete="email"
                            placeholder="example@siwakee.com"
                            onChange={changeHandler}
                        />
                    </div>
                </label>
                {frontError.email.error && (
                    <FrontErrorMessage message={frontError.email.message} />
                )}
                <label htmlFor="" className="modal__content_input">
                    <p>パスワード</p>
                    <PasswordInput name="password" onChange={changeHandler} />
                </label>
                {frontError.password.error && (
                    <FrontErrorMessage message={frontError.password.message} />
                )}
                <label htmlFor="" className="modal__content_input">
                    <p>パスワードの確認</p>
                    <PasswordInput
                        name="confirmPassword"
                        onChange={changeHandler}
                    />
                </label>
                {frontError.confirmPassword.error && (
                    <FrontErrorMessage
                        message={frontError.confirmPassword.message}
                    />
                )}
                <label className="checkboxAgree terms" htmlFor="terms">
                    <input
                        className="checkboxAgree__input_wrap"
                        type="checkbox"
                        id="terms"
                        name="terms"
                        onChange={changeHandler}
                    />
                    <span className="checkboxAgree__input"></span>
                    <span className="checkboxAgree__text">
                        <a
                            className="link"
                            href="/portal/entry/2023/07/20/162046"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            利用規約
                        </a>
                        に同意する
                    </span>
                </label>
                {frontError.terms.error && (
                    <FrontErrorMessage message={frontError.terms.message} />
                )}
                <label className="checkboxAgree policy" htmlFor="policy">
                    <input
                        className="checkboxAgree__input_wrap"
                        type="checkbox"
                        id="policy"
                        name="policy"
                        onChange={changeHandler}
                    />
                    <span className="checkboxAgree__input"></span>
                    <span className="checkboxAgree__text">
                        <a
                            className="link"
                            href="/portal/entry/2023/07/20/162113"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            プライバシーポリシー
                        </a>
                        に同意する
                    </span>
                </label>
                {frontError.policy.error && (
                    <FrontErrorMessage message={frontError.policy.message} />
                )}
                <div className="modal__btn_area">
                    <button
                        className="modal__btn back"
                        onClick={() => {
                            close();
                        }}
                    >
                        閉じる
                    </button>
                    <button
                        className="modal__btn"
                        disabled={!isValid()}
                        onClick={register}
                    >
                        登録
                    </button>
                </div>
            </div>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </div>
    );
};

export default ModalSignUp;
