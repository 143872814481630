import { useEffect, useRef, useState } from 'react';

/**
 * ツールチップコンポーネント
 * @param {children} 子コンポーネント、DOM
 * @param {last} スタイル調整用フラグ
 * @param {type} ツールチップの種類(スタイル調整用フラグ)
 * @param {sourceInfo} セル情報
 * @returns {React.Element}
 */
const Tooltip = ({ children, last, type, sourceInfo }) => {
    const tooltipRef = useRef();

    // ツールチップtypeによってスタイル調整する処理
    const initTop = () => {
        switch (type) {
            case 'memo':
                return { top: '27px' };
            case 'data':
                return { top: '50px' };
            case 'source':
                return {
                    top: 22 * sourceInfo.row + 30 + 'px',
                };
            case 'defMemo':
                return {
                    top: 'calc(50% + 20px)',
                };
            default:
                break;
        }
    };
    const [style, setStyle] = useState(() => initTop());

    useEffect(() => {
        const tooltip = tooltipRef.current;
        const computeTop = () => {
            setStyle({ top: -tooltip.offsetHeight - 10 + 'px' });
        };
        if (tooltip && type === 'default') {
            tooltip.previousElementSibling.addEventListener(
                'mouseover',
                computeTop
            );
        }

        return () => {
            if (
                tooltip &&
                tooltip.previousElementSibling &&
                type === 'default'
            ) {
                tooltip.previousElementSibling.removeEventListener(
                    'mouseover',
                    computeTop
                );
            }
        };
    }, [tooltipRef, type]);

    return (
        <span
            className={
                last ? 'tooltip js-tooltip tooltip--left' : 'tooltip js-tooltip'
            }
            ref={tooltipRef}
            style={style}
        >
            <span
                className="tooltip__arrow"
                style={type === 'defMemo' ? { left: '50%' } : {}}
            ></span>
            {children}
        </span>
    );
};

export default Tooltip;
