import { useContext, useEffect, useState } from 'react';
import {
    computeConversion,
    extractFullData,
    toShiftJIS,
} from '../common/utils';
import { DataContext } from './Tool';
import { AppContext } from '../View/Home';

/**
 * データー変換実行開始モーダル画面
 * @param {close} モーダル閉じる処理
 * @returns
 */
const ModalConversion = ({ close }) => {
    const { convData, importArr } = useContext(DataContext);
    const { data } = useContext(AppContext);

    const [downloadURL, setDownloadURL] = useState('');
    const [hasBlankLine, setHasBlankLine] = useState(false);

    useEffect(() => {
        //データ変換実行
        const fullInputData = extractFullData(
            importArr.data,
            data.userInfo.userType
        );
        let fullCSVData = computeConversion(convData.data, fullInputData);

        if (fullInputData.length > 0) {
            const rowNum = fullInputData.length;
            const colNum = fullInputData[0].length;
            for (let col = 1; col < colNum; col++) {
                let found = false;
                for (let row = 0; row < rowNum; row++) {
                    if (
                        fullInputData[row][col] !== undefined &&
                        fullInputData[row][col] !== ''
                    ) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    setHasBlankLine(true);
                    break;
                }
            }
        }
        const lineArray = [];
        //CSVデータ準備
        fullCSVData.forEach(function (row) {
            const line = row.join(',');
            lineArray.push(line);
        });
        const csvContent = lineArray.join('\r\n');
        let blob;
        if (convData.data.encoding === 0) {
            blob = new Blob([toShiftJIS(csvContent)], {
                type: 'text/plain; charset=shift_jis',
            });
        } else {
            blob = new Blob([csvContent], { type: 'text/plain; charset=utf8' });
        }
        //ダウンロードURL準備
        setDownloadURL(URL.createObjectURL(blob));
    }, []);

    //モダール閉じる時にダウンロード開始
    const handleClose = (e) => {
        e.preventDefault();
        convData.setter({ ...convData.data, dlURL: downloadURL });
        close();
    };

    return (
        <>
            <p className="modal__text">
                変換が完了しました。変換したデータを保存します。
                <br />
                {hasBlankLine && '注：変換するファイルに空行がありました。'}
            </p>
            <div className="modal__btn_area">
                <button
                    className="modal__btn modal__btn02 js-modal_close"
                    onClick={handleClose}
                >
                    OK
                </button>
            </div>
        </>
    );
};

export default ModalConversion;
