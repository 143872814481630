import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { convertNumToLetter } from '../common/utils';
import { DataContext } from './Tool';
import { ModalContext } from '../App';
import ToolDataCell from './ToolDataCell';
import ToolTableHeaderTop from './ToolTableHeaderTop';
import ToolDataHeader from './ToolDataHeader';
import ToolTableHeaderLeft from './ToolTableHeaderLeft';
import { AppContext } from '../View/Home';

/**
 * 定義データ設定コンポネント
 * @param {updateBG} クリックされてたセールのソース列数設定機能。直接入力の場合は「-1」設定
 * @param {unsavedStatus} データ更新あったら未保存に設定する。
 * @returns
 */
const ToolData = ({ updateBG, sheetRef, sheetAreaRef, sheetWrapRef }) => {
    const dataStatus = useContext(ModalContext);
    const { data } = useContext(AppContext);

    // 表示されているメニューID保存
    const [menuDisplayedID, setMenuDisplayedID] = useState('');
    // 変換データ・ソースデータ・クリックされたソース列数読み込み
    const { convData, importArr, clicked } = useContext(DataContext);

    const sheetScrollRef = useRef();
    const fixedElementRef = useRef();
    const sheetInnerRef = useRef();

    const isWinFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1 &&
        navigator.userAgent.toLowerCase().indexOf('windows') > -1;

    const stableResizeEvent = useCallback(() => {
        const sheetScroll = sheetScrollRef.current;
        const sheetWrap = sheetWrapRef.current;
        const sheetWrapInner = sheetRef.current;
        const sheetInner = sheetInnerRef.current;

        if (sheetInner && sheetWrapInner) {
            sheetInner.style.width = sheetWrapInner.offsetWidth + 17 + 'px';
        }
        if (sheetScroll && sheetWrap) {
            sheetScroll.style.width = sheetWrap.offsetWidth + 'px';
        }
    }, [sheetRef, sheetWrapRef]);

    useEffect(() => {
        //メニュー以外なところをクリックするとメニューを閉じる
        document.body.addEventListener('click', hideMenu);
        window.addEventListener('resize', stableResizeEvent);

        const obs = new ResizeObserver(stableResizeEvent);
        if (sheetRef.current) obs.observe(sheetRef.current);

        return () => {
            document.body.removeEventListener('click', hideMenu);
            window.removeEventListener('resize', stableResizeEvent);
            obs.disconnect();
        };
    }, [stableResizeEvent, sheetRef]);

    // メニューを閉じる
    const hideMenu = () => {
        setMenuDisplayedID('');
    };

    // メモー・ヘッダー更新処理
    const updateMemo = (e, idx, type, value) => {
        if (e.nativeEvent && e.nativeEvent.inputType === 'insertFromDrop')
            return;
        const memoTmp = { ...convData.data };
        memoTmp.header[idx][type] = value;
        convData.setter(memoTmp);
        if (!dataStatus.data.dataChanged) {
            dataStatus.setter({
                ...dataStatus.data,
                dataChanged: data.userInfo.userType === 'free' ? false : true,
            });
        }
    };

    // データ更新処理
    const updateInput = (dropped, row, col, value) => {
        const tmpRows = { ...convData.data };
        if (dropped) {
            const droppedColTmp = tmpRows.dropped;
            if (value === '') {
                tmpRows.rows[row][col] = '';
                droppedColTmp[row][col] = -1;
            } else {
                tmpRows.rows[row][col] = importArr.data.cols[value][0];
                droppedColTmp[row][col] = Number(value);
            }
            convData.setter(droppedColTmp);
        } else {
            tmpRows.rows[row][col] = value;
        }
        convData.setter(tmpRows);

        if (!dataStatus.data.dataChanged) {
            dataStatus.setter({
                ...dataStatus.data,
                dataChanged: data.userInfo.userType === 'free' ? false : true,
            });
        }
    };

    // 行「追加・削除」処理
    const updateRow = (type, idx) => {
        const tmpRows = { ...convData.data };
        const tmpDroppedCol = tmpRows.dropped;
        switch (type) {
            case 'add':
                const dataRow = new Array(tmpRows.rows[0].length).fill('');
                tmpRows.rows.splice(idx, 0, dataRow);

                const droppedRow = new Array(tmpRows.rows[0].length).fill(-1);
                tmpDroppedCol.splice(idx, 0, droppedRow);
                break;
            case 'del':
                tmpRows.rows.splice(idx, 1);
                tmpDroppedCol.splice(idx, 1);
                break;
            default:
                break;
        }
        convData.setter(tmpRows);
        if (!dataStatus.data.dataChanged) {
            dataStatus.setter({
                ...dataStatus.data,
                dataChanged: data.userInfo.userType === 'free' ? false : true,
            });
        }
    };

    // 列「追加・削除」処理
    const updateCol = (type, idx) => {
        const tmpRows = { ...convData.data };
        const tmpDroppedCol = tmpRows.dropped;
        switch (type) {
            case 'add':
                for (let row in tmpRows.rows) {
                    tmpRows.rows[row].splice(idx, 0, '');
                    tmpDroppedCol[row].splice(idx, 0, -1);
                }
                const headerRow = new Array(2).fill('');
                tmpRows.header.splice(idx, 0, headerRow);
                break;
            case 'del':
                for (let row in tmpRows.rows) {
                    tmpRows.rows[row].splice(idx, 1);
                    tmpDroppedCol[row].splice(idx, 1);
                }
                tmpRows.header.splice(idx, 1);
                break;
            default:
                break;
        }
        convData.setter(tmpRows);
        if (!dataStatus.data.dataChanged) {
            dataStatus.setter({
                ...dataStatus.data,
                dataChanged: data.userInfo.userType === 'free' ? false : true,
            });
        }
    };

    const handleScrollEvent = (e) => {
        hideMenu();
        const sheetScroll = sheetScrollRef.current;
        const sheetWrap = sheetWrapRef.current;
        const fixedElement = fixedElementRef.current;
        if (e.target === sheetScroll) {
            sheetWrap.scrollLeft = sheetScroll.scrollLeft;
            fixedElement.style.top = `${sheetWrap.scrollTop}px`;
            fixedElement.style.left = `${sheetWrap.scrollLeft}px`;
        } else if (e.target === sheetWrap) {
            sheetScroll.scrollLeft = sheetWrap.scrollLeft;
            fixedElement.style.top = `${sheetWrap.scrollTop}px`;
            fixedElement.style.left = `${sheetWrap.scrollLeft}px`;
        }
    };

    return (
        <div className="sheet__wrap_cover" ref={sheetAreaRef}>
            <div
                className="sheet__scroll"
                onScroll={handleScrollEvent}
                ref={sheetScrollRef}
                style={{
                    width: sheetWrapRef.current
                        ? sheetWrapRef.current.offsetWidth + 'px'
                        : '0px',
                }}
            >
                <div
                    className={
                        isWinFirefox
                            ? 'sheet__inner windows-firefox-only'
                            : 'sheet__inner'
                    }
                    ref={sheetInnerRef}
                    style={{
                        width: sheetRef.current
                            ? sheetRef.current.offsetWidth + 17 + 'px'
                            : '0px',
                    }}
                ></div>
            </div>
            <div
                className="sheet__wrap"
                id="scrollbar-point"
                ref={sheetWrapRef}
                onScroll={handleScrollEvent}
            >
                <div
                    className="sheet__fixed_top"
                    style={{
                        width: 1 + convData.data.header.length * 200 + 'px',
                        gridTemplateColumns:
                            'repeat(' +
                            convData.data.header.length +
                            ', 200px)',
                    }}
                >
                    {convData.data.header.map((val, key) => {
                        return (
                            <div
                                className="sheet__row"
                                key={'toolColCount_' + key}
                            >
                                <ToolTableHeaderTop
                                    key={'header_' + key}
                                    id={convertNumToLetter(key)}
                                    menu={{
                                        idSet: menuDisplayedID,
                                        setter: setMenuDisplayedID,
                                    }}
                                    menuOpe={updateCol}
                                    parentRef={sheetAreaRef}
                                >
                                    {convertNumToLetter(key)}
                                </ToolTableHeaderTop>
                                {val.map((row, idx) => {
                                    return (
                                        <ToolDataHeader
                                            key={'header_' + key + '_' + idx}
                                            memo={idx === 0}
                                            col={key}
                                            val={row}
                                            valChange={updateMemo}
                                            last={
                                                key ===
                                                    convData.data.header
                                                        .length -
                                                        1 ||
                                                key ===
                                                    convData.data.header
                                                        .length -
                                                        2
                                            }
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                <div className="sheet__fixed_top_left" ref={fixedElementRef}>
                    <div className="sheet__row sheet__top_left_header">
                        <div className="sheet__top_header"></div>
                        <div className="sheet__memo_header_top">メモ</div>
                        <div className="sheet__memo_header_top sheet__memo_heder--line2">
                            ヘッダー
                        </div>
                    </div>
                </div>
                <div
                    className="sheet"
                    ref={sheetRef}
                    style={{
                        width: 65 + convData.data.rows[0].length * 200 + 'px',
                        height: 1 + 60 * convData.data.rows.length + 'px',
                        gridTemplateColumns:
                            '64px repeat(' +
                            convData.data.rows[0].length +
                            ', 200px)',
                        gridTemplateRows:
                            'repeat(' + convData.data.rows.length + ', 60px)',
                    }}
                >
                    {convData.data.rows.map((val, key) => {
                        return (
                            <div key={'row_' + key} className="sheet__row">
                                <ToolTableHeaderLeft
                                    key={'head_' + key}
                                    id={key}
                                    menu={{
                                        idSet: menuDisplayedID,
                                        setter: setMenuDisplayedID,
                                    }}
                                    menuOpe={updateRow}
                                    parentRef={sheetAreaRef}
                                >
                                    {key + 1}
                                </ToolTableHeaderLeft>
                                {val.map((col, idx) => {
                                    return (
                                        <ToolDataCell
                                            key={'cell_' + key + '_' + idx}
                                            info={{
                                                row: key,
                                                col: idx,
                                                val: col,
                                            }}
                                            dropped={
                                                convData.data.dropped[key][idx]
                                            }
                                            last={
                                                idx ===
                                                    convData.data.rows[0]
                                                        .length -
                                                        2 ||
                                                idx ===
                                                    convData.data.rows[0]
                                                        .length -
                                                        1
                                            }
                                            valChange={updateInput}
                                            updateBG={updateBG}
                                            yellowBG={
                                                clicked.data > -1 &&
                                                clicked.data ===
                                                    convData.data.dropped[key][
                                                        idx
                                                    ]
                                            }
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ToolData;
