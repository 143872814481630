import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { changeJapaneseErrorMessage } from '../common/utils';
import { FrontErrorMessage } from './FrontErrorMessage';
import { AxiosContext } from './AxiosContext';
import { AppContext } from '../View/Home';
import ModalFrame from './ModalFrame';
import '../css/confirmSignUp.css';

/**
 * 新規登録時検証コード入力モーダル
 * @param {close} モーダル閉じる処理
 * @param {setter} ステート更新用関数
 * @param {data} ユーザーデータ
 * @returns {React.Element}
 */
const ModalConfirmSignUp = ({ close, setter, data }) => {
    const { state, email } = data;

    const instance = useContext(AxiosContext);
    const userInfoContext = useContext(AppContext);
    const [userInfo, setUserInfo] = useState({ code: '' });
    const [frontError, setFrontError] = useState({
        code: { error: false, message: [] },
    });
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isShowWarning, setIsShowWarning] = useState(false);

    // バリデーション処理
    const validation = (value) => {
        let codeErrorMessage = [];
        if (value === '') {
            codeErrorMessage.push('検証コードを入力してください。');
        }
        setFrontError((prev) => ({
            ...prev,
            code: {
                error: codeErrorMessage && codeErrorMessage.length > 0,
                message: codeErrorMessage,
            },
        }));
    };

    // 入力時処理
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setUserInfo((params) => ({
            ...params,
            [name]: value,
        }));
        validation(value);
    };

    // 登録処理
    const register = async (e) => {
        e.preventDefault(); // フォーム送信を防止
        setIsLoading(true);
        if (!isValid()) {
            setIsLoading(false);
            return;
        }
        try {
            if (state === 'register') {
                const result = await Auth.confirmSignUp(email, userInfo.code);
                setter((prev) => ({ ...prev, target: 'userInfoReg' }));
            } else if (state === 'update') {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.verifyUserAttributeSubmit(
                    user,
                    'email',
                    userInfo.code
                );

                const params = {
                    mail: email,
                };
                await instance.request({
                    data: params,
                    method: 'POST',
                    url: 'updAccount',
                });

                userInfoContext.setter({
                    ...userInfoContext.data,
                    userInfo: { ...userInfoContext.data.userInfo, mail: email },
                });
                setter({
                    target: 'updateComplete',
                    data: {
                        title: 'メールアドレス変更完了',
                        message: 'メールアドレスの変更が完了しました。',
                    },
                });
            }
        } catch (err) {
            const message = changeJapaneseErrorMessage(
                err.response?.data.errInfo || err.message
            );
            setRemoteError({ status: true, message: message });
        } finally {
            setIsLoading(false);
        }
    };

    // コード再送信処理
    const resendSignUpCode = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (state === 'register') {
                const result = await Auth.resendSignUp(email);
            } else if (state === 'update') {
                const user = await Auth.currentAuthenticatedUser();
                const result = await Auth.updateUserAttributes(user, {
                    email: email,
                });
            }
        } catch (err) {
            const message = changeJapaneseErrorMessage(err.message);
            setRemoteError({ status: true, message: message });
        } finally {
            setIsLoading(false);
        }
    };

    // 戻る処理
    const handleReturn = (e) => {
        e.preventDefault();
        setter({ target: 'updEmail', data: '' });
    };

    // ボタン有効化切り替え
    const isValid = () => {
        return !Object.values(frontError).some((e) => e.error) && userInfo.code
            ? true
            : false;
    };

    return (
        <div>
            {isLoading && <ModalFrame modalId="loading" />}
            <div className="modal__title">検証コード入力</div>
            <div>
                <p className="modal_message">
                    送信先メールアドレスに届いた検証コードを入力してください。
                    <br />
                    送信先：{email}
                    <br />
                    検証コードが届くまでに1分ほどかかることがあります。
                </p>
                <label htmlFor="" className="modal__content_input">
                    <p>検証コード</p>
                    <div>
                        <input
                            type="text"
                            name="code"
                            autoComplete="code"
                            onChange={changeHandler}
                        />
                    </div>
                </label>
                {frontError.code.error && (
                    <FrontErrorMessage message={frontError.code.message} />
                )}
                <div className="modal__btn_area">
                    {state === 'register' ? (
                        <button
                            className="modal__btn back"
                            onClick={() => setIsShowWarning(true)}
                        >
                            登録をやめる
                        </button>
                    ) : (
                        <button
                            className="modal__btn back"
                            onClick={handleReturn}
                        >
                            戻る
                        </button>
                    )}
                    <button
                        className="modal__btn"
                        onClick={register}
                        disabled={!isValid()}
                    >
                        次へ
                    </button>
                </div>
                <div>
                    <button
                        className="modal__btn resend"
                        onClick={resendSignUpCode}
                    >
                        コードを再送信
                    </button>
                </div>
            </div>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
            {isShowWarning && (
                <ModalFrame
                    modalId="warning"
                    data={{
                        message:
                            '本当に登録をやめますか？\n同じメールアドレスで再登録する場合は10分ほど待つ必要があります。',
                        onClickOK: close,
                        onClickCancel: setIsShowWarning,
                    }}
                />
            )}
        </div>
    );
};

export default ModalConfirmSignUp;
