import { read, utils } from 'xlsx';
import { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { convertNumToLetter, csvStringToArray } from '../common/utils';
import { DataContext } from './Tool';
import { ModalContext } from '../App';
import { AppContext } from '../View/Home';
import Tooltip from './Tooltip';
import pullLogo from '../img/icon_pull.svg';
/**
 * 「変換するファイル」コンポネント
 * @param {updateBG} 列をクリックするときにBackground色更新
 * @returns
 */
const ToolSource = ({ updateBG, sheetAreaRef, remoteError }) => {
    const linkScroll1 = useRef();
    const linkScroll2 = useRef();
    const sourceAreaRef = useRef();
    const inputRef = useRef();
    const sourceWrapRef = useRef();
    const headerRef = useRef([]);

    const [dragger, setDragger] = useState({ flag: 0, point: 0 });
    const [scrollStyle, setScrollStyle] = useState({
        class: '',
        opacity: '0',
        width: '0px',
        innerW: '0px',
    });

    const isWinFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1 &&
        navigator.userAgent.toLowerCase().indexOf('windows') > -1;

    const { convData, importArr, clicked } = useContext(DataContext);
    const { data } = useContext(AppContext);
    const dataStatus = useContext(ModalContext);

    const stableScrollDisplay = useCallback(() => {
        const sourceArea = sourceAreaRef.current;
        const sourceWrap = sourceWrapRef.current;
        const scroll1 = linkScroll1.current;
        if (!sourceArea || !scroll1) {
            return;
        }

        if (sourceArea.offsetHeight < 360) {
            setScrollStyle({
                class: 'js-scroll_fixed',
                opacity: '1',
                width: scroll1.offsetWidth + 'px',
                innerW: sourceWrap ? sourceWrap.offsetWidth + 'px' : '0px',
            });
        } else {
            setScrollStyle({
                class: '',
                opacity: '1',
                width: '0px',
                innerW: '0px',
            });
        }
    }, []);

    const stableResizeEvent = useCallback(() => {
        const sourceArea = sourceAreaRef.current;
        const sheetArea = sheetAreaRef.current;
        const scroll1 = linkScroll1.current;
        const scroll2 = linkScroll2.current;
        const header = document.querySelector('.header');
        const toolMenu = document.querySelector('.tool_menu');
        const outputMenu = document.querySelector('.output_menu');
        const footer = document.querySelector('.footer');

        if (
            !sourceArea ||
            !sheetArea ||
            !header ||
            !toolMenu ||
            !outputMenu ||
            !footer
        )
            return;

        const topBottomHeight =
            header.offsetHeight +
            toolMenu.offsetHeight +
            outputMenu.offsetHeight +
            footer.offsetHeight +
            20;
        let sheetAreaVar = window.innerHeight - topBottomHeight + 2;
        sheetArea.style.height = sheetAreaVar - sourceArea.offsetHeight + 'px';
        if (scroll2 && scroll1) {
            scroll2.style.width = scroll1.offsetWidth + 'px';
            if (scroll2.children.length > 0 && scroll1.children.length > 1) {
                scroll2.children[0].style.width =
                    scroll1.children[0].offsetWidth + 33 + 'px';
            }
        }

        stableScrollDisplay();
    }, [sheetAreaRef, stableScrollDisplay]);

    useEffect(() => {
        // ページ下部ドラッグ＆ドロップ
        const sourceArea = sourceAreaRef.current;
        const sheetArea = sheetAreaRef.current;
        const sourceWrap = sourceWrapRef.current;
        const header = document.querySelector('.header');
        const toolMenu = document.querySelector('.tool_menu');
        const outputMenu = document.querySelector('.output_menu');
        const footer = document.querySelector('.footer');

        if (
            !sourceArea ||
            !sheetArea ||
            !header ||
            !toolMenu ||
            !outputMenu ||
            !footer
        )
            return;

        const topBottomHeight =
            header.offsetHeight +
            toolMenu.offsetHeight +
            outputMenu.offsetHeight +
            footer.offsetHeight +
            20;
        let sheetAreaVar = window.innerHeight - topBottomHeight + 2;
        sheetArea.style.height = sheetAreaVar - sourceArea.offsetHeight + 'px';
        sourceArea.style.height =
            Math.min(350, window.innerHeight - topBottomHeight - 241) + 'px';

        const obs = new ResizeObserver(stableResizeEvent);
        if (sourceWrap) obs.observe(sourceWrap);
        obs.observe(sourceArea);

        stableScrollDisplay();

        window.addEventListener('resize', stableResizeEvent);

        return () => {
            obs.disconnect();
            window.removeEventListener('resize', stableResizeEvent);
        };
    }, [stableResizeEvent, stableScrollDisplay, sheetAreaRef]);

    const startTrackingListener = (e) => {
        e.preventDefault();
        // 元のマウスポインタの場所格納
        setDragger({ flag: 1, point: e.clientY });
    };

    const followTrackingCallback = useCallback(
        (e) => {
            const sourceArea = sourceAreaRef.current;
            const sheetArea = sheetAreaRef.current;
            // ドラッグの速度・移動距離を制限

            if (dragger.flag === 1 && e.y > 20) {
                // 動かした距離 = 現マウスポインタの場所 - 元のマウスポインタの場所
                e.dragY = e.y - dragger.point;
                // 元のマウスポインタの場所更新
                setDragger({ ...dragger, point: e.y });
                let sourceHeight = sourceArea.offsetHeight;
                // let dragDistance = Math.abs(e.dragY);
                // if (dragDistance > 10) {
                //     e.dragY = Math.sign(e.dragY) * 10; // ドラッグの移動距離を最大10ピクセルに制限する
                // }

                let newSourceHeight = sourceHeight - e.dragY;
                if (newSourceHeight > 350) {
                    newSourceHeight = 350;
                } else if (newSourceHeight < 80) {
                    newSourceHeight = 80;
                }

                sheetArea.style.height =
                    sheetArea.offsetHeight +
                    (sourceHeight - newSourceHeight) +
                    'px';
                sourceArea.style.height = newSourceHeight + 'px';

                stableScrollDisplay();
            }

            let areaRect = sourceArea.getBoundingClientRect();
            let areaOffset = areaRect.top;
            if (areaOffset <= 20) {
                sourceArea.style.height = window.innerHeight - 60 + 'px';
            }
        },
        [dragger, sourceAreaRef, sheetAreaRef, stableScrollDisplay]
    );

    const stopTrackingCallback = useCallback(() => {
        setDragger({ ...dragger, flag: 0 });
    }, [dragger]);

    useEffect(() => {
        document.body.addEventListener('mousemove', followTrackingCallback);
        document.body.addEventListener('mouseup', stopTrackingCallback);
        document.body.addEventListener('mouseleave', stopTrackingCallback);
        return () => {
            document.body.removeEventListener(
                'mousemove',
                followTrackingCallback
            );
            document.body.removeEventListener('mouseup', stopTrackingCallback);
            document.body.removeEventListener(
                'mouseleave',
                stopTrackingCallback
            );
        };
    }, [followTrackingCallback, stopTrackingCallback]);

    useEffect(() => {
        stableScrollDisplay();

        if (importArr.data.name === '') {
            // ページ下部ドラッグ＆ドロップ
            const sourceArea = sourceAreaRef.current;
            const sheetArea = sheetAreaRef.current;
            const header = document.querySelector('.header');
            const toolMenu = document.querySelector('.tool_menu');
            const outputMenu = document.querySelector('.output_menu');
            const footer = document.querySelector('.footer');

            if (
                !sourceArea ||
                !sheetArea ||
                !header ||
                !toolMenu ||
                !outputMenu ||
                !footer
            )
                return;

            const topBottomHeight =
                header.offsetHeight +
                toolMenu.offsetHeight +
                outputMenu.offsetHeight +
                footer.offsetHeight +
                20;
            let sheetAreaVar = window.innerHeight - topBottomHeight + 2;
            sheetArea.style.height =
                sheetAreaVar - sourceArea.offsetHeight + 'px';
            sourceArea.style.height =
                Math.min(350, window.innerHeight - topBottomHeight - 241) +
                'px';
        }
    }, [stableScrollDisplay, importArr.data.name, sheetAreaRef]);

    const scrollSync = (e) => {
        const scroll1 = linkScroll1.current;
        const scroll2 = linkScroll2.current;
        if (e.target === scroll1) {
            scroll2.scrollLeft = e.target.scrollLeft;
        } else if (e.target === scroll2) {
            scroll1.scrollLeft = e.target.scrollLeft;
        }
    };

    //ファイル読み込み処理
    const importFile = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileReader = new FileReader();
        const fileName = file.name;
        const extension = fileName.split('.').pop();
        if (
            extension === 'xlsx' ||
            extension === 'xls' ||
            extension === 'xlsm'
        ) {
            // Excelファイルの場合
            fileReader.onload = async (e) => {
                const buffer = e.target.result;
                const workbook = read(buffer, { sheets: 0 });
                const rows = utils.sheet_to_json(
                    workbook.Sheets[workbook.SheetNames[0]],
                    { header: 'A', blankrows: false, raw: false, defval: '' }
                );

                let rowArrays = [];
                let prevRowNum = -1;
                rows.every((e, idx) => {
                    if (idx > 9) return false;
                    const currentRowNum = e.__rowNum__;
                    const nbCol = Object.keys(e).length;
                    const values = Object.values(e);
                    if (currentRowNum > prevRowNum + 1) {
                        for (let i = 0; i < nbCol; i++) {
                            if (idx === 0) {
                                rowArrays = [
                                    ...rowArrays,
                                    Array(
                                        Math.min(currentRowNum, 10) -
                                            prevRowNum -
                                            1
                                    ).fill(''),
                                ];
                            } else {
                                rowArrays[i] = [
                                    ...rowArrays[i],
                                    ...Array(
                                        Math.min(currentRowNum, 10) -
                                            prevRowNum -
                                            1
                                    ).fill(''),
                                ];
                            }
                        }
                    }
                    if (currentRowNum <= 9) {
                        for (let i = 0; i < nbCol; i++) {
                            let val = values[i];
                            if (val.charAt(0) === '"') {
                                val = val.substring(1, val.length);
                            }
                            if (val.charAt(val.length - 1) === '"') {
                                val = val.substring(0, val.length - 1);
                            }
                            if (rowArrays[i]) {
                                rowArrays[i].push(val);
                            } else {
                                rowArrays.push([val]);
                            }
                        }
                    }
                    prevRowNum = currentRowNum;
                    return true;
                });

                if (!dataStatus.data.dataChanged) {
                    if (importArr.data.cols.length !== rowArrays.length) {
                        dataStatus.setter({
                            ...dataStatus.data,
                            dataChanged:
                                data.userInfo.userType === 'free'
                                    ? false
                                    : true,
                        });
                    }
                }
                importArr.setter({
                    ...importArr.data,
                    name: fileName,
                    encoding: '',
                    cols: rowArrays,
                    binary: buffer,
                    fromDef: 0,
                });
            };
            fileReader.readAsArrayBuffer(file);
        } else if (extension === 'csv') {
            // CSVファイルの場合
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = async (e) => {
                let result = e.target.result;
                const encoding =
                    importArr.data.encoding === ''
                        ? 'SJIS'
                        : importArr.data.encoding;
                const csvText = new TextDecoder(encoding).decode(result);

                const csvLines = csvStringToArray(csvText, 'init');
                let [row] = csvLines;
                const transposedArray = row.map((value, column) =>
                    csvLines.map((row) => row[column])
                );

                if (!dataStatus.data.dataChanged) {
                    if (importArr.data.cols.length !== transposedArray.length) {
                        dataStatus.setter({
                            ...dataStatus.data,
                            dataChanged:
                                data.userInfo.userType === 'free'
                                    ? false
                                    : true,
                        });
                    }
                }

                importArr.setter({
                    ...importArr.data,
                    name: fileName,
                    encoding: encoding,
                    cols: transposedArray,
                    binary: result,
                    fromDef: 0,
                });
            };
        } else {
            remoteError({
                status: true,
                message:
                    'エクセルファイル（.xls/.xlsx/.xlsm）とCSVファイル（.csv）のみ\n読み込めます。',
            });
        }
    };

    //Drag開始処理。列数保存、ドラグイメージ設定
    const handleSourceDrag = (e, idx) => {
        e.dataTransfer.setData('text', idx);
        e.dataTransfer.setDragImage(headerRef[idx], 0, 0);
        if (clicked.data !== idx) {
            updateBG(idx);
        }
    };

    const handleEncodingChange = (target) => {
        if (importArr.data.name.split('.').pop() === 'csv') {
            const csvText = new TextDecoder(target).decode(
                importArr.data.binary
            );

            const csvLines = csvStringToArray(csvText, 'init');
            let [row] = csvLines;
            const transposedArray = row.map((value, column) =>
                csvLines.map((row) => row[column])
            );
            importArr.setter({
                ...importArr.data,
                cols: transposedArray,
                encoding: target,
            });
        } else {
            importArr.setter({ ...importArr.data, encoding: target });
        }
        if (!dataStatus.data.dataChanged) {
            dataStatus.setter({
                ...dataStatus.data,
                dataChanged: data.userInfo.userType === 'free' ? false : true,
            });
        }
    };

    return (
        <div
            className={importArr.data.fromDef ? 'source _source_b' : 'source'}
            ref={sourceAreaRef}
        >
            <span className="source_over-flow"></span>
            <div
                id="js-scroll_linkage02"
                className="scrollbar"
                onScroll={scrollSync}
                ref={linkScroll2}
                style={{
                    opacity: scrollStyle.opacity,
                    width: scrollStyle.width,
                }}
            >
                <div
                    className={
                        isWinFirefox ? 'inner windows-firefox-only' : 'inner'
                    }
                    style={{ width: scrollStyle.innerW }}
                ></div>
            </div>
            <span
                className="source__pull js-pull"
                onMouseDown={startTrackingListener}
            >
                <img src={pullLogo} alt="pull" />
            </span>
            <div className="source__top">
                <h2 className="source__title">
                    変換するファイル
                    {importArr.data.name.length > 48 ? (
                        <>
                            <span className="source__file_name js-help">
                                {importArr.data.name.substring(0, 48) + '...'}
                            </span>
                            <Tooltip type="default">
                                {importArr.data.name}
                            </Tooltip>
                        </>
                    ) : (
                        <span className="source__file_name">
                            {importArr.data.name}
                        </span>
                    )}
                </h2>
                <div className="file_menu">
                    <div className="file_menu__radio_wrap">
                        <div className="file_menu__radio_title">
                            文字コード
                            <span className="radio__icon js-help"></span>
                            <Tooltip>
                                CSVファイルを読み込む場合、読み込み時に使う文字コードが選択できます。文字化けが発生しない方を選んで下さい。Excelファイルを読み込む場合は自動で判別されます。文字化けが解決しない場合は「使い方」を参照して下さい。
                            </Tooltip>
                            &nbsp;&nbsp;：
                        </div>
                        <label
                            className={
                                importArr.data.encoding === ''
                                    ? 'radio tool_menu__no_events'
                                    : 'radio'
                            }
                        >
                            <input
                                className="radio__input_wrap"
                                type="radio"
                                name="radio_character_code"
                                disabled={importArr.data.encoding === ''}
                                checked={importArr.data.encoding === 'SJIS'}
                                onChange={() => handleEncodingChange('SJIS')}
                            />
                            <span className="radio__input"></span>
                            <span className="radio__text">Shift_JIS</span>
                        </label>
                        <label
                            className={
                                importArr.data.encoding === ''
                                    ? 'radio tool_menu__no_events'
                                    : 'radio'
                            }
                        >
                            <input
                                className="radio__input_wrap"
                                type="radio"
                                name="radio_character_code"
                                disabled={importArr.data.encoding === ''}
                                checked={importArr.data.encoding === 'UTF-8'}
                                onChange={() => handleEncodingChange('UTF-8')}
                            />
                            <span className="radio__input"></span>
                            <span className="radio__text">UTF-8</span>
                        </label>
                    </div>
                    <label
                        className="file_menu__icon_file"
                        htmlFor="browseFile"
                    >
                        <input
                            id="browseFile"
                            type="file"
                            accept=".xlsx, .csv, .xls, .xlsm"
                            onChange={(e) => importFile(e)}
                            onClick={() => {
                                inputRef.current.value = '';
                            }}
                            ref={inputRef}
                        ></input>
                        <span>ファイル読み込み</span>
                    </label>
                </div>
            </div>
            <div className="sheet__wrap_cover">
                {importArr.data.name === '' ? (
                    <>
                        <div
                            id="js-scroll_linkage01"
                            className={
                                importArr.data.fromDef
                                    ? 'sheet__wrap _sheet_b ' +
                                      scrollStyle.class
                                    : 'sheet__wrap ' + scrollStyle.class
                            }
                            onScroll={scrollSync}
                            ref={linkScroll1}
                        >
                            {importArr.data.fromDef &&
                            importArr.data.cols.length > 0 ? (
                                <table
                                    className="sheet-table sheet __table"
                                    ref={sourceWrapRef}
                                >
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span></span>
                                            </th>
                                        </tr>
                                        {importArr.data.cols.map((val, key) => {
                                            return (
                                                <tr
                                                    key={'importCol_' + key}
                                                    onClick={() =>
                                                        updateBG(key)
                                                    }
                                                >
                                                    <th
                                                        style={
                                                            key === clicked.data
                                                                ? {
                                                                      background:
                                                                          '#FEEECA',
                                                                  }
                                                                : {}
                                                        }
                                                        draggable={
                                                            importArr.data
                                                                .name !== ''
                                                        }
                                                        onDragStart={(e) => {
                                                            handleSourceDrag(
                                                                e,
                                                                key
                                                            );
                                                        }}
                                                        ref={(header) =>
                                                            (headerRef[key] =
                                                                header)
                                                        }
                                                    >
                                                        <span>
                                                            {convertNumToLetter(
                                                                key
                                                            )}
                                                        </span>
                                                        {convData.data.dropped.find(
                                                            (el) =>
                                                                el.includes(key)
                                                        ) ? (
                                                            <span className="sheet__drop_mark" />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div
                            className={'data_loading__txt'}
                            style={
                                importArr.data.fromDef
                                    ? { marginTop: '50px' }
                                    : { marginTop: '80px' }
                            }
                        >
                            <p>ファイルを読み込んでください。</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            id="js-scroll_linkage01"
                            className={'sheet__wrap ' + scrollStyle.class}
                            onScroll={scrollSync}
                            ref={linkScroll1}
                        >
                            <table
                                ref={sourceWrapRef}
                                className="sheet-table sheet __table"
                            >
                                <tbody>
                                    <tr>
                                        <th>
                                            <span></span>
                                        </th>
                                        <th>
                                            <span>1</span>
                                        </th>
                                        <th>
                                            <span>2</span>
                                        </th>
                                        <th>
                                            <span>3</span>
                                        </th>
                                        <th>
                                            <span>4</span>
                                        </th>
                                        <th>
                                            <span>5</span>
                                        </th>
                                        <th>
                                            <span>6</span>
                                        </th>
                                        <th>
                                            <span>7</span>
                                        </th>
                                        <th>
                                            <span>8</span>
                                        </th>
                                        <th>
                                            <span>9</span>
                                        </th>
                                        <th>
                                            <span>10</span>
                                        </th>
                                    </tr>
                                    {importArr.data.cols.map((val, key) => {
                                        return (
                                            <tr
                                                key={'importCol_' + key}
                                                onClick={() => updateBG(key)}
                                            >
                                                <th
                                                    key={'importHead_' + key}
                                                    style={
                                                        key === clicked.data
                                                            ? {
                                                                  background:
                                                                      '#FEEECA',
                                                              }
                                                            : {}
                                                    }
                                                    draggable={
                                                        importArr.data.name !==
                                                        ''
                                                    }
                                                    onDragStart={(e) => {
                                                        handleSourceDrag(
                                                            e,
                                                            key
                                                        );
                                                    }}
                                                    ref={(header) =>
                                                        (headerRef[key] =
                                                            header)
                                                    }
                                                >
                                                    <span>
                                                        {convertNumToLetter(
                                                            key
                                                        )}
                                                    </span>
                                                    {convData.data.dropped.find(
                                                        (el) => el.includes(key)
                                                    ) ? (
                                                        <span className="sheet__drop_mark" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </th>
                                                {Array(10)
                                                    .fill('')
                                                    .map((dummy, idx) => {
                                                        const row =
                                                            val[idx] || dummy;
                                                        return (
                                                            <td
                                                                key={
                                                                    'importDum_' +
                                                                    key +
                                                                    '_' +
                                                                    idx
                                                                }
                                                                style={
                                                                    key ===
                                                                    clicked.data
                                                                        ? {
                                                                              background:
                                                                                  '#FEEECA',
                                                                          }
                                                                        : {}
                                                                }
                                                                draggable={
                                                                    importArr
                                                                        .data
                                                                        .name !==
                                                                    ''
                                                                }
                                                                onDragStart={(
                                                                    e
                                                                ) => {
                                                                    handleSourceDrag(
                                                                        e,
                                                                        key
                                                                    );
                                                                }}
                                                                className={
                                                                    row.length >
                                                                    15
                                                                        ? 'js-help'
                                                                        : ''
                                                                }
                                                            >
                                                                {row.length >
                                                                15 ? (
                                                                    <>
                                                                        <span>
                                                                            {row.substring(
                                                                                0,
                                                                                15
                                                                            ) +
                                                                                '...'}
                                                                        </span>
                                                                        <Tooltip
                                                                            type="source"
                                                                            sourceInfo={{
                                                                                row:
                                                                                    idx +
                                                                                    1,
                                                                                col:
                                                                                    key +
                                                                                    1,
                                                                            }}
                                                                        >
                                                                            {
                                                                                row
                                                                            }
                                                                        </Tooltip>
                                                                    </>
                                                                ) : (
                                                                    <span>
                                                                        {row}
                                                                    </span>
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ToolSource;
