import { Auth } from 'aws-amplify';
import { useState } from 'react';
import {
    changeJapaneseErrorMessage,
    passwordValidation,
} from '../common/utils';
import { FrontErrorMessage } from './FrontErrorMessage';
import ModalFrame from './ModalFrame';
import PasswordInput from './PasswordInput';

/**
 * パスワード変更モーダル
 * @param {close} 閉じる処理
 * @param {setter}  モーダル内容設定
 * @returns {React.Element}
 */
const ModalUpdPassword = ({ close, setter }) => {
    const [loading, setLoading] = useState(false);
    const [passwordValue, setPasswordValue] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [frontError, setFrontError] = useState({
        currentPassword: { error: false, message: [] },
        newPassword: { error: false, message: [] },
        confirmNewPassword: { error: false, message: [] },
    });
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });

    const currentPasswordValidation = (value) => {
        let currentPasswordErrorMessage = [];
        // 未入力チェック
        if (value === '') {
            currentPasswordErrorMessage.push(
                '現在のパスワードを入力してください。'
            );
        }
        setFrontError({
            ...frontError,
            currentPassword: {
                error:
                    currentPasswordErrorMessage &&
                    currentPasswordErrorMessage.length > 0,
                message: currentPasswordErrorMessage,
            },
        });
    };

    // パスワードの確認のバリデーション
    const confirmPasswordValidation = (value, password) => {
        let confirmPasswordErrorMessage = [];
        // 未入力チェック
        if (value === '') {
            confirmPasswordErrorMessage.push(
                'パスワードを再度入力してください。'
            );
            // パスワードと一致確認
        } else if (value !== password) {
            confirmPasswordErrorMessage.push(
                '新しいパスワードと一致しません。'
            );
        }
        setFrontError({
            ...frontError,
            confirmNewPassword: {
                error:
                    confirmPasswordErrorMessage &&
                    confirmPasswordErrorMessage.length > 0,
                message: confirmPasswordErrorMessage,
            },
        });
    };

    // バリデーション処理
    const validation = (name, value) => {
        if (name === 'currentPassword') {
            currentPasswordValidation(value);
        } else if (name === 'newPassword') {
            const validationResults = passwordValidation(
                value,
                passwordValue.confirmNewPassword,
                true
            );
            setFrontError((prev) => ({
                ...prev,
                newPassword: {
                    error: validationResults.password.error,
                    message: validationResults.password.message,
                },
                confirmNewPassword: {
                    error: validationResults.confirmPassword.error,
                    message: validationResults.confirmPassword.message,
                },
            }));
        } else if (name === 'confirmNewPassword') {
            confirmPasswordValidation(value, passwordValue.newPassword);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setPasswordValue((params) => ({
            ...params,
            [name]: value,
        }));
        // バリデーションチェック
        validation(name, value);
    };

    // パスワード変更処理
    const updatePassword = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            // ユーザーの現在の認証情報を取得
            const currentUser = await Auth.currentAuthenticatedUser();

            // 新しいパスワードを指定して、ユーザーのパスワードを変更
            const result = await Auth.changePassword(
                currentUser,
                passwordValue.currentPassword,
                passwordValue.newPassword
            );
            setter({
                target: 'updateComplete',
                data: {
                    title: 'パスワード変更完了',
                    message: 'パスワードの変更が完了しました。',
                },
            });
        } catch (err) {
            let message = '';

            // サインイン画面と同じエラーが返ってきているため、文言の表示を切り分ける
            if (err.message === 'Incorrect username or password.') {
                message = '現在のパスワードが正しくありません。';
            } else {
                message = changeJapaneseErrorMessage(err.message);
                message =
                    message === 'システムエラーが発生しました。'
                        ? 'パスワードの変更ができませんでした。\nしばらくたってから再度お試しください。'
                        : message;
            }
            setRemoteError({ status: true, message: message });
        } finally {
            setLoading(false);
        }
    };

    const isValid = () => {
        return !Object.values(frontError).some((e) => e.error) &&
            passwordValue.currentPassword &&
            passwordValue.newPassword &&
            passwordValue.confirmNewPassword
            ? true
            : false;
    };

    return (
        <>
            {loading && <ModalFrame modalId="loading" />}
            <div className="modal__title">パスワードの変更</div>
            <dl className="modal__save_box">
                <dt className="modal__save_title">現在のパスワード</dt>
                <dd>
                    <PasswordInput
                        name="currentPassword"
                        onChange={handleChange}
                    />
                </dd>
                {frontError.currentPassword.error && (
                    <dd style={{ marginTop: '10px' }}>
                        <FrontErrorMessage
                            message={frontError.currentPassword.message}
                        />
                    </dd>
                )}
                <dt className="modal__save_title">新しいパスワード</dt>
                <dd>
                    <PasswordInput name="newPassword" onChange={handleChange} />
                </dd>
                {frontError.newPassword.error && (
                    <dd style={{ marginTop: '10px' }}>
                        <FrontErrorMessage
                            message={frontError.newPassword.message}
                        />
                    </dd>
                )}
                <dt className="modal__save_title">新しいパスワードの確認</dt>
                <dd>
                    <PasswordInput
                        name="confirmNewPassword"
                        onChange={handleChange}
                    />
                </dd>
                {frontError.confirmNewPassword.error && (
                    <dd style={{ marginTop: '10px' }}>
                        <FrontErrorMessage
                            message={frontError.confirmNewPassword.message}
                        />
                    </dd>
                )}
            </dl>
            <div className="modal__btn_area">
                <button
                    className="modal__btn modal__btn03 js-modal_close"
                    onClick={() => close()}
                >
                    戻る
                </button>
                <button
                    className="modal__btn modal__btn02"
                    onClick={updatePassword}
                    disabled={!isValid()}
                >
                    変更する
                </button>
            </div>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </>
    );
};

export default ModalUpdPassword;
