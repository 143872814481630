import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosContext } from './AxiosContext';

/**
 * 有料プラン登録完了モーダル
 * @param {close} 閉じる処理
 * @param {data} データ
 * @returns {React.Element}
 */
const ModalStripeComplete = ({ data, close }) => {
    const { isSuccess, message } = data;
    const instance = useContext(AxiosContext);
    const navigate = useNavigate();

    // データ変換をはじめる押下時の処理
    const handleClose = async () => {
        try {
            const sendParams = {
                authType: 'LOGIN',
            };
            await instance.request({
                data: sendParams,
                method: 'POST',
                url: 'settingAuthenticationHistory',
            });
        } catch (err) {
        } finally {
            close();
            navigate('/home');
        }
    };

    // 改行コードを取得してメッセージを改行する処理
    const renderTextWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <>
            {isSuccess ? (
                // 決済成功時
                <>
                    <div className="modal__title">有料プラン登録完了</div>
                    <p className="modal__text">
                        決済が成功し、有料プランの登録が完了しました。
                    </p>
                    <div>
                        <div className="modal__btn_area">
                            <button
                                className="modal__btn"
                                onClick={handleClose}
                            >
                                データ変換をはじめる
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                // 決済失敗時
                <>
                    <div className="modal__title">決済結果</div>
                    <p className="modal__text">
                        {message
                            ? renderTextWithLineBreaks(message)
                            : '決済が失敗しました。'}
                    </p>
                    <div>
                        <div className="modal__btn_area">
                            <button
                                className="modal__btn"
                                onClick={handleClose}
                            >
                                データ変換をはじめる
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ModalStripeComplete;
