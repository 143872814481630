/**
 * マイページ保存済定義一覧タブの昇順降順メニュー
 * @param {id} 一意な値
 * @param {menu} メニュー
 * @param {sortAscFunction} 更新処理
 * @param {sortDescFunction} 削除処理
 * @param {sortArrow} ソートオブジェクト
 * @returns {React.Element}
 */
const DefinitionInfoMenuTop = ({
    id,
    menu,
    sortAscFunction,
    sortDescFunction,
    sortArrow,
}) => {
    //　メニュー表示・非表示
    const onMenuClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        menu.setter(menu.idSet === id ? '' : id);
    };
    return (
        <>
            <span
                className={`list__menu_btn js-list_menu_btn _sort${
                    sortArrow.id === id ? sortArrow.sort : ''
                }`}
                onClick={onMenuClick}
                style={
                    menu.idSet === id
                        ? { boxShadow: '0 0 7px rgba(0, 0, 0, 0.17)' }
                        : { boxShadow: 'none' }
                }
            ></span>
            {menu.idSet === id ? (
                <div className="list__menu js-list_menu">
                    <ul>
                        <li>
                            <button
                                className="list__menu_icon list__menu_icon--up"
                                onClick={() => sortAscFunction(id)}
                            >
                                昇順で並び替え
                            </button>
                        </li>
                        <li>
                            <button
                                className="list__menu_icon list__menu_icon--down"
                                onClick={() => sortDescFunction(id)}
                            >
                                降順で並び替え
                            </button>
                        </li>
                    </ul>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default DefinitionInfoMenuTop;
