import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../css/ModalStripeComplete.css';
import { useLocation } from 'react-router-dom';

/**
 * 変更完了モーダル
 * @param {data} ユーザーデータ
 * @param {close} 閉じる処理
 * @returns {React.Element}
 */
const ModalUpdateComplete = ({ data, close }) => {
    const route = useLocation();
    const { title, message, shouldReload } = data;
    const navigate = useNavigate();

    // アカウント削除完了処理
    const onClick = () => {
        close();
        if (title === 'アカウント削除完了') {
            navigate('/');
        } else if (message === 'サポート依頼が送信されました。') {
            navigate('/home');
        } else if (
            route.pathname === '/home/mypage/definition' &&
            shouldReload
        ) {
            window.location.reload();
        }
    };

    // 改行コードを取得してメッセージを改行する処理
    const renderTextWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <>
            <div className="modal__title">{title}</div>
            <p className="modal__text">{renderTextWithLineBreaks(message)}</p>
            <div>
                <div className="modal__btn_area">
                    <button className="modal__btn" onClick={onClick}>
                        OK
                    </button>
                </div>
            </div>
        </>
    );
};

export default ModalUpdateComplete;
