import { useContext, useEffect, useState } from 'react';
import { changeJapaneseErrorMessage, prepareDefInfo } from '../common/utils';
import { AxiosContext } from './AxiosContext';
import { DataContext } from './Tool';
import { FrontErrorMessage } from './FrontErrorMessage';
import ModalFrame from './ModalFrame';

/**
 * 定義ファイル保存モーダル画面
 * @param {close} モーダル閉じる処理
 * @returns
 */
const ModalSaveDef = ({ close, from, initVal, setter }) => {
    const instance = useContext(AxiosContext);
    const toolData = useContext(DataContext);
    const [defInfo, setDefInfo] = useState({ title: '', memo: '' });
    const [hasError, setHasError] = useState({ error: false, message: '' });
    const [frontError, setFrontError] = useState({
        defTitle: { error: false, message: [] },
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (from === 'myPage') {
            setDefInfo({
                title: initVal.definitionTitle,
                memo: initVal.definitionMemo,
            });
        }
    }, [from, initVal]);

    // 定義名更新処理
    const updateDefTitle = (e) => {
        let defTitleValidationErrorMessage = [];
        setDefInfo({ ...defInfo, title: e.target.value });
        if (e.target.value === '') {
            defTitleValidationErrorMessage.push('仕訳定義名は必須項目です。');
        }

        setFrontError((prev) => ({
            ...prev,
            defTitle: {
                error:
                    defTitleValidationErrorMessage &&
                    defTitleValidationErrorMessage.length > 0,
                message: defTitleValidationErrorMessage,
            },
        }));
    };

    // メモ更新処理
    const updateDefMemo = (e) => {
        setDefInfo({ ...defInfo, memo: e.target.value });
    };

    // 保存処理
    const saveDef = async (e) => {
        e.preventDefault();
        if (defInfo.title === '') {
            const defTitleValidationErrorMessage = [
                '仕訳定義名は必須項目です。',
            ];
            setFrontError((prev) => ({
                ...prev,
                defTitle: {
                    error:
                        defTitleValidationErrorMessage &&
                        defTitleValidationErrorMessage.length > 0,
                    message: defTitleValidationErrorMessage,
                },
            }));
            return;
        }
        setLoading(true);
        try {
            const params = {
                definitionFileName: '',
                definitionTitle: defInfo.title,
                definitionMemo: defInfo.memo,
                fileInfo: '',
            };

            if (from === 'tool') {
                params.fileInfo = JSON.stringify(
                    prepareDefInfo(
                        toolData.convData.data,
                        toolData.importArr.data
                    )
                );
            } else if (from === 'myPage') {
                params.definitionFileName = initVal.definitionFileName;
            }

            const response = await instance.request({
                data: params,
                method: 'POST',
                url: 'settingDefinition',
            });
            if (
                from === 'tool' &&
                response.data.contentsInfo &&
                response.data.contentsInfo.definitionFileName
            ) {
                toolData.convData.setter({
                    ...toolData.convData.data,
                    name: response.data.contentsInfo.definitionFileName,
                    title: defInfo.title,
                    memo: defInfo.memo,
                });
            } else if (from === 'myPage') {
                initVal.definitionTitle = defInfo.title;
                initVal.definitionMemo = defInfo.memo;
                initVal.updDateJst =
                    response.data.contentsInfo.definitionUpdDate;
                initVal.updUser = response.data.contentsInfo.definitionUpdUser;
            }
            setter({
                target: 'updateComplete',
                data: {
                    title: '定義保存完了',
                    message: '定義が保存されました。',
                },
            });
        } catch (err) {
            let message = changeJapaneseErrorMessage(
                err.response?.data.errInfo
            );
            message =
                message === 'システムエラーが発生しました。'
                    ? '定義が保存できませんでした。\nしばらくたってから再度お試しください。'
                    : message;
            setHasError({
                status: true,
                message: message,
            });
        } finally {
            setLoading(false);
        }
    };

    // 閉じる処理
    const handleClose = (e) => {
        e.preventDefault();
        close();
    };

    return (
        <>
            {loading && <ModalFrame modalId="loading" />}
            <div className="modal__title">名前を付けて保存</div>
            <dl className="modal__save_box">
                <dt className="modal__save_title">仕訳定義名　※必須</dt>
                <dd>
                    <input
                        className="modal__save_textbox"
                        type="text"
                        name="name"
                        value={defInfo.title}
                        onChange={updateDefTitle}
                    />
                </dd>
                {frontError.defTitle.error && (
                    <dd>
                        <FrontErrorMessage
                            message={frontError.defTitle.message}
                        />
                    </dd>
                )}
                <dt className="modal__save_title">メモ</dt>
                <dd>
                    <textarea
                        className="modal__save_textarea"
                        name="name_memo"
                        value={defInfo.memo}
                        onChange={updateDefMemo}
                    />
                </dd>
            </dl>
            <div className="modal__btn_area">
                <button
                    className="modal__btn modal__btn03 js-modal_close"
                    onClick={handleClose}
                >
                    戻る
                </button>
                <button
                    className="modal__btn modal__btn02"
                    disabled={defInfo.title === '' || frontError.defTitle.error}
                    onClick={saveDef}
                >
                    保存する
                </button>
            </div>

            {hasError.status && (
                <ModalFrame
                    modalId="error"
                    data={hasError.message}
                    errorHandling={setHasError}
                />
            )}
        </>
    );
};

export default ModalSaveDef;
