import React, { useContext, useEffect } from 'react';
import { ModalContext } from '../App';
import { AppContext } from '../View/Home';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import AccountInfo from './AccountInfo';
import DefinitionInfo from './DefinitionInfo';

import '../css/myPage.css';

/**
 * マイページ
 * @returns {React.Element}
 */
function MyPage() {
    const { data } = useContext(AppContext);
    const dataStatus = useContext(ModalContext);
    const from = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        dataStatus.setter({
            display: false,
            dataChanged: false,
            target: '',
            data: undefined,
        });
    }, []);

    // タブ切り替え処理
    const changeContent = (type) => {
        navigate('/home/mypage' + type);
    };

    return (
        <main className="mypage main">
            <div className="mypage_title">
                <h2 className="mypage_title__headline">マイページ</h2>
            </div>

            <div className="mypage_tab">
                <input
                    id="account"
                    type="radio"
                    name="mypage_tab__item"
                    checked={from.pathname === '/home/mypage'}
                    onChange={() => {
                        changeContent('');
                    }}
                />
                <label className="mypage_tab__item" htmlFor="account">
                    アカウント・決済情報
                </label>
                {data && data.userInfo.userType === 'free' ? (
                    <label className="mypage_tab__item tool_menu__no_events">
                        保存済み定義一覧
                    </label>
                ) : (
                    <>
                        <input
                            id="list"
                            type="radio"
                            name="mypage_tab__item"
                            checked={
                                from.pathname === '/home/mypage/definition'
                            }
                            onChange={() => {
                                changeContent('/definition');
                            }}
                        />
                        <label className="mypage_tab__item" htmlFor="list">
                            保存済み定義一覧
                        </label>
                    </>
                )}
                <Routes>
                    <Route exact index element={<AccountInfo />} />
                    <Route
                        exact
                        path="/definition"
                        element={
                            data ? (
                                data.userInfo.userType === 'free' ? (
                                    <Navigate to="/home/mypage" replace />
                                ) : (
                                    <DefinitionInfo />
                                )
                            ) : (
                                <></>
                            )
                        }
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/home/mypage" replace />}
                    />
                    {/* page-not-found route*/}
                </Routes>
            </div>
        </main>
    );
}

export default MyPage;
