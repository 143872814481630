import { useContext, useState } from 'react';
import { AxiosContext } from './AxiosContext';
import { AppContext } from '../View/Home';
import ModalFrame from './ModalFrame';
import { changeJapaneseErrorMessage } from '../common/utils';

/**
 * 有料プラン解約モーダル
 * @param {close} モーダル閉じる処理
 * @param {setContent} ステート更新用関数
 * @returns {React.Element}
 */
const ModalCancelSub = ({ close, setContent }) => {
    const instance = useContext(AxiosContext);
    const { data, setter } = useContext(AppContext);

    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });

    const handleClose = (e) => {
        e.preventDefault();
        close();
    };

    // 解約処理
    const cancelSub = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            // マイページからのパターン
            const response = await instance.request({
                data: null,
                method: 'POST',
                url: 'paidCancellation',
            });
            const newInfo = { ...data };
            if (
                response.data.contentsInfo.paidEndDtJst &&
                response.data.contentsInfo.paidEndDtUtc
            ) {
                newInfo.userInfo.paidEdDtJst =
                    response.data.contentsInfo.paidEndDtJst;
                newInfo.userInfo.paidEdDtUtc =
                    response.data.contentsInfo.paidEndDtUtc;
            } else {
                newInfo.userInfo.suppEdDtJst =
                    response.data.contentsInfo.suppEndDtJst;
                newInfo.userInfo.suppEdDtUtc =
                    response.data.contentsInfo.suppEndDtUtc;
            }
            delete newInfo.stripeInfo;
            setter(newInfo);
            setContent({
                target: 'updateComplete',
                data: {
                    title: '解約完了',
                    message:
                        '有料プランの解約が完了しました。\n現在のプランの期間終了後、アカウントは無料トライアルとして残ります。',
                },
            });
        } catch (err) {
            let message = changeJapaneseErrorMessage(
                err.response?.data.errInfo || err.message
            );
            message =
                message === 'システムエラーが発生しました。'
                    ? '有料プランの解約ができませんでした。\nしばらくたってから再度お試しください。\n問題が解決しない場合は「特定商取引法に基づく表記」ページにある連絡先にご連絡ください。'
                    : message;
            setRemoteError({
                status: true,
                message: message,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <ModalFrame modalId="loading" />}
            <div className="modal__title">有料プランの解約</div>
            <p className="modal__text__left">
                本当に有料プランを解約しますか？
                <br />
                解約する場合は、以下の注意事項を確認の上「解約する」ボタンを押しください。
            </p>
            <form>
                <div className="modal__coution">
                    <h2>注意事項</h2>
                    <ul>
                        <li>解約は取り消しができません。</li>
                        <li>
                            解約すると保存していたデータは削除されます。
                            <br />
                            （ただし、無料トライアルユーザーとして、メールアドレス、パスワード、氏名・会社情報は残ります。）
                        </li>
                        <li>削除されたデータにはアクセスできません。</li>
                    </ul>
                    <label className="checkbox">
                        <input
                            className="checkbox__input_wrap"
                            type="checkbox"
                            id="agree_paid"
                            checked={checked}
                            onChange={(e) => {
                                setChecked(e.target.checked);
                            }}
                        />
                        <span className="checkbox__input"></span>
                        <span className="checkbox__text">
                            上記を理解した上で解約します。
                        </span>
                    </label>
                </div>
                <div className="modal__btn_area">
                    <button
                        className="modal__btn modal__btn03 js-modal_close"
                        onClick={handleClose}
                    >
                        戻る
                    </button>
                    <button
                        className="modal__btn modal__btn02"
                        type="submit"
                        id="submit_paid"
                        disabled={!checked}
                        onClick={cancelSub}
                    >
                        解約する
                    </button>
                </div>
            </form>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </>
    );
};

export default ModalCancelSub;
