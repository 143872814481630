/**
 * 上書き確認保存モーダル画面
 * @param {close} モーダル閉じる処理
 * @returns
 */
const ModalOverwrite = ({ close }) => {
    //モーダル閉じる処理
    const handleClose = (e) => {
        e.preventDefault();
        close(false);
    };

    return (
        <>
            <p className="modal__text">定義が上書き保存されました。</p>
            <div className="modal__btn_area">
                <button
                    className="modal__btn modal__btn02 js-modal_close"
                    onClick={handleClose}
                >
                    OK
                </button>
            </div>
        </>
    );
};

export default ModalOverwrite;
