/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateSiwakeeInfo = /* GraphQL */ `
  subscription OnUpdateSiwakeeInfo($input: UpdateSiwakeeInfoInput) {
    onUpdateSiwakeeInfo(input: $input) {
      COMPANY_NAME
      COUNTRY_CODE
      DEF_DEL_FLG
      DEF_FILE_NAME
      DEF_MEMO
      DEF_TITLE
      DEF_REG_USER
      DEF_UPD_USER
      DEPARTMENT_NAME
      FREEPAID_END_DATE_JST
      FREEPAID_END_DATE_UTC
      FREEPAID_START_DATE_JST
      FREEPAID_START_DATE_UTC
      MAIL_ADDRESS
      NAME
      NAME_KANA
      PAID_END_DATE_JST
      PAID_END_DATE_UTC
      PAID_START_DATE_JST
      PAID_START_DATE_UTC
      SUPPORT_END_DATE_JST
      SUPPORT_END_DATE_UTC
      SUPPORT_START_DATE_JST
      SUPPORT_START_DATE_UTC
      POSITION_NAME
      PRODUCT
      SORT_KEY
      STRIPE_CUSTOMER_ID
      STRIPE_DEL_FLG
      STRIPE_PRICE_ID
      STRIPE_SUBSCRIPTION_ID
      USER
      USER_INFO_KEY
      REG_DATE_JST
      REG_DATE_UTC
      UPD_DATE_JST
      UPD_DATE_UTC
      USER_STATUS
      USER_TYPE
      PLAN_TYPE
      LAST_USED_PRICE_ID
    }
  }
`;
