import React from 'react';

import '../css/ModalStripeComplete.css';

/**
 * 変更完了モーダル
 * @param {data} ユーザーデータ
 * @param {close} 閉じる処理
 * @returns {React.Element}
 */
const ModalTopSupport = ({ close }) => {
    return (
        <>
            <div className="modal__title" />
            <p className="modal__text">
                このメニューをご利用いただくには、無料トライアルまたは有料プランでのログインが必要です。
                なお、サポート内容については
                <a
                    href="/portal/entry/2023/12/28/163204"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline' }}
                >
                    こちら
                </a>
                を参照して下さい。
            </p>
            <div>
                <div className="modal__btn_area">
                    <button
                        className="modal__btn"
                        onClick={() => {
                            close();
                        }}
                    >
                        OK
                    </button>
                </div>
            </div>
        </>
    );
};

export default ModalTopSupport;
