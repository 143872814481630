import { useContext, useEffect, useMemo, useState } from 'react';
import { AxiosContext } from './AxiosContext';
import { DataContext } from './Tool';
import {
    changeJapaneseErrorMessage,
    initialDataValue,
    initialImportValue,
} from '../common/utils';
import { AppContext } from '../View/Home';
import useFetch from '../hook/useFetch';
import ModalFrame from './ModalFrame';

/**
 * 「新規・開く」モーダル画面
 * @param {close} モーダル閉じる処理
 * @returns
 */
const ModalNewDef = ({ close, setter }) => {
    const { loaded, response } = useFetch('initAndSlctTemplate', 'POST', null);

    const instance = useContext(AxiosContext);
    const toolData = useContext(DataContext);
    // ユーザー情報
    const { data } = useContext(AppContext);

    const [templateList, setTemplateList] = useState([]);
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });

    const stableResponse = useMemo(() => {
        return response || {};
    }, [response]);

    useEffect(() => {
        if (loaded && stableResponse) {
            setTemplateList(stableResponse.templateNameInfo);
        }
    }, [loaded, stableResponse]);

    //定義ファイル読み込んで定義データ設定
    const loadTemplate = async (e, tpltName) => {
        e.preventDefault();
        try {
            const params = {
                templateName: tpltName,
            };
            const response = await instance.request({
                data: params,
                method: 'POST',
                url: 'initAndSlctTemplate',
            });
            if (
                response.data.contentsInfo &&
                response.data.contentsInfo.templateInfo
            ) {
                const data = JSON.parse(
                    response.data.contentsInfo.templateInfo
                );
                const header = [];
                data.hdColumnsL.forEach((h, idx) => {
                    const row = [h, data.hdColumnsP[idx]];
                    header.push(row);
                });
                const initVal = JSON.parse(JSON.stringify(initialDataValue));
                initVal.encoding = data.outStrCode;
                initVal.headerOutput = data.header;
                initVal.header = header;
                initVal.rows = new Array(1)
                    .fill()
                    .map(() => new Array(header.length).fill(''));
                initVal.dropped = new Array(1)
                    .fill()
                    .map(() => new Array(header.length).fill(-1));
                toolData.convData.setter(initVal);

                toolData.importArr.setter(
                    JSON.parse(JSON.stringify(initialImportValue))
                );

                toolData.clicked.setter(-1);
                close(false);
            }
        } catch (err) {
            const message = changeJapaneseErrorMessage(
                err.response?.data.errInfo || err.message
            );
            setRemoteError({
                status: true,
                message: message,
            });
        }
    };

    //新規定義開始
    const startNewDef = (e) => {
        e.preventDefault();
        toolData.convData.setter(JSON.parse(JSON.stringify(initialDataValue)));
        toolData.importArr.setter(
            JSON.parse(JSON.stringify(initialImportValue))
        );

        toolData.clicked.setter(-1);
        close(false);
    };

    const goToDefinitionInfo = (e) => {
        e.preventDefault();
        setter({ target: '/home/mypage/definition', data: undefined });
    };

    return (
        <>
            <button
                className="modal__close js-modal_close"
                onClick={() => {
                    close();
                }}
            ></button>
            <>
                <div className="modal__title">定義を作成する</div>
                <div className="modal__flex">
                    <dl className="modal__menu">
                        <dt className="modal__menu_title">新規作成</dt>
                        <dd className="modal__menu_item">
                            <button
                                onClick={(e) => {
                                    startNewDef(e);
                                }}
                            >
                                空白から作成
                            </button>
                        </dd>

                        {!loaded ? (
                            <ModalFrame modalId="loading" />
                        ) : (
                            templateList.map((val, idx) => {
                                return (
                                    <dd key={idx} className="modal__menu_item">
                                        <button
                                            onClick={(e) => {
                                                loadTemplate(e, val);
                                            }}
                                        >
                                            {val.split('_')[1]}
                                        </button>
                                    </dd>
                                );
                            })
                        )}
                    </dl>
                    <dl className="modal__menu">
                        <dt className="modal__menu_title">保存済み定義</dt>
                        <dd
                            className={
                                data && data.userInfo.userType === 'free'
                                    ? ' modal__menu_item modal__menu_item__gray'
                                    : 'modal__menu_item'
                            }
                        >
                            <button
                                className={
                                    data && data.userInfo.userType === 'free'
                                        ? 'tool_menu__no_events'
                                        : ''
                                }
                                onClick={goToDefinitionInfo}
                            >
                                保存済み定義一覧へ
                            </button>
                        </dd>
                    </dl>
                </div>
            </>

            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </>
    );
};

export default ModalNewDef;
