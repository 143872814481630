import axios from 'axios';
import { createContext, useEffect, useRef } from 'react';

export const AxiosContext = createContext(null);

/**
 * instance用createContext
 * @returns {React.Element}
 */
export const AxiosInstanceProvider = ({
    config = {},
    requestInterceptors = [],
    responseInterceptors = [],
    children,
}) => {
    const instanceRef = useRef(axios.create(config));

    useEffect(() => {
        requestInterceptors.forEach((interceptor) => {
            instanceRef.current.interceptors.request.use(interceptor);
        });
        responseInterceptors.forEach((interceptor) => {
            instanceRef.current.interceptors.response.use(interceptor);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AxiosContext.Provider value={instanceRef.current}>
            {children}
        </AxiosContext.Provider>
    );
};
