import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { getFormattedDate, isEmptyString } from '../common/utils';
import { AppContext } from '../View/Home';
import { ModalContext } from '../App';
import { AxiosContext } from './AxiosContext';
import { Auth } from 'aws-amplify';
import logo from '../img/img_logo.svg';
import ModalFrame from './ModalFrame';

/**
 * ログイン後のヘッダー
 * @returns {React.Element}
 */
function Header() {
    const { data } = useContext(AppContext);
    const dataStatus = useContext(ModalContext);
    const instance = useContext(AxiosContext);

    const [isLoading, setIsLoading] = useState(false);
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });
    const loc = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (data && data.userInfo.userStatus === 'pending') {
            setRemoteError({
                status: true,
                message: 'カード情報を登録してください。',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data && data.userInfo.userStatus]);

    // マイページ遷移
    const handleMyPage = (e) => {
        e.preventDefault();
        if (loc.pathname === '/home/mypage') {
            return;
        }
        if (dataStatus.data.dataChanged) {
            dataStatus.setter({
                ...dataStatus.data,
                display: true,
                target: '/home/mypage',
            });
        } else {
            navigate('/home/mypage');
        }
    };

    const openModal = (e) => {
        e.preventDefault();
    };

    const goInquiry = (e) => {
        e.preventDefault();
        if (loc.pathname === '/home/inquiry') {
            return;
        }
        if (dataStatus.data.dataChanged) {
            dataStatus.setter({
                ...dataStatus.data,
                display: true,
                target: '/home/inquiry',
            });
        } else {
            navigate('/home/inquiry');
        }
    };

    // ツール画面遷移
    const goTool = (e) => {
        e.preventDefault();
        if (loc.pathname === '/home') {
            return;
        }
        if (dataStatus.data.dataChanged) {
            dataStatus.setter({
                ...dataStatus.data,
                display: true,
                target: '/home',
            });
        } else {
            navigate('/home');
        }
    };

    // 有料プラン登録処理
    const handleRegisterPaid = (e) => {
        e.preventDefault();
        if (data.userInfo.userType === 'paid' && !data.stripeInfo) {
            dataStatus.setter({
                ...dataStatus.data,
                display: true,
                target: 'stripeConfirm',
            });
        } else {
            dataStatus.setter({
                ...dataStatus.data,
                display: true,
                target: 'stripeReg',
                data: 'convert',
            });
        }
    };

    // ログアウト処理
    const handleLogout = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const params = {
                authType: 'LOGOUT',
            };
            await instance.request({
                data: params,
                method: 'POST',
                url: 'settingAuthenticationHistory',
            });
            window.sessionStorage.clear();
            await Auth.signOut();
            navigate('/');
        } catch (err) {
            await Auth.signOut();
            navigate('/');
        } finally {
            setIsLoading(false);
        }
    };

    const getEndPaidDate = () => {
        try {
            const paidDate = new Date(data.userInfo.paidEdDtJst);
            const suppDate = new Date(data.userInfo.suppEdDtJst);

            if (paidDate > suppDate) {
                return `${getFormattedDate(
                    new Date(data.userInfo.paidEdDtJst),
                    'yyyy年MM月dd日'
                )}`;
            } else {
                return `${getFormattedDate(
                    new Date(data.userInfo.suppEdDtJst),
                    'yyyy年MM月dd日'
                )}`;
            }
        } catch (Err) {
            return '';
        }
    };

    return (
        <header className="header">
            {isLoading && <ModalFrame modalId="loading" />}
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
            <div className="header__left">
                <h1 className="header__logo">
                    <img src={logo} alt="siwakee" />
                </h1>
                <div className="header__name">
                    {!isEmptyString(data) ? data.nameCompanyInfo.name : ''}
                    &nbsp;&nbsp;様
                    {data &&
                    data.userInfo &&
                    (data.userInfo.userType === 'paid' ||
                        data.userInfo.userType.startsWith('fpaid')) ? (
                        <span className="header__member">有料プラン</span>
                    ) : (
                        <span className="header__member header__member--free">
                            無料トライアル
                        </span>
                    )}
                    {data &&
                    (data.userInfo.userType === 'free' ||
                        data.userInfo.userType === 'fpaidLastMonth' ||
                        (data.userInfo.userType === 'paid' &&
                            !data.stripeInfo)) ? (
                        <a
                            className="header__register"
                            href=""
                            onClick={handleRegisterPaid}
                        >
                            {data.userInfo.userType === 'free'
                                ? '有料プラン登録はこちら'
                                : data.userInfo.userType === 'fpaidLastMonth'
                                ? '有料プランの継続はこちら（現プランは' +
                                  getFormattedDate(
                                      new Date(data.userInfo.fpaidEdDtJst),
                                      'yyyy年MM月dd日'
                                  ) +
                                  'まで有効）'
                                : `有料プランの継続はこちら（現プランは${getEndPaidDate()}まで有効）`}
                        </a>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <nav className="header__navwrap">
                <ul className="header__nav">
                    <li>
                        <a
                            className="header__icon header__icon--data"
                            onClick={goTool}
                            href=""
                        >
                            データ変換
                        </a>
                    </li>
                    <li>
                        <a
                            className="header__icon header__icon--howto js-modal_toggle"
                            href="/portal/howto"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            使い方
                        </a>
                    </li>
                    <li>
                        <a
                            className="header__icon header__icon--inquiry"
                            onClick={goInquiry}
                            href=""
                        >
                            サポート
                        </a>
                    </li>
                    <li>
                        <a
                            className="header__icon header__icon--mypage"
                            onClick={handleMyPage}
                            href=""
                        >
                            マイページ
                        </a>
                    </li>
                    <li>
                        <a
                            className="header__icon header__icon--logout"
                            onClick={handleLogout}
                            href=""
                        >
                            ログアウト
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
