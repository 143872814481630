import { useContext, useEffect, useState } from 'react';
import { DataContext } from './Tool';

/**
 * 定義テーブルヘッダー
 * @param {children}
 * @param {id} 表示されてる文字
 * @param {menu} 表示されてるメニューID
 * @param {menuOpe} メニュー処理（行・列削除か追加）
 * @returns
 */
const ToolTableHeaderLeft = ({ children, id, menu, menuOpe, parentRef }) => {
    const { convData } = useContext(DataContext);
    const [menuStyle, setMenuStyle] = useState({
        right: '-162px',
        top: '17px',
    });

    const clickedButtonStyle = {
        background: '#fff',
        boxShadow: '0 0 7px rgba(0, 0, 0, 0.17)',
    };

    const defaultButtonStyle = {
        background: '',
        boxShadow: 'none',
    };

    useEffect(() => {
        const parentWrap = parentRef.current;
        const obs = new ResizeObserver(() => {
            if (id > 0) {
                if (
                    parentWrap.style.height.split('px').join('') <
                    200 + 60 * id
                ) {
                    setMenuStyle({ right: '-162px', top: '-50px' });
                } else {
                    setMenuStyle({ right: '-162px', top: '17px' });
                }
            }
        });

        if (parentWrap) {
            obs.observe(parentWrap);
        }
        return () => {
            obs.disconnect();
        };
    }, []);

    //　メニュー表示・非表示
    const onMenuClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        menu.setter(menu.idSet === id ? '' : id);
    };

    // メニュー処理
    const handleMenuClick = (type) => {
        if (type === 'before') {
            menuOpe('add', id);
        } else if (type === 'after') {
            menuOpe('add', id + 1);
        } else if (type === 'del') {
            menuOpe(type, id);
        }
    };

    return (
        <div className="sheet__left_header fixed">
            <div className="sheet__left_number">
                {children}
                <span
                    className="sheet__menu_btn js-sheet_menu_btn sheet__menu_btn--left"
                    onClick={onMenuClick}
                    style={
                        menu.idSet === id
                            ? clickedButtonStyle
                            : defaultButtonStyle
                    }
                ></span>
                {menu.idSet === id ? (
                    <div
                        className="sheet__menu js-sheet_menu"
                        style={menuStyle}
                    >
                        <ul>
                            <li>
                                <button
                                    className="sheet__menu_icon sheet__menu_icon--plus"
                                    onClick={() => {
                                        handleMenuClick('before');
                                    }}
                                >
                                    上に1行挿入
                                </button>
                            </li>
                            <li>
                                <button
                                    className="sheet__menu_icon sheet__menu_icon--plus"
                                    onClick={() => {
                                        handleMenuClick('after');
                                    }}
                                >
                                    下に1行挿入
                                </button>
                            </li>
                            <li>
                                <button
                                    className="sheet__menu_icon sheet__menu_icon--delete"
                                    disabled={convData.data.rows.length === 1}
                                    onClick={() => {
                                        handleMenuClick('del');
                                    }}
                                >
                                    行を削除
                                </button>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default ToolTableHeaderLeft;
