import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { changeJapaneseErrorMessage } from '../common/utils';
import ModalFrame from './ModalFrame';

import '../css/resetPassword.css';
import { FrontErrorMessage } from './FrontErrorMessage';

/**
 * パスワード再発行モーダル
 * @param {setter} モーダル内容設定
 * @returns {React.Element}
 */
const ModalResetPassword = ({ setter }) => {
    const [userInfo, setUserInfo] = useState({ email: '', password: '' });
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });
    // フロントエラー
    const [frontError, setFrontError] = useState({
        email: { error: false, message: [] },
    });
    const [isLoading, setIsLoading] = useState(false);

    // メールアドレスアドレスバリデーション
    const emailValidation = (value) => {
        const emailErrorMessage = [];
        if (value === '') {
            emailErrorMessage.push('メールアドレスを入力してください。');
        }
        setFrontError((prev) => ({
            ...prev,
            email: {
                error: emailErrorMessage && emailErrorMessage.length > 0,
                message: emailErrorMessage,
            },
        }));
    };

    // 入力時処理
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setUserInfo((params) => ({
            ...params,
            [name]: value,
        }));
        emailValidation(value);
    };

    // 登録処理
    const register = async (e) => {
        e.preventDefault(); // フォーム送信を防止
        setIsLoading(true);
        try {
            const result = await Auth.forgotPassword(userInfo.email);
            setter((prev) => ({
                ...prev,
                target: 'confirmResetPassword',
                data: { email: userInfo.email },
            }));
        } catch (err) {
            const message = changeJapaneseErrorMessage(err.message);
            setRemoteError({ status: true, message: message });
        } finally {
            setIsLoading(false);
        }
    };

    // 各項目でエラーがなかったら&全ての項目が入力されていたら活性化する
    const isValid = () => {
        return !Object.values(frontError).some((e) => e.error) && userInfo.email
            ? true
            : false;
    };

    return (
        <div>
            {isLoading && <ModalFrame modalId="loading" />}
            <div className="modal__title">パスワードの再発行</div>
            <div>
                <label htmlFor="" className="modal__content_input">
                    <p>メールアドレス</p>
                    <div>
                        <input
                            type="text"
                            name="email"
                            autoComplete="email"
                            placeholder="example@siwakee.com"
                            onChange={changeHandler}
                        />
                    </div>
                </label>
                {frontError.email.error && (
                    <FrontErrorMessage message={frontError.email.message} />
                )}
                <div className="modal__btn_area">
                    <button
                        className="modal__btn back"
                        onClick={() => {
                            setter((prev) => ({ ...prev, target: 'signIn' }));
                        }}
                    >
                        戻る
                    </button>
                    <button
                        className="modal__btn"
                        onClick={register}
                        disabled={!isValid()}
                    >
                        検証コードを送信
                    </button>
                </div>
            </div>

            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </div>
    );
};

export default ModalResetPassword;
