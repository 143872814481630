import { useContext, useEffect, useState } from 'react';
import { convertLetterToNum } from '../common/utils';
import { DataContext } from './Tool';

/**
 * 定義テーブルヘッダー
 * @param {children}
 * @param {id} 表示されてる文字
 * @param {menu} 表示されてるメニューID
 * @param {menuOpe} メニュー処理（行・列削除か追加）
 * @returns
 */
const ToolTableHeaderTop = ({ children, id, menu, menuOpe, parentRef }) => {
    const { convData } = useContext(DataContext);

    const [menuStyle, setMenuStyle] = useState({
        right: '-133px',
    });

    const clickedButtonStyle = {
        background: '#fff',
        boxShadow: '0 0 7px rgba(0, 0, 0, 0.17)',
    };

    const defaultButtonStyle = {
        background: '',
        boxShadow: 'none',
    };

    useEffect(() => {
        const parentWrap = parentRef.current;
        const obs = new ResizeObserver(() => {
            const numId = convertLetterToNum(id) + 1;
            if (
                parentWrap &&
                numId === convData.data.header.length &&
                parentWrap.offsetWidth <= 200 * numId + 213
            ) {
                setMenuStyle({ right: '6px' });
            } else {
                setMenuStyle({ right: '-133px' });
            }
        });

        if (parentWrap) {
            obs.observe(parentWrap);
        }
        return () => {
            obs.disconnect();
        };
    }, []);

    useEffect(() => {
        const parentWrap = parentRef.current;
        const numId = convertLetterToNum(id) + 1;
        if (
            parentWrap &&
            numId === convData.data.header.length &&
            parentWrap.offsetWidth <= 200 * numId + 213
        ) {
            setMenuStyle({ right: '6px' });
        } else {
            setMenuStyle({ right: '-133px' });
        }
    }, [convData.data, parentRef, id]);

    //　メニュー表示・非表示
    const onMenuClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        menu.setter(menu.idSet === id ? '' : id);
    };

    // メニュー処理
    const handleMenuClick = (type) => {
        if (type === 'before') {
            menuOpe('add', convertLetterToNum(id));
        } else if (type === 'after') {
            menuOpe('add', convertLetterToNum(id) + 1);
        } else if (type === 'del') {
            menuOpe(type, convertLetterToNum(id));
        }
    };

    return (
        <div className="sheet__top_header">
            {children}
            <span
                className="sheet__menu_btn js-sheet_menu_btn"
                onClick={onMenuClick}
                style={
                    menu.idSet === id ? clickedButtonStyle : defaultButtonStyle
                }
            ></span>
            {menu.idSet === id ? (
                <div className="sheet__menu js-sheet_menu" style={menuStyle}>
                    <ul>
                        <li>
                            <button
                                className="sheet__menu_icon sheet__menu_icon--plus"
                                onClick={() => {
                                    handleMenuClick('before');
                                }}
                            >
                                左に1列挿入
                            </button>
                        </li>
                        <li>
                            <button
                                className="sheet__menu_icon sheet__menu_icon--plus"
                                onClick={() => {
                                    handleMenuClick('after');
                                }}
                            >
                                右に1列挿入
                            </button>
                        </li>
                        <li>
                            <button
                                className="sheet__menu_icon sheet__menu_icon--delete"
                                disabled={convData.data.rows[0].length === 1}
                                onClick={() => {
                                    handleMenuClick('del');
                                }}
                            >
                                列を削除
                            </button>
                        </li>
                    </ul>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ToolTableHeaderTop;
