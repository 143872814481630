import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AxiosContext } from './AxiosContext';

import '../css/ModalStripeComplete.css';

/**
 * 無料トライアル登録完了モーダル
 * @param {close} 閉じる処理
 * @param {data} 無料登録か、有料登録の中断かを判断するフラグ
 * @returns {React.Element}
 */
const ModalFreeUserComplete = ({ close, data }) => {
    const instance = useContext(AxiosContext);
    const navigate = useNavigate();

    // データ変換始める押下時の処理
    const handleClose = async () => {
        try {
            const sendParams = {
                authType: 'LOGIN',
            };
            await instance.request({
                data: sendParams,
                method: 'POST',
                url: 'settingAuthenticationHistory',
            });
        } catch (err) {
        } finally {
            close();
            navigate('/home');
        }
    };

    return (
        <>
            <div className="modal__title">無料トライアル登録完了</div>
            <p className="modal__text">
                {data === 'cancelSubscription'
                    ? '有料プランの登録が中断されたため、無料トライアルの登録のみ完了しました。'
                    : '無料トライアルの登録が完了しました。'}
            </p>
            <div>
                <div className="modal__btn_area">
                    <button className="modal__btn" onClick={handleClose}>
                        データ変換をはじめる
                    </button>
                </div>
            </div>
        </>
    );
};

export default ModalFreeUserComplete;
