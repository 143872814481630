import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { computeConversion, convertNumToLetter } from '../common/utils';
import { DataContext } from './Tool';

import '../css/ModalPreviewBox.css';
import { AppContext } from '../View/Home';

/**
 * 変換プレビューモーダル画面
 * @param {close} 閉じる処理
 * @param {setter} モーダル内容設定
 * @returns
 */
const ModalDataPreview = ({ close, setter }) => {
    const { data: userData } = useContext(AppContext);
    const { convData, importArr } = useContext(DataContext);
    const data = computeConversion(convData.data, importArr.data.cols);
    const transposedArray = data[0].map((_, colIndex) =>
        data.map((row) => row[colIndex])
    );

    // セル内のspan高さを算出してtdのheightプロパティに設定
    const refs = useRef(
        new Array(transposedArray.length)
            .fill()
            .map(() => new Array(transposedArray[0].length).fill(createRef()))
    );
    const [heights, setHeights] = useState([]);

    useEffect(() => {
        const newHeights = refs.current.map((rowRefs) =>
            rowRefs.map((cellRef) => cellRef.offsetHeight)
        );
        setHeights(newHeights);
    }, [refs]);

    //閉じる処理
    const handleClose = (e) => {
        e.preventDefault();
        close();
    };

    //プレビュー終わったら、ダウンロードモーダルに移動
    const continueToConversion = (e) => {
        e.preventDefault();
        setter({ target: 'modal06', data: undefined });
    };

    return (
        <>
            <div className="modal__title">データ変換プレビュー</div>
            <div className="modal__column">
                <div className="modal__preview_box">
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <span>　</span>
                                </th>
                                {transposedArray[0].map((val, key) => {
                                    return (
                                        <th key={'left_header_' + key}>
                                            <span>{key + 1}</span>
                                        </th>
                                    );
                                })}
                            </tr>
                            {transposedArray.map((col, key) => {
                                return (
                                    <tr key={'data_row' + key}>
                                        <th>
                                            <span>
                                                {convertNumToLetter(key)}
                                            </span>
                                        </th>
                                        {col.map((cell, idx) => {
                                            return (
                                                <td
                                                    key={
                                                        'cell_' +
                                                        key +
                                                        '_' +
                                                        idx
                                                    }
                                                    style={{
                                                        height: `${
                                                            28 +
                                                            heights[key]?.[idx]
                                                        }px`,
                                                    }}
                                                >
                                                    <span
                                                        ref={(header) =>
                                                            (refs.current[key][
                                                                idx
                                                            ] = header)
                                                        }
                                                        style={{
                                                            height: `${heights[key]?.[idx]}px`,
                                                        }}
                                                    >
                                                        {cell}
                                                    </span>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="modal__description_area">
                    {userData &&
                    userData.userInfo &&
                    userData.userInfo.userType === 'free' ? (
                        <p className="modal__description">
                            ※前ページの「変換するファイル」に表示されている10件分のデータを使って変換のイメージを表示しています。無料トライアルでは、変換実行時にヘッダー行を含む21件分までデータが変換できます。有料プランならすべての行のデータが変換できます。
                        </p>
                    ) : (
                        <p className="modal__description">
                            ※前ページの「変換するファイル」に表示されている10件分のデータを使って変換のイメージを表示しています。変換実行時には全件のデータが変換されます。
                        </p>
                    )}
                </div>
                <div className="modal__btn_area">
                    <button
                        className="modal__btn modal__btn03 js-modal_close"
                        onClick={handleClose}
                    >
                        戻る
                    </button>
                    <button
                        className="modal__btn modal__btn01 js-modal_toggle"
                        onClick={continueToConversion}
                    >
                        変換実行
                    </button>
                </div>
            </div>
        </>
    );
};

export default ModalDataPreview;
