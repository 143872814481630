import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    changeJapaneseErrorMessage,
    initialDataValue,
    initialImportValue,
    isEmptyString,
    prepareDefInfo,
} from '../common/utils';
import { AppContext } from '../View/Home';
import { ModalContext } from '../App';
import { AxiosContext } from './AxiosContext';
import { useLocation } from 'react-router-dom';
import ToolSource from './ToolSource';
import ToolData from './ToolData';
import Tooltip from './Tooltip';
import ModalFrame from './ModalFrame';

import '../css/tool.css';

export const DataContext = createContext();

/**
 * ツールコンポネント。
 * 「データ変換を定義する」と「変換するファイル」含み
 * @param {status} データ状態 （変更あり・なし）
 * @returns
 */
function Tool() {
    const { state } = useLocation();

    // CSVダウンロードURL
    const dlLinkRef = useRef();
    const sheetRef = useRef();
    const sheetAreaRef = useRef();
    const sheetWrapRef = useRef();

    // AXIOSインスタンス
    const instance = useContext(AxiosContext);
    // ユーザー情報
    const { data } = useContext(AppContext);
    const dataStatus = useContext(ModalContext);

    // ソースの列数保存
    const [sourceClicked, setSourceClicked] = useState(-1);
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });
    //定義データ保存
    const [mainRows, setMainRows] = useState(() => {
        if (state) {
            const initVal = JSON.parse(JSON.stringify(initialDataValue));
            initVal.name = state.defInfo.definitionFileName;
            initVal.title = state.defInfo.definitionTitle;
            initVal.memo = state.defInfo.definitionMemo;
            initVal.encoding = state.data.outStrCode;
            initVal.headerOutput = state.data.header;
            initVal.rows = state.data.mapNameDtArr;
            initVal.dropped = state.data.mapDtArr;

            const header = [];
            state.data.hdColumnsL.forEach((h, idx) => {
                const row = [h, state.data.hdColumnsP[idx]];
                header.push(row);
            });
            initVal.header = header;
            window.history.replaceState({}, document.title);
            return initVal;
        } else {
            return JSON.parse(JSON.stringify(initialDataValue));
        }
    });
    //ソースデータ保存
    const [importInfo, setImportInfo] = useState(() => {
        if (state) {
            const initVal = JSON.parse(JSON.stringify(initialImportValue));
            initVal.cols = new Array(state.data.sourceMaxColumns)
                .fill()
                .map(() => []);
            initVal.fromDef = 1;
            if (state.data.inputStrCode === 0) {
                initVal.encoding = 'SJIS';
            } else if (state.data.inputStrCode === 1) {
                initVal.encoding = 'UTF-8';
            } else if (state.data.inputStrCode === 2) {
                initVal.encoding = '';
            }

            window.history.replaceState({}, document.title);
            return initVal;
        } else {
            return JSON.parse(JSON.stringify(initialImportValue));
        }
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // ダウンロードURL設定される場合、ダウンロードを行って、ダウンロードURL削除
        if (!isEmptyString(mainRows.dlURL)) {
            dlLinkRef.current.click();
            URL.revokeObjectURL(mainRows.dlURL);
            setMainRows({ ...mainRows, dlURL: '' });
        }
    }, [mainRows]);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return(() => {
            document.body.style.overflow = 'auto';
        })
    }, []);

    //　セル・ソース列をクリックするときに、クリックされた列数設定。
    const updateBGColor = (id) => {
        setSourceClicked(id === sourceClicked ? -1 : id);
    };

    /**
     * モーダル画面表示開始
     * @param {*} modalId 開きたいモーダル番号
     */
    const openModal = (e, modalId) => {
        e.preventDefault();

        dataStatus.setter({
            ...dataStatus.data,
            display: true,
            target: modalId,
            data: { from: 'tool' },
        });
    };

    // 保存されてる定義ファイル上書き
    const overwriteDefFile = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const params = {
                definitionFileName: mainRows.name,
                definitionTitle: mainRows.title,
                definitionMemo: mainRows.memo,
                fileInfo: JSON.stringify(prepareDefInfo(mainRows, importInfo)),
            };
            const response = await instance.request({
                data: params,
                method: 'POST',
                url: 'settingDefinition',
            });
            openModal(e, 'modal05');
        } catch (err) {
            let message = changeJapaneseErrorMessage(
                err.response?.data.errInfo
            );
            message =
                message === 'システムエラーが発生しました。'
                    ? '定義が上書き保存できませんでした。\nしばらくたってから再度お試しください。'
                    : message;
            setRemoteError({
                status: true,
                message: message,
            });
        } finally {
            setLoading(false);
        }
    };

    // データ変換実行
    const convertData = (e) => {
        e.preventDefault();
        if (isEmptyString(importInfo.name)) {
            setRemoteError({
                status: true,
                message: 'ファイルを読み込んでください',
            });
        } else {
            dataStatus.setter({
                ...dataStatus.data,
                display: true,
                target: 'modal06',
            });
        }
    };

    const openPreview = (e) => {
        e.preventDefault();
        if (isEmptyString(importInfo.name)) {
            setRemoteError({
                status: true,
                message: 'ファイルを読み込んでください',
            });
        } else {
            openModal(e, 'modal02');
        }
    };

    return (
        <main className="main">
            {loading && <ModalFrame modalId="loading" />}
            <div className="tool_menu">
                <h2 className="tool_menu__title">
                    データ変換を定義する
                    <span className="tool_menu__definition">定義名 ： </span>
                    {mainRows.title.length > 24 ? (
                        <>
                            <span className="tool_menu__definition_name js-help">
                                {mainRows.title.substring(0, 24) + '...'}
                            </span>
                            <Tooltip type="memo">{mainRows.title}</Tooltip>
                        </>
                    ) : (
                        <span className="tool_menu__definition_name">
                            {mainRows.title}
                        </span>
                    )}
                </h2>
                <ul className="tool_menu__menu">
                    <li>
                        <button
                            className={
                                'tool_menu__icon tool_menu__icon--newdoc'
                            }
                            onClick={(e) => {
                                openModal(e, 'modal01');
                            }}
                        >
                            新規 / 開く
                        </button>
                    </li>
                    <li>
                        <button
                            className={
                                (data && data.userInfo.userType === 'free') ||
                                mainRows.name === ''
                                    ? 'tool_menu__icon tool_menu__icon--save_gray tool_menu__no_events'
                                    : 'tool_menu__icon tool_menu__icon--save'
                            }
                            onClick={overwriteDefFile}
                        >
                            上書き保存
                        </button>
                    </li>
                    <li>
                        <button
                            className={
                                data && data.userInfo.userType === 'free'
                                    ? 'tool_menu__icon tool_menu__icon--saveas_gray tool_menu__no_events'
                                    : 'tool_menu__icon tool_menu__icon--saveas'
                            }
                            onClick={(e) => {
                                openModal(e, 'modal03');
                            }}
                        >
                            名前を付けて保存
                        </button>
                    </li>
                    <li>
                        <button
                            className="tool_menu__icon tool_menu__icon--preview"
                            onClick={openPreview}
                        >
                            変換プレビュー
                        </button>
                    </li>
                    <li>
                        <button
                            className="tool_menu__btn"
                            onClick={convertData}
                        >
                            変換実行
                        </button>
                        <a
                            style={{ display: 'none' }}
                            download="swk_converted_data.csv"
                            href={mainRows.dlURL}
                            ref={dlLinkRef}
                        >
                            csvDownload
                        </a>
                    </li>
                </ul>
            </div>
            <div className="output_menu">
                <label className="checkbox">
                    <input
                        className="checkbox__input_wrap"
                        type="checkbox"
                        checked={mainRows.headerOutput}
                        onChange={(e) => {
                            if (!dataStatus.data.dataChanged) {
                                dataStatus.setter({
                                    ...dataStatus.data,
                                    dataChanged:
                                        data &&
                                        data.userInfo.userType === 'free'
                                            ? false
                                            : true,
                                });
                            }
                            setMainRows({
                                ...mainRows,
                                headerOutput: Number(e.target.checked),
                            });
                        }}
                    />
                    <span className="checkbox__input"></span>
                    <span className="checkbox__text">
                        ヘッダー行を出力に含める
                        <span className="checkbox__icon js-help"></span>
                        <Tooltip>
                            出力するCSVファイルにヘッダー行を含めるかどうか選択できます（メモ行は含まれません）。例えば、勘定奉行はヘッダー行の出力が必要ですが、弥生会計は不要です。
                        </Tooltip>
                    </span>
                </label>
                <div className="output_menu__radio_wrap">
                    <div className="output_menu__radio_title">
                        文字コード
                        <span
                            id="js-help"
                            className="radio__icon js-help"
                        ></span>
                        <Tooltip>
                            出力するCSVファイルの文字コードを選択できます。例えば、勘定奉行と弥生会計は
                            Shift_JIS です。
                        </Tooltip>
                        &nbsp;&nbsp;：
                    </div>

                    <label className="radio">
                        <input
                            className="radio__input_wrap"
                            type="radio"
                            name="radio_output"
                            checked={mainRows.encoding === 0}
                            onChange={(e) => {
                                if (!dataStatus.data.dataChanged) {
                                    dataStatus.setter({
                                        ...dataStatus.data,
                                        dataChanged:
                                            data &&
                                            data.userInfo.userType === 'free'
                                                ? false
                                                : true,
                                    });
                                }
                                setMainRows({
                                    ...mainRows,
                                    encoding: e.target.checked ? 0 : 1,
                                });
                            }}
                        />
                        <span className="radio__input"></span>
                        <span className="radio__text">Shift_JIS</span>
                    </label>
                    <label className="radio">
                        <input
                            className="radio__input_wrap"
                            type="radio"
                            name="radio_output"
                            checked={mainRows.encoding === 1}
                            onChange={(e) => {
                                if (!dataStatus.data.dataChanged) {
                                    dataStatus.setter({
                                        ...dataStatus.data,
                                        dataChanged:
                                            data &&
                                            data.userInfo.userType === 'free'
                                                ? false
                                                : true,
                                    });
                                }
                                setMainRows({
                                    ...mainRows,
                                    encoding: e.target.checked ? 1 : 0,
                                });
                            }}
                        />
                        <span className="radio__input"></span>
                        <span className="radio__text">UTF-8</span>
                    </label>
                </div>
            </div>
            <DataContext.Provider
                value={{
                    convData: {
                        data: mainRows,
                        setter: setMainRows,
                    },
                    importArr: {
                        data: importInfo,
                        setter: setImportInfo,
                    },
                    clicked: {
                        data: sourceClicked,
                        setter: setSourceClicked,
                    },
                }}
            >
                <ToolData
                    updateBG={updateBGColor}
                    sheetRef={sheetRef}
                    sheetAreaRef={sheetAreaRef}
                    sheetWrapRef={sheetWrapRef}
                />
                <ToolSource
                    updateBG={updateBGColor}
                    sheetAreaRef={sheetAreaRef}
                    remoteError={setRemoteError}
                />

                {dataStatus.data.display && (
                    <ModalFrame
                        modalId={
                            dataStatus.data.target !== 'modal03' &&
                            dataStatus.data.target !== 'modal05' &&
                            dataStatus.data.dataChanged
                                ? 'modal04'
                                : dataStatus.data.target
                        }
                        data={dataStatus.data.data}
                    />
                )}
                {remoteError.status && (
                    <ModalFrame
                        modalId="error"
                        data={remoteError.message}
                        errorHandling={setRemoteError}
                    />
                )}
            </DataContext.Provider>
        </main>
    );
}

export default Tool;
