import loading from '../img/img_loading.gif';

import '../css/loading.css';
/**
 * ローディング画像
 * @returns {React.Element}
 */
const Loading = () => {
    return <img className="loading" src={loading} alt="" />;
};

export default Loading;
