import { useContext, useState } from 'react';
import { AxiosContext } from './AxiosContext';
import { Auth } from 'aws-amplify';
import { AppContext } from '../View/Home';
import { changeJapaneseErrorMessage } from '../common/utils';
import ModalFrame from './ModalFrame';

/**
 * アカウント削除モーダル
 * @param {close} 閉じる処理
 * @param {setter} モーダル内容設定
 * @returns {React.Element}
 */
const ModalDeleteAcc = ({ close, setter }) => {
    const instance = useContext(AxiosContext);
    const { data } = useContext(AppContext);

    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });

    // 閉じる処理
    const handleClose = (e) => {
        e.preventDefault();
        close();
    };

    // アカウント削除処理
    const deleteAcc = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            // マイページからのパターン
            if (data.stripeInfo) {
                const cancelSub = await instance.request({
                    data: null,
                    method: 'POST',
                    url: 'paidCancellation',
                });
            }
            const response = await instance.request({
                data: null,
                method: 'POST',
                url: 'settingWithdrawal',
            });
            await Auth.deleteUser();
            setter({
                target: 'updateComplete',
                data: {
                    title: 'アカウント削除完了',
                    message:
                        'アカウント削除が完了しました。\nご利用ありがとうございました。',
                },
            });
        } catch (err) {
            let message = changeJapaneseErrorMessage(
                err.response?.data.errInfo || err.message
            );
            message =
                message === 'システムエラーが発生しました。'
                    ? 'アカウント削除ができませんでした。\nしばらくたってから再度お試しください。'
                    : message;
            setRemoteError({
                status: true,
                message: message,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <ModalFrame modalId="loading" />}
            <div className="modal__title">アカウント削除</div>
            <p className="modal__text__left">
                本当にアカウントを削除しますか？
                <br />
                削除する場合は、以下の注意事項を確認の上「削除する」ボタンを押してください。
            </p>
            <form>
                <div className="modal__coution">
                    <h2>注意事項</h2>
                    <ul>
                        <li>アカウント削除は取り消しができません。</li>
                        <li>
                            アカウント削除をすると保存していたデータは削除されます。
                        </li>
                        <li>削除されたデータにはアクセスできません。</li>
                    </ul>
                    <label className="checkbox">
                        <input
                            className="checkbox__input_wrap"
                            type="checkbox"
                            id="agree_account"
                            checked={checked}
                            onChange={(e) => {
                                setChecked(e.target.checked);
                            }}
                        />
                        <span className="checkbox__input"></span>
                        <span className="checkbox__text">
                            上記を理解した上でアカウントを削除します。
                        </span>
                    </label>
                </div>
                <div className="modal__btn_area">
                    <button
                        className="modal__btn modal__btn03 js-modal_close"
                        onClick={handleClose}
                    >
                        戻る
                    </button>
                    <button
                        className="modal__btn modal__btn02"
                        type="submit"
                        id="submit_account"
                        disabled={!checked}
                        onClick={deleteAcc}
                    >
                        削除する
                    </button>
                </div>
            </form>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </>
    );
};

export default ModalDeleteAcc;
