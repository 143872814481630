import { useContext, useState } from 'react';
import { changeJapaneseErrorMessage } from '../common/utils';
import { AxiosContext } from './AxiosContext';

import '../css/ModalPreviewBox.css';
import ModalFrame from './ModalFrame';

/**
 * 定義削除確認モーダル
 * @param {close} 閉じる処理
 * @param {setter} モーダル内容設定
 * @returns
 */
const ModalDeleteDef = ({ close, setter, data }) => {
    const instance = useContext(AxiosContext);
    const { defInfo, setDefInfo, id } = data;
    const [loading, setLoading] = useState(false);
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });

    // 仕訳定義削除処理
    const deleteDef = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const params = {
                definitionFileName: defInfo[id].definitionFileName,
                definitionTitle: defInfo[id].definitionTitle,
                definitionDelFlg: '1',
            };
            await instance.request({
                data: params,
                method: 'POST',
                url: 'settingDefinition',
            });

            const tmpInfo = [...defInfo];
            tmpInfo.splice(id, 1);
            setDefInfo(tmpInfo);
            setter({
                target: 'updateComplete',
                data: {
                    title: '定義の削除完了',
                    message: '定義の削除が完了しました。',
                },
            });
        } catch (err) {
            const message = changeJapaneseErrorMessage(
                err.response?.data.errInfo || err.message
            );
            setRemoteError({
                status: true,
                message: message,
            });
        } finally {
            setLoading(false);
        }
    };

    //閉じる処理
    const handleClose = (e) => {
        e.preventDefault();
        close();
    };

    return (
        <>
            {loading && <ModalFrame modalId="loading" />}
            <p className="modal__text _delete">
                仕訳定義を削除してもよろしいですか？
                <br />
                削除後は元に戻すことはできません。
            </p>
            <div className="modal__btn_area">
                <button
                    className="modal__btn modal__btn03 js-modal_close"
                    onClick={handleClose}
                >
                    戻る
                </button>
                <button className="modal__btn modal__btn02" onClick={deleteDef}>
                    削除する
                </button>
            </div>
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </>
    );
};

export default ModalDeleteDef;
