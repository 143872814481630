import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

/**
 * 認証確認
 * @param {children} 子コンポーネント、DOM
 * @returns {React.Element}
 */
function RequireAuth({ children }) {
    const [isAuth, setIsAuth] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const authUser = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                // ユーザー情報が登録されている場合
                if (user.attributes['custom:regInfo'] === '1') {
                    setIsAuth(true);
                } else {
                    throw Error();
                }
            } catch (e) {
                navigate('/', {
                    // state: {from: location},
                    replace: true,
                });
                return;
            }
        };
        authUser();
    });
    return isAuth && children;
}

export default RequireAuth;
