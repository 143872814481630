import { useContext, useEffect, useMemo, useState } from 'react';
import { AxiosContext } from './AxiosContext';
import { ModalContext } from '../App';
import { useNavigate } from 'react-router-dom';
import useFetch from '../hook/useFetch';
import ModalFrame from './ModalFrame';
import DefinitionInfoMenuLeft from './DefinitionInfoMenuLeft';
import DefinitionInfoMenuTop from './DefinitionInfoMenuTop';
import Tooltip from './Tooltip';
import { changeJapaneseErrorMessage } from '../common/utils';

/**
 * マイページ保存済定義一覧タブ
 * @returns {React.Element}
 */
const DefinitionInfo = () => {
    const navigate = useNavigate();

    const { loaded, response } = useFetch(
        'initAndSlctDefinition',
        'POST',
        null
    );

    const instance = useContext(AxiosContext);
    const dataStatus = useContext(ModalContext);

    const [menuDisplayedID, setMenuDisplayedID] = useState('');
    const [defInfo, setDefInfo] = useState([]);
    const [charLimit, setCharLimit] = useState({
        memo: Math.floor((window.innerWidth * 0.263) / 16),
        other: 15,
    });
    const [loading, setLoading] = useState(false);
    const [remoteError, setRemoteError] = useState({
        status: false,
        message: '',
    });
    const [sortArrow, setSortArrow] = useState({
        id: '',
        sort: '',
    });

    const stableResponse = useMemo(() => {
        return response || {};
    }, [response]);

    useEffect(() => {
        if (loaded && stableResponse) {
            setDefInfo(stableResponse.definitionInfo);
        }
    }, [loaded, stableResponse]);

    useEffect(() => {
        const handleWindowResize = (e) => {
            const limit = {
                memo: Math.floor((window.innerWidth * 0.263) / 16),
                other: 15,
            };
            if (e.target.innerWidth > 1470) {
                limit.memo = Math.floor((e.target.innerWidth * 0.263) / 16);
            } else if (e.target.innerWidth > 1300) {
                limit.memo = 20;
            } else {
                limit.memo = 13;
            }

            if (e.target.innerWidth > 1500) {
                limit.other = 15;
            } else if (e.target.innerWidth > 1400) {
                limit.other = 13;
            } else {
                limit.other = 10;
            }
            setCharLimit(limit);
        };
        //メニュー以外なところをクリックするとメニューを閉じる
        document.body.addEventListener('click', hideMenu);
        window.addEventListener('resize', handleWindowResize);

        return () => {
            document.body.removeEventListener('click', hideMenu);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const hideMenu = () => {
        setMenuDisplayedID('');
    };

    const goToTool = async (e, val) => {
        e.preventDefault();
        try {
            setLoading(true);
            const params = {
                definitionFileName: val.definitionFileName,
            };
            const response = await instance.request({
                data: params,
                method: 'POST',
                url: 'initAndSlctDefinition',
            });
            const fileContent = JSON.parse(response.data.contentsInfo.fileInfo);
            navigate('/home', { state: { data: fileContent, defInfo: val } });
        } catch (err) {
            const message = changeJapaneseErrorMessage(
                err.response?.data.errInfo || err.message
            );
            setRemoteError({
                status: true,
                message: message,
            });
        } finally {
            setLoading(false);
        }
    };

    // 更新処理
    const updateDef = (id) => {
        dataStatus.setter({
            display: true,
            target: 'modal03',
            unsaved: false,
            data: { from: 'myPage', init: defInfo[id] },
        });
    };

    // 削除処理
    const deleteDef = async (id) => {
        dataStatus.setter({
            display: true,
            target: 'deleteDef',
            unsaved: false,
            data: { defInfo: defInfo, setDefInfo: setDefInfo, id: id },
        });
    };

    // 複製処理
    const copyDef = async (id) => {
        try {
            setLoading(true);
            const defTitle = defInfo[id].definitionTitle;
            const usedIdx = [];
            let copySuffix = ' - コピー';
            defInfo.forEach((e) => {
                if (e.definitionTitle.startsWith(defTitle)) {
                    const remTitle = e.definitionTitle.replace(defTitle, '');
                    const count = (remTitle.match(/ - コピー/g) || []).length;
                    if (remTitle === copySuffix) {
                        usedIdx.push(1);
                    } else if (count === 1) {
                        const rParenth = remTitle.lastIndexOf('(');
                        const lParenth = remTitle.lastIndexOf(')');
                        if (rParenth > -1 && lParenth > -1) {
                            try {
                                const parseNum = parseInt(
                                    remTitle.substring(rParenth + 1, lParenth)
                                );
                                if (parseNum) {
                                    usedIdx.push(parseNum);
                                }
                            } catch (err) {}
                        }
                    }
                }
            });
            if (usedIdx.length > 0) {
                let minIdx = 0;
                usedIdx.sort((a, b) => a - b);
                usedIdx.every((i) => {
                    if (minIdx + 1 === i) {
                        minIdx = i;
                        return true;
                    } else {
                        return false;
                    }
                });
                if (minIdx > 0) {
                    copySuffix += ' (' + (minIdx + 1) + ')';
                }
            }

            const params = {
                prevDefFileName: defInfo[id].definitionFileName,
                definitionTitle: defTitle + copySuffix,
                definitionMemo: defInfo[id].definitionMemo,
            };

            const response = await instance.request({
                data: params,
                method: 'POST',
                url: 'duplicateDefinition',
            });

            const newDef = {
                definitionFileName:
                    response.data.contentsInfo.definitionFileName,
                definitionTitle: defTitle + copySuffix,
                definitionMemo: defInfo[id].definitionMemo,
                regDateJst: response.data.contentsInfo.definitionRegDate,
                regUser: response.data.contentsInfo.definitionRegUser,
            };

            const tmpInfo = [...defInfo];
            tmpInfo.splice(id + 1, 0, newDef);
            setDefInfo(tmpInfo);
            dataStatus.setter({
                display: true,
                target: 'updateComplete',
                dataChanged: false,
                data: {
                    title: '定義の複製完了',
                    message: '定義の複製が完了しました。',
                },
            });
        } catch (err) {
            const message = changeJapaneseErrorMessage(
                err.response?.data.errInfo || err.message
            );
            setRemoteError({
                status: true,
                message: message,
            });
        } finally {
            setLoading(false);
        }
    };

    // 昇順に並び替え
    const sortAsc = (id) => {
        const prop =
            id === 'A'
                ? 'definitionTitle'
                : id === 'B'
                ? 'updDateJst'
                : id === 'C'
                ? 'updUser'
                : id === 'D'
                ? 'regDateJst'
                : id === 'E' && 'regUser';
        const sortAscDefInfo = defInfo.sort((a, b) => {
            if (a[prop] === null && b[prop] !== null) {
                return 1;
            } else if (a[prop] !== null && b[prop] === null) {
                return -1;
            } else if (a[prop] !== null && b[prop] !== null) {
                if (a[prop] < b[prop]) {
                    return -1;
                } else if (a[prop] > b[prop]) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        });
        // 矢印の向きを変える
        setSortArrow({
            id: id,
            sort: '_up',
        });
        setDefInfo(sortAscDefInfo);
    };
    // 降順に並び替え
    const sortDesc = (id) => {
        const prop =
            id === 'A'
                ? 'definitionTitle'
                : id === 'B'
                ? 'updDateJst'
                : id === 'C'
                ? 'updUser'
                : id === 'D'
                ? 'regDateJst'
                : id === 'E' && 'regUser';
        const sortDescDefInfo = defInfo.sort((a, b) => {
            if (a[prop] === null && b[prop] !== null) {
                return 1;
            } else if (a[prop] !== null && b[prop] === null) {
                return -1;
            } else if (a[prop] !== null && b[prop] !== null) {
                if (a[prop] > b[prop]) {
                    return -1;
                } else if (a[prop] < b[prop]) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        });
        // 矢印の向きを変える
        setSortArrow({
            id: id,
            sort: '_down',
        });
        setDefInfo(sortDescDefInfo);
    };

    return (
        <div className="mypage_tab__content" id="list_content">
            {(!loaded || loading) && <ModalFrame modalId="loading" />}
            <div className="content_list">
                <table className="content_list__table">
                    <tbody>
                        <tr className="list">
                            <th className="list_row _list_sort">
                                定義名
                                <DefinitionInfoMenuTop
                                    id="A"
                                    menu={{
                                        idSet: menuDisplayedID,
                                        setter: setMenuDisplayedID,
                                    }}
                                    sortAscFunction={sortAsc}
                                    sortDescFunction={sortDesc}
                                    sortArrow={sortArrow}
                                />
                            </th>
                            <th>メモ</th>
                            <th className="list_row _list_sort">
                                作成日時
                                <DefinitionInfoMenuTop
                                    id="D"
                                    menu={{
                                        idSet: menuDisplayedID,
                                        setter: setMenuDisplayedID,
                                    }}
                                    sortAscFunction={sortAsc}
                                    sortDescFunction={sortDesc}
                                    sortArrow={sortArrow}
                                />
                            </th>
                            <th className="list_row _list_sort">
                                作成者
                                <DefinitionInfoMenuTop
                                    id="E"
                                    menu={{
                                        idSet: menuDisplayedID,
                                        setter: setMenuDisplayedID,
                                    }}
                                    sortAscFunction={sortAsc}
                                    sortDescFunction={sortDesc}
                                    sortArrow={sortArrow}
                                />
                            </th>
                            <th className="list_row _list_sort">
                                更新日時
                                <DefinitionInfoMenuTop
                                    id="B"
                                    menu={{
                                        idSet: menuDisplayedID,
                                        setter: setMenuDisplayedID,
                                    }}
                                    sortAscFunction={sortAsc}
                                    sortDescFunction={sortDesc}
                                    sortArrow={sortArrow}
                                />
                            </th>
                            <th className="list_row _list_sort">
                                更新者
                                <DefinitionInfoMenuTop
                                    id="C"
                                    menu={{
                                        idSet: menuDisplayedID,
                                        setter: setMenuDisplayedID,
                                    }}
                                    sortAscFunction={sortAsc}
                                    sortDescFunction={sortDesc}
                                    sortArrow={sortArrow}
                                />
                            </th>
                        </tr>
                        {defInfo.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td className="list_row _list_edit">
                                        <div className="underbar__deco"></div>
                                        <DefinitionInfoMenuLeft
                                            id={key}
                                            menu={{
                                                idSet: menuDisplayedID,
                                                setter: setMenuDisplayedID,
                                            }}
                                            updDefinition={updateDef}
                                            delDefinition={deleteDef}
                                            copyDefinition={copyDef}
                                        />
                                        <a
                                            className="journal_definition_name"
                                            href=""
                                            onClick={(e) => {
                                                goToTool(e, val);
                                            }}
                                        >
                                            {val.definitionTitle}
                                        </a>
                                    </td>
                                    {val.definitionMemo.length <
                                    charLimit.memo ? (
                                        <td className="omit_area _memo">
                                            {val.definitionMemo}
                                        </td>
                                    ) : (
                                        <td className="omit_area _memo js-help">
                                            <div className="memo_area">
                                                {val.definitionMemo}
                                            </div>
                                            <Tooltip type="defMemo">
                                                {val.definitionMemo}{' '}
                                            </Tooltip>
                                        </td>
                                    )}
                                    <td>{val.regDateJst}</td>
                                    {val.regUser.length < charLimit.other ? (
                                        <td className="omit_area">
                                            {val.regUser}
                                        </td>
                                    ) : (
                                        <td className="omit_area js-help">
                                            <div className="memo_area">
                                                {val.regUser}
                                            </div>
                                            <Tooltip type="defMemo">
                                                {val.regUser}{' '}
                                            </Tooltip>
                                        </td>
                                    )}
                                    <td>{val.updDateJst}</td>
                                    {val.updUser ? (
                                        val.updUser.length < charLimit.other ? (
                                            <td className="omit_area">
                                                {val.updUser}
                                            </td>
                                        ) : (
                                            <td className="omit_area js-help">
                                                <div className="memo_area">
                                                    {val.updUser}
                                                </div>
                                                <Tooltip type="defMemo">
                                                    {val.updUser}{' '}
                                                </Tooltip>
                                            </td>
                                        )
                                    ) : (
                                        <td className="omit_area"></td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {dataStatus.data.display && (
                <ModalFrame
                    modalId={dataStatus.data.target}
                    data={dataStatus.data.data}
                />
            )}
            {remoteError.status && (
                <ModalFrame
                    modalId="error"
                    data={remoteError.message}
                    errorHandling={setRemoteError}
                />
            )}
        </div>
    );
};

export default DefinitionInfo;
