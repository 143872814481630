import { useState, useEffect, useRef, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { ModalContext } from '../App';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromiseContext } from '../App';
import ModalConversion from './ModalConversion';
import ModalDataPreview from './ModalDataPreview';
import ModalError from './ModalError';
import ModalNewDef from './ModalNewDef';
import ModalOverwrite from './ModalOverwrite';
import ModalSaveDef from './ModalSaveDef';
import ModalUnsaved from './ModalUnsaved';
import ModalUserInfoReg from './ModalUserInfoReg';
import ModalStripeReg from './ModalStripeReg';
import ModalSignUp from './ModalSignUp';
import ModalSignIn from './ModalSignIn';
import ModalConfirmSignUp from './ModalConfirmSignUp';
import ModalResetPassword from './ModalResetPassword';
import ModalConfirmResetPassword from './ModalConfirmResetPassword';
import ModalStripeComplete from './ModalStripeComplete';
import Loading from './Loading';
import ModalUpdMail from './ModalUpdMail';
import ModalUpdPassword from './ModalUpdPassword';
import ModalCancelSub from './ModalCancelSub';
import ModalDeleteAcc from './ModalDeleteAcc';
import ModalFreeUserComplete from './ModalFreeUserComplete';
import ModalWarning from './ModalWarning';
import ModalUpdateComplete from './ModalUpdateComplete';
import ModalStripeConfirm from './ModalStripeConfirm';
import ModalDeleteDef from './ModalDeleteDef';
import ModalConfirmInquiry from './ModalConfirmInquiry';
import ModalTopSupport from './ModalTopSupport';

const classList = {
    modal01: '',
    modal02: 'modal--preview',
    modal03: 'modal--saveas',
    modal04: 'modal--jump',
    modal05: 'modal--save',
    modal06: 'modal--save',
    signIn: 'modal--signIn',
    freeSignUp: 'modal--signUp',
    paidSignUp: 'modal--signUp',
    confirmSignUp: 'modal--confirmSignUp',
    resetPassword: 'modal--resetPassword',
    confirmResetPassword: 'modal--resetPassword',
    updEmail: 'modal--myPage',
    updPassword: 'modal--myPage',
    cancelSub: 'modal--myPage',
    deleteAcc: 'modal--myPage',
    stripeReg: 'modal--credit',
    stripeConfirm: 'modal--stripeConfirm',
    stripeComplete: 'modal--result',
    freeUserComplete: 'modal--result',
    error: 'modal--error',
    warning: 'modal--warning',
    updateComplete: 'modal--result',
    topSupport: 'modal--result',
    deleteDef: 'modal--myPage',
    inquiry: 'modal--inquiry',
};

const focusableElementsSelector =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';

/**
 * モーダルフレーム内容なし
 * @param {modalId} 表示するモダールID
 * @param {data}  モダールのデーター
 * @param {errorHandling} エラーの時の処理
 * @returns
 */
const ModalFrame = ({ modalId, data, errorHandling }) => {
    const modalRef = useRef();
    const overlayRef = useRef();

    const [content, setContent] = useState({ target: modalId, data: data });
    const dataStatus = useContext(ModalContext);
    const stripePromise = useContext(stripePromiseContext);

    const keyDownEvent = (event) => {
        // タブキーが押された時
        if (event.key === 'Tab') {
            event.preventDefault();
            // モーダル要素内のフォーカス可能な要素の一覧を取得
            let focusableElements = Array.from(
                modalRef.current.querySelectorAll(focusableElementsSelector)
            );
            // 現在のフォーカス位置を取得
            let focusedItemIndex = focusableElements.indexOf(
                document.activeElement
            );
            // shiftキーと同時に押されてた場合
            if (focusableElements.length > 0) {
                if (event.shiftKey) {
                    if (focusedItemIndex === 0) {
                        // 現在のフォーカスが最初の要素の場合、最後の要素にフォーカスを移動
                        focusableElements[focusableElements.length - 1].focus();
                    } else {
                        // 現在のフォーカスが最初の要素以外の場合、前の要素にフォーカスを移動
                        focusableElements[focusedItemIndex - 1].focus();
                    }
                } else {
                    if (focusedItemIndex === focusableElements.length - 1) {
                        // 現在のフォーカスが最後の要素の場合、最初の要素にフォーカスを移動
                        focusableElements[0].focus();
                    } else {
                        // 現在のフォーカスが最後の要素以外の場合、次の要素にフォーカスを移動
                        focusableElements[focusedItemIndex + 1].focus();
                    }
                }
            }
        }
    };

    useEffect(() => {
        const overlay = overlayRef.current;
        if (modalRef.current && overlay) {
            modalRef.current.animate(
                { opacity: 1 },
                {
                    duration: 300,
                    fill: 'forwards',
                }
            );
            overlay.style.pointerEvents = 'all';
            overlay.animate(
                {
                    opacity: 1,
                    background:
                        content.target === 'loading'
                            ? 'rgba(255, 255, 255, 0.7)'
                            : 'rgba(0, 0, 0, 0.3)',
                },
                {
                    duration: 300,
                    fill: 'forwards',
                }
            );
        }

        document.body.addEventListener('keydown', keyDownEvent);

        return () => {
            document.body.removeEventListener('keydown', keyDownEvent);
        };
    }, [modalRef, overlayRef, content]);

    //モーダル画面閉じる処理
    const handleClose = (status) => {
        overlayRef.current.animate(
            {
                display: 0,
                background: 'transparent',
            },
            {
                duration: 300,
                fill: 'forwards',
            }
        );
        overlayRef.current.style.pointerEvents = 'none';
        overlayRef.current.style.zIndex = 50;
        modalRef.current.animate(
            { opacity: 0 },
            {
                duration: 300,
                fill: 'forwards',
            }
        );
        modalRef.current.style.display = 'none';

        if (dataStatus) {
            if (status === undefined) {
                if (
                    modalId === 'modal03' &&
                    content.target === 'updateComplete'
                ) {
                    dataStatus.setter({
                        ...dataStatus.data,
                        display: false,
                        dataChanged: false,
                    });
                } else {
                    dataStatus.setter({ ...dataStatus.data, display: false });
                }
            } else {
                dataStatus.setter({
                    ...dataStatus.data,
                    display: false,
                    dataChanged: status,
                });
            }
        }
    };

    // モーダル内容取得
    const getModalContent = () => {
        switch (content.target) {
            case 'modal01':
                return <ModalNewDef close={handleClose} setter={setContent} />;
            case 'modal02':
                return (
                    <ModalDataPreview close={handleClose} setter={setContent} />
                );
            case 'modal03':
                return (
                    <ModalSaveDef
                        close={handleClose}
                        from={data.from}
                        initVal={data.init}
                        setter={setContent}
                    />
                );
            case 'modal04':
                return (
                    <ModalUnsaved
                        close={handleClose}
                        target={dataStatus.data.target}
                        setter={setContent}
                    />
                );
            case 'modal05':
                return <ModalOverwrite close={handleClose} />;
            case 'modal06':
                return <ModalConversion close={handleClose} />;
            case 'updEmail':
                return <ModalUpdMail close={handleClose} setter={setContent} />;
            case 'updPassword':
                return (
                    <ModalUpdPassword close={handleClose} setter={setContent} />
                );
            case 'cancelSub':
                return (
                    <ModalCancelSub
                        close={handleClose}
                        setContent={setContent}
                    />
                );
            case 'deleteAcc':
                return (
                    <ModalDeleteAcc close={handleClose} setter={setContent} />
                );
            case 'confirmSignUp':
                return (
                    <ModalConfirmSignUp
                        close={handleClose}
                        setter={setContent}
                        data={content.data}
                    />
                );
            case 'freeSignUp':
            case 'paidSignUp':
                return <ModalSignUp close={handleClose} setter={setContent} />;
            case 'signIn':
                return <ModalSignIn close={handleClose} setter={setContent} />;
            case 'resetPassword':
                return (
                    <ModalResetPassword
                        close={handleClose}
                        setter={setContent}
                    />
                );
            case 'confirmResetPassword':
                return (
                    <ModalConfirmResetPassword
                        close={handleClose}
                        setter={setContent}
                        data={content.data}
                    />
                );
            case 'userInfoReg':
            case 'userInfoConfirm':
                return (
                    <ModalUserInfoReg
                        close={handleClose}
                        setter={setContent}
                        modalRef={modalRef}
                        type={data}
                    />
                );
            case 'stripeReg':
                return (
                    <Elements stripe={stripePromise}>
                        <ModalStripeReg
                            close={handleClose}
                            setter={setContent}
                            type={content.data}
                        />
                    </Elements>
                );
            case 'stripeConfirm':
                return (
                    <ModalStripeConfirm
                        close={handleClose}
                        setter={setContent}
                        data={content.data}
                    />
                );
            case 'stripeComplete':
                return (
                    <ModalStripeComplete
                        close={handleClose}
                        setter={setContent}
                        data={content.data}
                    />
                );
            case 'freeUserComplete':
                return (
                    <ModalFreeUserComplete
                        close={handleClose}
                        data={content.data}
                    />
                );
            case 'deleteDef':
                return (
                    <ModalDeleteDef
                        close={handleClose}
                        setter={setContent}
                        data={content.data}
                    />
                );
            case 'error':
                return (
                    <ModalError message={data} errorSetter={errorHandling} />
                );
            case 'warning':
                return <ModalWarning data={data} />;
            case 'updateComplete':
                return (
                    <ModalUpdateComplete
                        data={content.data}
                        close={handleClose}
                    />
                );
            case 'topSupport':
                return <ModalTopSupport close={handleClose} />;
            case 'inquiry':
                return (
                    <ModalConfirmInquiry
                        data={content.data}
                        close={handleClose}
                        setter={setContent}
                    />
                );
            default:
                return <Navigate to={content.target} state={content.data} />;
        }
    };

    return (
        <div className="modal__wrap">
            <div className="overlay" ref={overlayRef}></div>
            <div
                className={'modal ' + classList[content.target]}
                style={{ pointerEvents: 'all', display: 'block' }}
                ref={modalRef}
            >
                {content.target === 'loading' ? (
                    <Loading />
                ) : (
                    <div className="modal__content">{getModalContent()}</div>
                )}
            </div>
        </div>
    );
};

export default ModalFrame;
